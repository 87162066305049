import { __decorate, __metadata } from "tslib";
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import { ChangeSetStatus, DeliveryOrderLineItemStatus } from '~/db_types/swagger_types';
import BaseModal from '~/nasa_ui/base/BaseModal';
import { AlertType, DeliveryOrderLineItemStatusDisplay } from '~/nasa_ui/types';
import { closeDeliveryOrderLineItems, getDeliveryOrder, putDeliveryOrderLineItem } from '~/nasa_ui/utils';
let ModalDeliveryOrderLineItemComplete = class ModalDeliveryOrderLineItemComplete extends Mixins(BaseModal) {
    DeliveryOrderLineItemStatus = DeliveryOrderLineItemStatus;
    DeliveryOrderLineItemStatusDisplay = DeliveryOrderLineItemStatusDisplay;
    lineItem = null;
    deliveryOrderFormData = {
        attributes: {},
        deliveryOrderLineItemStatus: null,
        projectedDate: null
    };
    formData = {
        comment: null
    };
    changeSetId;
    deliveryOrderId;
    get isMissingPartReference() {
        return Boolean(this.lineItem && !this.lineItem.partReferenceId);
    }
    get partReferenceId() {
        return this.lineItem?.partReferenceId ?? null;
    }
    get shouldUpdateLineItem() {
        return Boolean(this.deliveryOrderFormData.projectedDate !== this.lineItem?.projectedDate ||
            this.deliveryOrderFormData.deliveryOrderLineItemStatus !== this.lineItem?.deliveryOrderLineItemStatus);
    }
    // the endpoint for closing line items uses an array of part reference ids, to
    // determine which line items to close. so it’s possible to close multiple
    // line items at once (and will, if multiple line items use the same part
    // reference). we should probably communicate this to the user somehow.
    async closeLineItems() {
        if (!this.lineItem || !this.lineItem.partReferenceId) {
            console.error('Line item with part reference is required to complete.');
            return;
        }
        // TODO: authority code/number? qc stamp?
        const body = {
            comment: this.formData.comment,
            partReferenceIds: [this.lineItem.partReferenceId],
            status: ChangeSetStatus.APPROVED
        };
        // cast the request body because some fields don’t expect null
        return closeDeliveryOrderLineItems(this.deliveryOrderId, body);
    }
    async onClickOfSave() {
        if (!this.lineItem || !this.lineItem.partReferenceId) {
            console.error('Line item with part reference is required to complete.');
            return;
        }
        try {
            if (this.shouldUpdateLineItem) {
                await this.updateLineItem();
            }
            await this.closeLineItems();
            this.$notification.add({
                type: AlertType.SUCCESS,
                text: 'Saved.'
            });
            this.closeDialog();
        }
        catch (err) {
            if (err instanceof Error) {
                this.$errorUtility({ err });
            }
            else {
                console.error(`Unexpected error: ${err}`);
            }
        }
    }
    async updateLineItem() {
        if (!this.lineItem) {
            console.error('Could not find line item to update');
            return;
        }
        // this request body needs to list every property of the line item, even if
        // it's not being updated. It’s typed to be explicit (no undefined values)
        // because omitting a field here could lead to unintended data loss.
        const body = {
            attributes: this.lineItem.attributes,
            deliveryItemsListNumber: this.lineItem.deliveryItemsListNumber ?? null,
            deliveryOrderLineItemStatus: this.deliveryOrderFormData.deliveryOrderLineItemStatus ?? DeliveryOrderLineItemStatus.IN_PROCESS,
            jDocumentNumber: this.lineItem.jDocumentNumber ?? null,
            lineItemType: null,
            lineItemQuantity: this.lineItem.lineItemQuantity ?? null,
            openedDate: this.lineItem.openedDate,
            ownerContactId: this.lineItem.ownerContactId ?? null,
            partReferenceId: this.lineItem.partReferenceId ?? null,
            projectedDate: this.deliveryOrderFormData.projectedDate ?? null,
            shipperNumber: this.lineItem.shipperNumber ?? null,
            workBreakdownStructureNumber: this.lineItem.workBreakdownStructureNumber ?? null
        };
        // cast the request body because some fields don’t expect null
        return putDeliveryOrderLineItem(this.deliveryOrderId, this.changeSetId, body);
    }
    async onDeliveryOrderIdChange() {
        try {
            const deliveryOrder = await getDeliveryOrder(this.deliveryOrderId);
            this.lineItem = deliveryOrder.data.lineItems.changeSets?.find((cs) => cs.id === this.changeSetId);
            this.deliveryOrderFormData.projectedDate = this.lineItem?.projectedDate ?? null;
            this.deliveryOrderFormData.deliveryOrderLineItemStatus = this.lineItem?.deliveryOrderLineItemStatus ?? null;
        }
        catch (err) {
            if (err instanceof Error) {
                this.$errorUtility({
                    err
                });
            }
            else {
                console.log(`Unexpected error: ${err}`);
            }
        }
    }
};
__decorate([
    Prop({
        type: Number,
        required: true
    }),
    __metadata("design:type", Number)
], ModalDeliveryOrderLineItemComplete.prototype, "changeSetId", void 0);
__decorate([
    Prop({
        type: String,
        required: true
    }),
    __metadata("design:type", String)
], ModalDeliveryOrderLineItemComplete.prototype, "deliveryOrderId", void 0);
__decorate([
    Watch('deliveryOrderId', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", Promise)
], ModalDeliveryOrderLineItemComplete.prototype, "onDeliveryOrderIdChange", null);
ModalDeliveryOrderLineItemComplete = __decorate([
    Component
], ModalDeliveryOrderLineItemComplete);
export default ModalDeliveryOrderLineItemComplete;
