import { __decorate, __metadata } from "tslib";
import flatten from 'lodash/flatten';
import { Component, Mixins, Watch } from 'vue-property-decorator';
import { Side, UserRole } from '~/db_types';
import { AssemblyTemplateType, OrganizationType } from '~/db_types/swagger_types';
import BaseTab from '~/nasa_ui/base/BaseTab';
import { BASE_TEXT_COL, DESCRIPTION_COL, NAME_COL, NAME_DISPLAY_COL } from '~/nasa_ui/constants/genericColumnDefinitions';
import { EmailNotificationRepoMixin } from '~/nasa_ui/DAL/email';
import { UserRepoMixin } from '~/nasa_ui/DAL/user';
import { AlertType } from '~/nasa_ui/types';
import { deleteAutoHardwareList, getAutoHardwareLists, postAutoHardwareList, putAutoHardwareList } from '~/nasa_ui/utils';
import { composeTableHeader } from '~/nasa_ui/utils/helpers/composeTableHeader';
import { transformAutoHardwareListResponses, transformVehicleResponses } from '~/nasa_ui/utils/helpers/transformEntityToTableFormat';
let TabAdminCrud = class TabAdminCrud extends Mixins(BaseTab, UserRepoMixin, EmailNotificationRepoMixin) {
    autoHardwareLists = null;
    fileAccepted = false;
    fileAcceptedMega = false;
    isSaving = false;
    OrganizationType = OrganizationType;
    rawResult = null;
    rawResultMega = null;
    selectedAutoHardwareList = null;
    selectedVehicle = null;
    showCreateAutoHardwareListModal = false;
    showCreateVehicleModal = false;
    showEditVehicleModal = false;
    showEditAutoHardwareListModal = false;
    showImportUserSpreadsheetModal = false;
    usersToCreate = [];
    vehicles = [];
    emailFormData = {
        data: {},
        messageTextTemplate: '',
        messageHtmlTemplate: '',
        recipientContactId: [],
        subjectTemplate: ''
    };
    formDataOrganizationCreate = {
        code: null,
        subType: OrganizationType.PROCESSOR,
        name: null,
        attributes: {},
        isCageCode: false,
        isActive: true
    };
    formDataAutoHardwareList = {
        description: null,
        drawingNumberPrefix: null,
        name: null
    };
    formDataMegaCreation = {
        attributes: {},
        autoHardwareListId: null,
        installedOn: Side.NONE,
        maxQuantity: 0,
        minQuantity: 0,
        name: null,
        parentId: null,
        sequence: 0,
        subType: AssemblyTemplateType.MEGA
    };
    formDataVehicle = {
        name: null
    };
    importUsersTableHeader = [
        {
            text: 'AUID',
            value: 'auid'
        },
        {
            text: 'Email',
            value: 'email'
        },
        {
            text: 'Last Name',
            value: 'lastName'
        },
        {
            text: 'First Name',
            value: 'firstName'
        }
    ];
    tableHeadersAutoHardwareList = [
        composeTableHeader(NAME_COL, {
            monospacedFont: true,
            align: 'right',
            width: '150px'
        }),
        DESCRIPTION_COL,
        composeTableHeader(BASE_TEXT_COL, {
            text: 'Drawing number<br/>prefixes',
            value: '_drawingNumberPrefixes'
        })
    ];
    tableHeadersVehicle = [NAME_DISPLAY_COL];
    get allParsedUsers() {
        return (this.rawResult || []).map(([auid, email, lastName, firstName], index) => {
            return {
                auid: auid.toUpperCase(),
                email: [email],
                firstName: firstName?.trim(),
                index,
                lastName,
                middleName: null,
                organizationCodes: [this.currentUserActiveSupportContext],
                role: UserRole.USER
            };
        });
    }
    get availableContexts() {
        return this.currentUserSupportContexts;
    }
    get disableEmailTestButton() {
        return (!this.emailFormData.messageHtmlTemplate ||
            !this.emailFormData.subjectTemplate ||
            !this.emailFormData.recipientContactId.length);
    }
    get hasAvailableContexts() {
        return Boolean(this.availableContexts?.length);
    }
    get tableItemsAutoHardwareList() {
        return this.autoHardwareLists ? transformAutoHardwareListResponses(this.autoHardwareLists) : [];
    }
    get tableItemsVehicle() {
        return this.vehicles ? transformVehicleResponses(this.vehicles) : [];
    }
    created() {
        this.emailFormData.recipientContactId = [this.currentUser?.contactId];
        this.fetchAutoHardwareLists();
        this.fetchVehicles();
    }
    async createUsers() {
        try {
            this.$notification.add({
                text: `Checking users for duplicates....`,
                type: AlertType.INFO
            });
            const existingUsers = await this.checkUsersForExistence(this.usersToCreate);
            const existingUserAuids = (existingUsers?.users?.nodes || []).map((user) => user?.auid);
            const usersToCreate = this.usersToCreate.filter((user) => {
                // see if any emails match any of the existing emails
                const matchBasedOnEmail = user.email?.find((email) => {
                    return (existingUsers?.users?.nodes || []).find((user) => user?.email?.find((e) => e === email));
                });
                return (user.auid && !existingUserAuids.includes(user.auid)) || !matchBasedOnEmail;
            });
            if (usersToCreate.length > 0) {
                const resp = await Promise.all(usersToCreate.map((user) => {
                    return this.httpPost('/users', user);
                }));
                if (resp) {
                    this.$notification.add({
                        text: `${resp.length} users created.`,
                        type: AlertType.SUCCESS
                    });
                }
                this.reset();
            }
            else {
                setTimeout(() => {
                    this.$notification.add({
                        text: `All selected users currently exist.`,
                        type: AlertType.WARNING
                    });
                }, 2000);
            }
        }
        catch (err) {
            if (err instanceof Error) {
                this.$errorUtility({
                    err
                });
            }
            else {
                console.log(`Unexpected error: ${err}`);
            }
        }
    }
    async fetchAutoHardwareLists() {
        try {
            const params = {
                take: -1
            };
            const resp = await getAutoHardwareLists(params);
            this.autoHardwareLists = resp.data.results || [];
        }
        catch (err) {
            if (err instanceof Error) {
                this.$errorUtility({
                    err
                });
            }
            else {
                console.log(`Unexpected error: ${err}`);
            }
        }
    }
    async fetchVehicles() {
        try {
            const params = {
                take: -1
            };
            const resp = await this.$http.get(`/vehicles`, {
                params
            });
            this.vehicles = resp.data.results || [];
        }
        catch (err) {
            if (err instanceof Error) {
                this.$errorUtility({
                    err
                });
            }
            else {
                console.log(`Unexpected error: ${err}`);
            }
        }
    }
    async onClickOfCreatProcessorOrganization() {
        if (!this.formDataOrganizationCreate.code || !this.formDataOrganizationCreate.name) {
            return;
        }
        try {
            const body = {
                attributes: {},
                code: this.formDataOrganizationCreate.code,
                isActive: true,
                name: this.formDataOrganizationCreate.name
            };
            const resp = await this.$http.post(`/organizations/support-contexts`, body);
            this.$notification.add({
                type: AlertType.SUCCESS,
                text: 'Support ctx created.'
            });
            this.formDataOrganizationCreate.code = null;
            this.formDataOrganizationCreate.name = null;
        }
        catch (err) {
            if (err instanceof Error) {
                this.$errorUtility({
                    err
                });
            }
            else {
                console.log(`Unexpected error: ${err}`);
            }
        }
    }
    launchCreateAutoHardwareListModal() {
        this.showCreateAutoHardwareListModal = true;
    }
    launchCreateVehicleModal() {
        this.showCreateVehicleModal = true;
    }
    launchEditAutoHardwareList(item) {
        this.showEditAutoHardwareListModal = true;
        this.selectedAutoHardwareList = item;
        this.formDataAutoHardwareList.description = this.selectedAutoHardwareList.description;
        this.formDataAutoHardwareList.drawingNumberPrefix = this.selectedAutoHardwareList.drawingNumberPrefix;
        this.formDataAutoHardwareList.name = this.selectedAutoHardwareList.name;
    }
    launchEditVehicleModal(item) {
        this.showEditVehicleModal = true;
        this.selectedVehicle = item;
        this.formDataVehicle.name = this.selectedVehicle.name;
    }
    async onClickOfCreateNewAutoHardwareList() {
        try {
            this.isSaving = true;
            const reqBody = {
                drawingNumberPrefix: this.formDataAutoHardwareList.drawingNumberPrefix,
                name: this.formDataAutoHardwareList.name,
                description: this.formDataAutoHardwareList.description
            };
            const resp = await postAutoHardwareList(reqBody);
            this.$notification.add({
                type: AlertType.SUCCESS,
                text: 'Created.'
            });
            this.isSaving = false;
            this.resetAutoHardwareList();
        }
        catch (err) {
            this.isSaving = false;
            if (err instanceof Error) {
                this.$errorUtility({
                    err
                });
            }
            else {
                console.log(`Unexpected error: ${err}`);
            }
        }
    }
    async onClickOfCreateNewVehicle() {
        try {
            this.isSaving = true;
            const reqBody = {
                name: this.formDataVehicle.name
            };
            const resp = await this.$http.post(`/vehicles`, reqBody);
            this.$notification.add({
                type: AlertType.SUCCESS,
                text: 'Created.'
            });
            this.isSaving = false;
            this.resetVehicles();
        }
        catch (err) {
            this.isSaving = false;
            if (err instanceof Error) {
                this.$errorUtility({
                    err
                });
            }
            else {
                console.log(`Unexpected error: ${err}`);
            }
        }
    }
    async onClickOfImportMega() {
        this.isSaving = true;
        const createdAssemblyTemplates = [];
        let sequence = 0;
        const rootMegaBody = {
            attributes: {},
            autoHardwareListId: null,
            installedOn: Side.NONE,
            maxQuantity: 1,
            minQuantity: 1,
            name: this.formDataMegaCreation.name,
            parentId: null,
            sequence: 0,
            subType: AssemblyTemplateType.MEGA
        };
        const rootMega = await this.$http.post(`/assembly-templates`, rootMegaBody);
        if (!rootMega.data) {
            return;
        }
        createdAssemblyTemplates.push(rootMega.data);
        for (const assy of this.rawResultMega) {
            const [itemNumber, itemName, partNumbers, parentName] = assy;
            const cleanItemNumber = itemNumber.replaceAll('"', '').replaceAll('\r', '');
            const cleanItemName = itemName.replaceAll('"', '').replaceAll('\r', '');
            const cleanPartNumbers = partNumbers
                .replaceAll('"', '')
                .replaceAll('\r', '')
                .split('/')
                .filter((pn) => pn && pn.trim().length);
            const cleanParentItemNumber = parentName.replaceAll('"', '').replaceAll('\r', '');
            // Check for matching AutoHardwareList
            const ahlExistence = await getAutoHardwareLists({
                skip: 0,
                take: 1,
                query: cleanItemNumber
            });
            let ahl = null;
            if (ahlExistence.data.results?.length === 0) {
                const createdAhl = await postAutoHardwareList({
                    name: cleanItemNumber,
                    description: cleanItemName,
                    drawingNumberPrefix: cleanPartNumbers.length ? cleanPartNumbers : []
                });
                ahl = createdAhl.data;
            }
            else {
                ahl = ahlExistence.data.results[0];
            }
            // Create new AssyTemplate
            const assemblyTemplateReqBody = {
                attributes: {},
                minQuantity: 1,
                maxQuantity: cleanItemName.includes('qty') ? 2 : 1,
                sequence,
                subType: AssemblyTemplateType.MEGA,
                autoHardwareListId: ahl.id,
                name: `${cleanItemNumber}`,
                installedOn: Side.NONE,
                parentId: cleanParentItemNumber === '-'
                    ? rootMega.data.id
                    : createdAssemblyTemplates.find((temp) => temp.name === cleanParentItemNumber)?.id ?? null
            };
            const newAssemblyTemplate = await this.$http.post(`/assembly-templates`, assemblyTemplateReqBody);
            createdAssemblyTemplates.push(newAssemblyTemplate.data);
            if (assemblyTemplateReqBody.parentId === null) {
                sequence = 0;
            }
            else {
                sequence += 10;
            }
        }
        this.$notification.add({
            type: AlertType.SUCCESS,
            text: `${createdAssemblyTemplates.length} assembly templates created.`
        });
        this.isSaving = false;
    }
    async onClickOfSaveAutoHardwareList() {
        if (!this.selectedAutoHardwareList) {
            return;
        }
        try {
            this.isSaving = true;
            const reqBody = {
                drawingNumberPrefix: this.formDataAutoHardwareList.drawingNumberPrefix,
                name: this.formDataAutoHardwareList.name,
                description: this.formDataAutoHardwareList.description
            };
            const resp = await putAutoHardwareList(this.selectedAutoHardwareList.id, reqBody);
            this.$notification.add({
                type: AlertType.SUCCESS,
                text: 'Updated.'
            });
            this.isSaving = false;
            this.resetAutoHardwareList();
        }
        catch (err) {
            this.isSaving = false;
            if (err instanceof Error) {
                this.$errorUtility({
                    err
                });
            }
            else {
                console.log(`Unexpected error: ${err}`);
            }
        }
    }
    async onClickOfSaveVehicleList() {
        if (!this.selectedVehicle) {
            return;
        }
        try {
            this.isSaving = true;
            const reqBody = {
                name: this.formDataVehicle.name
            };
            const resp = await this.$http.put(`/vehicles/${this.selectedVehicle.id}`, reqBody);
            this.$notification.add({
                type: AlertType.SUCCESS,
                text: 'Updated.'
            });
            this.isSaving = false;
            this.resetVehicles();
        }
        catch (err) {
            this.isSaving = false;
            if (err instanceof Error) {
                this.$errorUtility({
                    err
                });
            }
            else {
                console.log(`Unexpected error: ${err}`);
            }
        }
    }
    async onClickOfDeleteAutoHardwareList(item) {
        // delete it
        try {
            const resp = await deleteAutoHardwareList(item.id);
            this.$notification.add({
                type: AlertType.SUCCESS,
                text: 'Deleted.'
            });
        }
        catch (err) {
            if (err instanceof Error) {
                this.$errorUtility({
                    err
                });
            }
            else {
                console.log(`Unexpected error: ${err}`);
            }
        }
        this.resetAutoHardwareList();
    }
    async onClickOfDeleteVehicle(item) {
        // delete it
        try {
            const resp = await this.$http.delete(`/vehicles/${item.id}`);
            this.$notification.add({
                type: AlertType.SUCCESS,
                text: 'Deleted.'
            });
        }
        catch (err) {
            if (err instanceof Error) {
                this.$errorUtility({
                    err
                });
            }
            else {
                console.log(`Unexpected error: ${err}`);
            }
        }
        this.resetVehicles();
    }
    resetAutoHardwareList() {
        this.showCreateAutoHardwareListModal = false;
        this.showEditAutoHardwareListModal = false;
        this.selectedAutoHardwareList = null;
        this.formDataAutoHardwareList = {
            description: null,
            drawingNumberPrefix: null,
            name: null
        };
        this.fetchAutoHardwareLists();
    }
    resetVehicles() {
        this.showCreateVehicleModal = false;
        this.showEditVehicleModal = false;
        this.selectedVehicle = null;
        this.formDataVehicle = {
            name: null
        };
        this.fetchVehicles();
    }
    onMegaImportFileSelected(file) {
        const reader = new FileReader();
        reader.readAsText(file);
        reader.onload = async () => {
            const strResult = reader.result;
            this.rawResultMega = strResult
                .split('\n')
                .map((row) => row.split(','))
                .slice(1);
            this.fileAcceptedMega = true;
        };
    }
    // Called from AppFileUpload @input
    onUserImportFileSelected(file) {
        const reader = new FileReader();
        reader.readAsText(file);
        reader.onload = async () => {
            const strResult = reader.result;
            this.rawResult = strResult
                .split('\n')
                .map((row) => row.split(','))
                .slice(1);
            this.fileAccepted = true;
        };
    }
    reset() {
        this.emailFormData.messageHtmlTemplate = '';
        this.emailFormData.recipientContactId = [];
        this.emailFormData.subjectTemplate = '';
        this.fileAccepted = false;
        this.isSaving = false;
        this.showImportUserSpreadsheetModal = false;
        this.usersToCreate = [];
    }
    checkUsersForExistence(users) {
        return this.getUsersByAuidOrEmail({
            userAuids: users.map((user) => user.auid),
            emails: flatten(users.map((user) => user.email))
        });
    }
    async sendEmail() {
        try {
            const resp = await this.createEmailNotification({
                emailDetails: this.emailFormData
            });
            if (resp?.createNotification?.notification) {
                this.$notification.add({
                    text: `Email sent to human(s).`,
                    type: AlertType.SUCCESS
                });
                this.reset();
            }
        }
        catch (err) {
            if (err instanceof Error) {
                this.$errorUtility({
                    err
                });
            }
            else {
                console.log(`Unexpected error: ${err}`);
            }
        }
    }
    onShowImportUserSpreadsheetModalChange() {
        if (!this.showImportUserSpreadsheetModal) {
            this.reset();
        }
    }
};
__decorate([
    Watch('showImportUserSpreadsheetModal'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], TabAdminCrud.prototype, "onShowImportUserSpreadsheetModalChange", null);
TabAdminCrud = __decorate([
    Component
], TabAdminCrud);
export default TabAdminCrud;
