var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    class: {
      app_item_instance_display: true,
      is_initialized: _vm.shouldShowItemInstanceDisplay
    }
  }, [!_vm.shouldShowItemInstanceDisplay ? _c('AppLoader', {
    staticClass: "loader"
  }) : _vm._e(), !_vm.isMinimized ? _c('div', {
    class: {
      minimized: _vm.isMinimized,
      is_initialized: _vm.shouldShowItemInstanceDisplay
    }
  }, [_c('div', {
    staticClass: "ahd--grid-container item_instance_display_grid"
  }, [_c('div', {
    staticClass: "ahd--column-one"
  }, [_c('div', {
    staticClass: "ahd--grid-item"
  }, [_c('p', {
    staticClass: "ahd--label"
  }, [_vm._v("Drawing num:")]), _vm.transformedItemDrawing ? _c('p', {
    staticClass: "pl-0 font-weight-bold"
  }, [_c('span', {
    staticClass: "monospace_font"
  }, [_vm._v(_vm._s(_vm.transformedItemDrawing.drawingNumber))])]) : _vm._e()]), _c('div', {
    staticClass: "ahd--grid-item"
  }, [_c('p', {
    staticClass: "ahd--label"
  }, [_vm._v("AsBuilt:")]), _vm.transformedSelectedEntity ? _c('p', {
    staticClass: "pl-0 font-weight-bold"
  }, [_c('span', {
    staticClass: "monospace_font"
  }, [_vm._v(_vm._s(_vm.transformedSelectedEntity._asBuiltNumber))])]) : _vm._e()]), _c('div', {
    staticClass: "ahd--grid-item"
  }, [_c('p', {
    staticClass: "ahd--label"
  }, [_vm._v("Side:")]), _vm.transformedSelectedEntity ? _c('p', {
    staticClass: "pl-0 font-weight-bold"
  }, [_c('span', {
    staticClass: "monospace_font"
  }, [_vm._v(_vm._s(_vm.transformedSelectedEntity._side))])]) : _vm._e()]), _c('div', {
    staticClass: "ahd--grid-item"
  }, [_c('p', {
    staticClass: "ahd--label"
  }, [_vm._v("Size:")]), _vm.transformedSelectedEntity ? _c('p', {
    staticClass: "pl-0 font-weight-bold"
  }, [_c('span', {
    staticClass: "monospace_font"
  }, [_vm._v(_vm._s(_vm.transformedSelectedEntity._size))])]) : _vm._e()]), _c('div', {
    staticClass: "ahd--grid-item"
  }, [_c('p', {
    staticClass: "ahd--label"
  }, [_vm._v("Serial:")]), _vm.transformedSelectedEntity ? _c('p', {
    staticClass: "pl-0 font-weight-bold"
  }, [_c('span', {
    staticClass: "monospace_font"
  }, [_vm._v(_vm._s(_vm.transformedSelectedEntity._serialNumber))])]) : _vm._e()]), _c('div', {
    staticClass: "ahd--grid-item"
  }, [_c('p', {
    staticClass: "ahd--label"
  }, [_vm._v("Lot:")]), _vm.transformedSelectedEntity ? _c('p', {
    staticClass: "pl-0 font-weight-bold"
  }, [_c('span', {
    staticClass: "monospace_font"
  }, [_vm._v(_vm._s(_vm.transformedSelectedEntity._lotNumber))])]) : _vm._e()]), _c('div', {
    staticClass: "ahd--grid-item"
  }, [_c('p', {
    staticClass: "ahd--label"
  }, [_vm._v("Class:")]), _vm.transformedSelectedEntity ? _c('p', {
    staticClass: "pl-0 font-weight-bold"
  }, [_c('span', {
    staticClass: "monospace_font"
  }, [_vm._v(_vm._s(_vm.transformedSelectedEntity._subType))])]) : _vm._e()]), _c('div', {
    staticClass: "ahd--grid-item"
  }, [_c('p', {
    staticClass: "ahd--label"
  }, [_vm._v("Item num:")]), _vm.transformedSelectedEntity ? _c('p', {
    staticClass: "pl-0 font-weight-bold"
  }, [_c('span', {
    staticClass: "monospace_font"
  }, [_vm._v(_vm._s(_vm.transformedSelectedEntity._itemNumber))])]) : _vm._e()]), _c('div', {
    staticClass: "ahd--grid-item"
  }, [_c('p', {
    staticClass: "ahd--label"
  }, [_vm._v("Proj code:")]), _vm.transformedSelectedEntity && _vm.transformedSelectedEntity.projectCode && _vm.transformedSelectedEntity.projectCode.length ? _c('ul', {
    staticClass: "pa-0 list-style-none",
    staticStyle: {
      "max-height": "80px",
      "overflow-y": "auto",
      "width": "120px"
    }
  }, _vm._l(_vm.transformedSelectedEntity._projectCode, function (projectCode) {
    return _c('li', {
      key: projectCode,
      staticClass: "pl-0 font-weight-bold monospace_font"
    }, [_vm._v(" " + _vm._s(projectCode) + " ")]);
  }), 0) : _c('p', {
    staticClass: "pl-0 font-weight-bold"
  }, [_vm._v("—")])]), _c('div', {
    staticClass: "ahd--grid-item"
  }, [_c('p', {
    staticClass: "ahd--label"
  }, [_vm._v("Return status:")]), _vm.transformedSelectedEntity ? _c('p', {
    staticClass: "pl-0 font-weight-bold"
  }, [_c('span', [_vm._v(_vm._s(_vm.transformedSelectedEntity._returnableStatus))])]) : _vm._e()])]), _c('div', {
    staticClass: "ahd--column-two"
  }, [_c('div', {
    staticClass: "ahd--grid-item"
  }, [_c('p', {
    staticClass: "ahd--label"
  }, [_vm._v("CEI num:")]), _vm.transformedItemMaster ? _c('p', {
    staticClass: "pl-0 font-weight-bold monospace_font"
  }, [_vm._v(" " + _vm._s(_vm.transformedItemMaster._ceiNumber) + " ")]) : _vm._e()]), _c('div', {
    staticClass: "ahd--grid-item"
  }, [_c('p', {
    staticClass: "ahd--label"
  }, [_vm._v("CEI lvl:")]), _vm.transformedItemMaster ? _c('p', {
    staticClass: "pl-0 font-weight-bold monospace_font"
  }, [_vm._v(" " + _vm._s(_vm.transformedItemMaster._ceiLevel) + " ")]) : _vm._e()]), _c('div', {
    staticClass: "ahd--grid-item"
  }, [_c('p', {
    staticClass: "ahd--label"
  }, [_vm._v("Crit code:")]), _vm.transformedItemMaster ? _c('p', {
    staticClass: "pl-0 font-weight-bold monospace_font"
  }, [_vm._v(" " + _vm._s(_vm.transformedItemMaster._criticalityCodeShort) + " ")]) : _vm._e()]), _vm.transformedItemMaster ? _c('div', {
    staticClass: "ahd--grid-item",
    attrs: {
      "tooltip": `${_vm.transformedItemMaster._subType}`
    }
  }, [_c('p', {
    staticClass: "ahd--label"
  }, [_vm._v("Equip grp:")]), _c('p', {
    staticClass: "pl-0 font-weight-bold monospace_font"
  }, [_vm._v(" " + _vm._s(_vm.transformedItemMaster._subTypeNumberOnly) + " ")])]) : _vm._e(), _vm.transformedSelectedEntity && _vm.transformedSelectedEntity.equipmentControlNumber ? _c('div', {
    staticClass: "ahd--grid-item"
  }, [_c('p', {
    staticClass: "ahd--label"
  }, [_vm._v("ECN:")]), _c('p', {
    staticClass: "pl-0 font-weight-bold monospace_font"
  }, [_vm._v(" " + _vm._s(_vm.transformedSelectedEntity._equipmentControlNumber) + " ")])]) : _vm._e(), _vm.transformedItemMaster ? _c('div', {
    staticClass: "ahd--grid-item",
    attrs: {
      "tooltip": `${_vm.transformedItemMaster._federalSupplyClassNumber} - ${_vm.transformedItemMaster._federalSupplyClass}`
    }
  }, [_c('p', {
    staticClass: "ahd--label"
  }, [_vm._v("FSC:")]), _c('p', {
    staticClass: "pl-0 font-weight-bold monospace_font"
  }, [_vm._v(" " + _vm._s(_vm.transformedItemMaster._federalSupplyClassNumber) + " ")])]) : _vm._e(), _vm.transformedItemDrawing && _vm.transformedItemDrawing.meanTimeBetweenFailure ? _c('div', {
    staticClass: "ahd--grid-item",
    attrs: {
      "tooltip": `Inherited from drawing ${_vm.transformedItemDrawing._itemNumber}`
    }
  }, [_c('p', {
    staticClass: "ahd--label"
  }, [_vm._v("MTBF:")]), _c('p', {
    staticClass: "pl-0 font-weight-bold monospace_font"
  }, [_vm._v(" " + _vm._s(_vm.transformedItemDrawing._meanTimeBetweenFailure) + " ")])]) : _vm._e(), _vm.transformedItemDrawing && _vm.transformedItemDrawing.meanTimeToRepair ? _c('div', {
    staticClass: "ahd--grid-item",
    attrs: {
      "tooltip": `Inherited from drawing ${_vm.transformedItemDrawing._itemNumber}`
    }
  }, [_c('p', {
    staticClass: "ahd--label"
  }, [_vm._v("MTTR:")]), _c('p', {
    staticClass: "pl-0 font-weight-bold monospace_font"
  }, [_vm._v(" " + _vm._s(_vm.transformedItemDrawing._meanTimeToRepair) + " ")])]) : _vm._e(), _vm.transformedSelectedEntity && _vm.transformedSelectedEntity.overrideMeanTimeToRepair ? _c('div', {
    staticClass: "ahd--grid-item",
    attrs: {
      "tooltip": "Overriden for this instance"
    }
  }, [_c('p', {
    staticClass: "ahd--label"
  }, [_vm._v("MTTR:")]), _c('p', {
    staticClass: "pl-0 font-weight-bold monospace_font"
  }, [_vm._v(" " + _vm._s(_vm.transformedSelectedEntity._overrideMeanTimeToRepair) + " ")])]) : _vm._e(), _vm.transformedItemMaster && _vm.transformedItemMaster._objectClass !== _vm.DEFAULT_DASH ? _c('div', {
    staticClass: "ahd--grid-item"
  }, [_c('p', {
    staticClass: "ahd--label"
  }, [_vm._v("Obj cls:")]), _c('p', {
    staticClass: "pl-0 font-weight-bold monospace_font"
  }, [_vm._v(" " + _vm._s(_vm.transformedItemMaster._objectClass) + " ")])]) : _vm._e(), _c('div', {
    staticClass: "ahd--grid-item"
  }, [_c('p', {
    staticClass: "ahd--label"
  }, [_vm._v("PoC:")]), _vm.transformedItemDrawing ? _c('p', {
    staticClass: "pl-0 font-weight-bold",
    staticStyle: {
      "width": "max-content"
    }
  }, [_vm._v(" " + _vm._s(_vm.transformedItemDrawing._pointOfContact) + " ")]) : _vm._e()]), _vm.transformedItemMaster ? _c('div', {
    staticClass: "ahd--grid-item"
  }, [_c('p', {
    staticClass: "ahd--label"
  }, [_vm._v("Prop cls:")]), _vm.transformedItemDrawing ? _c('p', {
    staticClass: "pl-0 font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.transformedItemMaster._propertyClass) + " ")]) : _vm._e()]) : _vm._e(), _vm.transformedItemMaster && _vm.transformedItemMaster.hasSoftwareVersion && _vm.transformedSelectedEntity && !_vm.transformedSelectedEntity.overrideSoftwareVersion ? _c('div', {
    staticClass: "ahd--grid-item",
    attrs: {
      "tooltip": `Inherited property from ${_vm.transformedItemMaster._itemNumber}`
    }
  }, [_c('p', {
    staticClass: "ahd--label"
  }, [_vm._v("Soft ver:")]), _c('p', {
    staticClass: "pl-0 font-weight-bold monospace_font"
  }, [_vm._v(" " + _vm._s(_vm.transformedItemMaster._softwareVersion) + " ")])]) : _vm.transformedItemMaster && _vm.transformedItemMaster.hasSoftwareVersion && _vm.transformedSelectedEntity && _vm.transformedSelectedEntity.overrideSoftwareVersion ? _c('div', {
    staticClass: "ahd--grid-item",
    attrs: {
      "tooltip": "Override set at the instance level"
    }
  }, [_c('p', {
    staticClass: "ahd--label"
  }, [_vm._v("Soft ver:")]), _c('p', {
    staticClass: "pl-0 font-weight-bold monospace_font"
  }, [_vm._v(" " + _vm._s(_vm.transformedSelectedEntity._overrideSoftwareVersion) + " ")])]) : _vm._e()]), _c('div', {
    staticClass: "ahd--column-three"
  }, [_vm.transformedSelectedEntity && _vm.transformedSelectedEntity.acquisitionDate ? _c('div', {
    staticClass: "ahd--grid-item"
  }, [_c('p', {
    staticClass: "ahd--label"
  }, [_vm._v("Acquisition:")]), _c('p', {
    staticClass: "pl-0 font-weight-bold monospace_font"
  }, [_vm._v(" " + _vm._s(_vm.transformedSelectedEntity._acquisitionDate) + " ")])]) : _vm._e(), _vm.transformedSelectedEntity && _vm.transformedSelectedEntity.birthDate ? _c('div', {
    staticClass: "ahd--grid-item"
  }, [_c('p', {
    staticClass: "ahd--label"
  }, [_vm._v("Birth:")]), _c('p', {
    staticClass: "pl-0 font-weight-bold monospace_font"
  }, [_vm._v(" " + _vm._s(_vm.transformedSelectedEntity._birthDate) + " ")])]) : _vm._e(), _vm.transformedSelectedEntity && _vm.transformedSelectedEntity.closeDate ? _c('div', {
    staticClass: "ahd--grid-item"
  }, [_c('p', {
    staticClass: "ahd--label"
  }, [_vm._v("Close:")]), _c('p', {
    staticClass: "pl-0 font-weight-bold monospace_font"
  }, [_vm._v(" " + _vm._s(_vm.transformedSelectedEntity._closeDate) + " ")])]) : _vm._e(), _vm.transformedSelectedEntity && _vm.transformedSelectedEntity.cureDate ? _c('div', {
    staticClass: "ahd--grid-item"
  }, [_c('p', {
    staticClass: "ahd--label"
  }, [_vm._v("Cure:")]), _c('p', {
    staticClass: "pl-0 font-weight-bold monospace_font"
  }, [_vm._v(" " + _vm._s(_vm.transformedSelectedEntity._cureDate) + " ")])]) : _vm._e(), _vm.transformedSelectedEntity && _vm.transformedSelectedEntity.openDate ? _c('div', {
    staticClass: "ahd--grid-item"
  }, [_c('p', {
    staticClass: "ahd--label"
  }, [_vm._v("Open:")]), _c('p', {
    staticClass: "pl-0 font-weight-bold monospace_font"
  }, [_vm._v(" " + _vm._s(_vm.transformedSelectedEntity._openDate) + " ")])]) : _vm._e(), _vm.transformedSelectedEntity && _vm.transformedSelectedEntity.operationalStartDate ? _c('div', {
    staticClass: "ahd--grid-item"
  }, [_c('p', {
    staticClass: "ahd--label"
  }, [_vm._v("Op start:")]), _c('p', {
    staticClass: "pl-0 font-weight-bold monospace_font"
  }, [_vm._v(" " + _vm._s(_vm.transformedSelectedEntity._operationalStartDate) + " ")])]) : _vm._e(), _vm.transformedSelectedEntity && _vm.transformedSelectedEntity.pdaDate ? _c('div', {
    staticClass: "ahd--grid-item"
  }, [_c('p', {
    staticClass: "ahd--label"
  }, [_vm._v("PDA:")]), _c('p', {
    staticClass: "pl-0 font-weight-bold monospace_font"
  }, [_vm._v(" " + _vm._s(_vm.transformedSelectedEntity._pdaDate) + " ")])]) : _vm._e(), _vm.transformedSelectedEntity && _vm.transformedSelectedEntity.piaDate ? _c('div', {
    staticClass: "ahd--grid-item"
  }, [_c('p', {
    staticClass: "ahd--label"
  }, [_vm._v("PIA:")]), _c('p', {
    staticClass: "pl-0 font-weight-bold monospace_font"
  }, [_vm._v(" " + _vm._s(_vm.transformedSelectedEntity._piaDate) + " ")])]) : _vm._e(), _vm.transformedSelectedEntity && _vm.transformedSelectedEntity.purchaseRequestDate ? _c('div', {
    staticClass: "ahd--grid-item"
  }, [_c('p', {
    staticClass: "ahd--label"
  }, [_vm._v("PR:")]), _c('p', {
    staticClass: "pl-0 font-weight-bold monospace_font"
  }, [_vm._v(" " + _vm._s(_vm.transformedSelectedEntity._purchaseRequestDate) + " ")])]) : _vm._e(), !_vm.hasAnyDateField ? _c('div', {
    staticClass: "ahd--no_hw_dates"
  }, [_vm._m(0)]) : _vm._e()]), _vm.transformedSelectedEntity && _vm.myLimitedLife ? _c('div', {
    staticClass: "ahd--column-four"
  }, [_vm.myLimitedLife.hasLimitedLifeCodes ? _c('div', {
    staticClass: "ahd--grid-item"
  }, [_c('p', {
    staticClass: "ahd--label"
  }, [_vm._v("LL code:")]), _c('ul', {
    staticClass: "pa-0 list-style-none",
    staticStyle: {
      "max-height": "80px",
      "overflow-y": "auto",
      "width": "120px"
    }
  }, _vm._l(_vm.myLimitedLife.orderedLimitedLifeCodes, function (code) {
    return _c('li', {
      key: code,
      staticClass: "pl-0 font-weight-bold monospace_font"
    }, [_vm._v(" " + _vm._s(code) + " ")]);
  }), 0)]) : _vm._e(), _vm.myLimitedLife.hasLimitedLifeCodes ? _c('hr', {
    staticClass: "mx-3"
  }) : _vm._e(), _vm.myLimitedLife.hasAnyLimitedLifeReportCycleRelated ? _vm._l(_vm.myLimitedLife.limitedLifeReportCycleRelated, function (item, index) {
    return _c('div', {
      key: `${index}_${item.subType}_${item.kind}`,
      staticClass: "ahd--grid-item ahd--grid-item_ll"
    }, [_c('p', {
      staticClass: "ahd--label"
    }, [_vm._v(_vm._s(_vm.LimitedLifeTypeShortDisplay.get(item.subType)) + ":")]), _c('div', {
      staticClass: "app_item_instance__ll_item_values"
    }, [item.expirationQuotaUses ? _c('div', {
      staticClass: "monospace_font"
    }, [_vm._v(" " + _vm._s(item.expirationQuotaUses) + " "), _c('small', [_vm._v("uses exp quota")])]) : _vm._e(), item.expirationUsesRemaining ? _c('div', {
      staticClass: "monospace_font"
    }, [_vm._v(" " + _vm._s(item.expirationUsesRemaining) + " "), _c('small', [_vm._v("uses remain")])]) : _vm._e(), item.lastServiceDate ? _c('div', {
      staticClass: "monospace_font"
    }, [_vm._v(" " + _vm._s(_vm.$dateDisplay(item.lastServiceDate)) + " "), _c('small', [_vm._v("last svc")])]) : _vm._e(), item.serviceQuotaUses ? _c('div', {
      staticClass: "monospace_font"
    }, [_vm._v(" " + _vm._s(item.serviceQuotaUses) + " "), _c('small', [_vm._v("svc quota")])]) : _vm._e(), item.serviceUsesRemaining ? _c('div', {
      staticClass: "monospace_font"
    }, [_vm._v(" " + _vm._s(item.serviceUsesRemaining) + " "), _c('small', [_vm._v("svc remain")])]) : _vm._e(), item.totalUses ? _c('div', {
      staticClass: "monospace_font"
    }, [_vm._v(" " + _vm._s(item.totalUses) + " "), _c('small', [_vm._v("ttl uses")])]) : _vm._e(), item.totalUsesSinceService ? _c('div', {
      staticClass: "monospace_font"
    }, [_vm._v(" " + _vm._s(item.totalUsesSinceService) + " "), _c('small', [_vm._v("ttl since svc")])]) : _vm._e()])]);
  }) : _vm._e(), _vm.myLimitedLife.hasAnyLimitedLifeReportDateRelated ? _vm._l(_vm.myLimitedLife.limitedLifeReportDateRelated, function (item, index) {
    return _c('div', {
      key: `${index}_${item.subType}_${item.kind}`,
      staticClass: "ahd--grid-item ahd--grid-item_ll"
    }, [_c('p', {
      staticClass: "ahd--label"
    }, [_vm._v(_vm._s(_vm.LimitedLifeTypeShortDisplay.get(item.subType)) + ":")]), _c('div', {
      staticClass: "app_item_instance__ll_item_values"
    }, [item.baseDate ? _c('div', {
      staticClass: "monospace_font"
    }, [_vm._v(" " + _vm._s(_vm.$dateDisplay(item.baseDate)) + " "), _c('small', [_vm._v("base")])]) : _vm._e(), item.expirationDate ? _c('div', {
      staticClass: "monospace_font"
    }, [_vm._v(" " + _vm._s(_vm.$dateDisplay(item.expirationDate)) + " "), _c('small', [_vm._v("exp")])]) : _vm._e(), item.expirationQuotaInterval ? _c('div', {
      staticClass: "monospace_font"
    }, [_vm._v(" " + _vm._s(_vm.$intervalDisplay(item.expirationQuotaInterval)) + " "), _c('small', [_vm._v("exp quota")])]) : _vm._e(), item.expirationTimeRemaining ? _c('div', {
      staticClass: "monospace_font"
    }, [_vm._v(" " + _vm._s(_vm.$intervalDisplay(item.expirationTimeRemaining)) + " "), _c('small', [_vm._v("exp remain")])]) : _vm._e(), item.lastServiceDate ? _c('div', {
      staticClass: "monospace_font"
    }, [_vm._v(" " + _vm._s(_vm.$dateDisplay(item.lastServiceDate)) + " "), _c('small', [_vm._v("last svc")])]) : _vm._e(), item.nextServiceDate ? _c('div', {
      staticClass: "monospace_font"
    }, [_vm._v(" " + _vm._s(_vm.$dateDisplay(item.nextServiceDate)) + " "), _c('small', [_vm._v("next svc")])]) : _vm._e(), item.overrideExpirationDate && item.overrideExpirationDate !== item.expirationDate ? _c('div', {
      staticClass: "monospace_font"
    }, [_vm._v(" " + _vm._s(_vm.$dateDisplay(item.overrideExpirationDate)) + " "), _c('small', [_vm._v("ovr exp")])]) : _vm._e(), item.serviceQuotaInterval ? _c('div', {
      staticClass: "monospace_font",
      attrs: {
        "tooltip": "Service quota"
      }
    }, [_vm._v(" " + _vm._s(_vm.$intervalDisplay(item.serviceQuotaInterval)) + " "), _c('small', [_vm._v("svc quota")])]) : _vm._e(), item.serviceTimeRemaining ? _c('div', {
      staticClass: "monospace_font"
    }, [_vm._v(" " + _vm._s(_vm.$intervalDisplay(item.serviceTimeRemaining)) + " "), _c('small', [_vm._v("svc remain")])]) : _vm._e()])]);
  }) : _vm._e(), _vm.myLimitedLife.hasAnyLimitedLifeReportIntervalRelated ? _vm._l(_vm.myLimitedLife.limitedLifeReportIntervalRelated, function (item, index) {
    return _c('div', {
      key: `${index}_${item.subType}_${item.kind}`,
      staticClass: "ahd--grid-item ahd--grid-item_ll"
    }, [_c('p', {
      staticClass: "ahd--label"
    }, [_vm._v(_vm._s(_vm.LimitedLifeTypeShortDisplay.get(item.subType)) + ":")]), _c('div', {
      staticClass: "app_item_instance__ll_item_values"
    }, [item.expirationQuotaInterval ? _c('div', {
      staticClass: "monospace_font"
    }, [_vm._v(" " + _vm._s(_vm.$intervalDisplay(item.expirationQuotaInterval)) + " "), _c('small', [_vm._v("exp quota")])]) : _vm._e(), item.expirationTimeRemaining ? _c('div', {
      staticClass: "monospace_font"
    }, [_vm._v(" " + _vm._s(_vm.$intervalDisplay(item.expirationTimeRemaining)) + " "), _c('small', [_vm._v("exp remain")])]) : _vm._e(), item.lastServiceDate ? _c('div', {
      staticClass: "monospace_font"
    }, [_vm._v(" " + _vm._s(_vm.$dateDisplay(item.lastServiceDate)) + " "), _c('small', [_vm._v("last svc")])]) : _vm._e(), item.nextServiceDate ? _c('div', {
      staticClass: "monospace_font"
    }, [_vm._v(" " + _vm._s(_vm.$dateDisplay(item.nextServiceDate)) + " "), _c('small', [_vm._v("next svc")])]) : _vm._e(), item.serviceQuotaInterval ? _c('div', {
      staticClass: "monospace_font"
    }, [_vm._v(" " + _vm._s(_vm.$intervalDisplay(item.serviceQuotaInterval)) + " "), _c('small', [_vm._v("svc quota")])]) : _vm._e(), item.serviceTimeRemaining ? _c('div', {
      staticClass: "monospace_font"
    }, [_vm._v(" " + _vm._s(_vm.$intervalDisplay(item.serviceTimeRemaining)) + " "), _c('small', [_vm._v("svc remain")])]) : _vm._e()])]);
  }) : _vm._e()], 2) : _vm._e(), _vm.transformedSelectedEntity && !_vm.selectedEntityHasLimitedLifeReport ? _c('div', {
    staticClass: "ahd--column-four"
  }, [_vm._m(1)]) : _vm._e(), _vm.transformedItemMaster ? _c('div', {
    staticClass: "ahd--column-five"
  }, [_c('div', {
    staticClass: "ahd--grid-item"
  }, [_c('p', {
    staticClass: "ahd--label"
  }, [_vm._v("Approved AsDesign:")]), _c('p', {
    staticClass: "pl-0 font-weight-bold"
  }, [_c('AppBooleanDisplay', {
    attrs: {
      "icon-size": "16px",
      "tooltip": _vm.transformedItemMaster.isApprovedAsDesign ? 'Yes, approved AsDesign' : 'Not approved AsDesign'
    },
    model: {
      value: _vm.transformedItemMaster.isApprovedAsDesign,
      callback: function ($$v) {
        _vm.$set(_vm.transformedItemMaster, "isApprovedAsDesign", $$v);
      },
      expression: "transformedItemMaster.isApprovedAsDesign"
    }
  })], 1)]), _c('div', {
    staticClass: "ahd--grid-item"
  }, [_c('p', {
    staticClass: "ahd--label"
  }, [_vm._v("Approved Data Pack:")]), _c('p', {
    staticClass: "pl-0 font-weight-bold"
  }, [_c('AppBooleanDisplay', {
    attrs: {
      "icon-size": "16px"
    },
    model: {
      value: _vm.transformedItemMaster.isApprovedDataPack,
      callback: function ($$v) {
        _vm.$set(_vm.transformedItemMaster, "isApprovedDataPack", $$v);
      },
      expression: "transformedItemMaster.isApprovedDataPack"
    }
  })], 1)]), _vm.transformedItemMaster.isCleanlinessTracking ? _c('div', {
    staticClass: "ahd--grid-item"
  }, [_c('p', {
    staticClass: "ahd--label"
  }, [_vm._v("Cleanliness tracked:")]), _c('p', {
    staticClass: "pl-0 font-weight-bold"
  }, [_c('AppBooleanDisplay', {
    attrs: {
      "icon-size": "16px"
    },
    model: {
      value: _vm.transformedItemMaster.isCleanlinessTracking,
      callback: function ($$v) {
        _vm.$set(_vm.transformedItemMaster, "isCleanlinessTracking", $$v);
      },
      expression: "transformedItemMaster.isCleanlinessTracking"
    }
  })], 1)]) : _vm._e(), _vm.transformedItemMaster.limitedLife ? _c('div', {
    staticClass: "ahd--grid-item"
  }, [_c('p', {
    staticClass: "ahd--label"
  }, [_vm._v("Disabled LL alerts:")]), _c('p', {
    staticClass: "pl-0 font-weight-bold"
  }, [_c('AppBooleanDisplay', {
    attrs: {
      "icon-size": "16px"
    },
    model: {
      value: _vm.transformedItemMaster.limitedLife.disableAlerts,
      callback: function ($$v) {
        _vm.$set(_vm.transformedItemMaster.limitedLife, "disableAlerts", $$v);
      },
      expression: "transformedItemMaster.limitedLife.disableAlerts"
    }
  })], 1)]) : _vm._e(), _vm.transformedSelectedEntity ? _c('div', {
    staticClass: "ahd--grid-item",
    attrs: {
      "tooltip-position": "left",
      "tooltip": _vm.transformedSelectedEntity.inheritLimitedLifeFromMaster ? `Limited life values are inherited from this instance's item master.` : 'Limited life values are intended to be instance specific'
    }
  }, [_c('p', {
    staticClass: "ahd--label"
  }, [_vm._v("Inherit LL:")]), _c('p', {
    staticClass: "pl-0 font-weight-bold"
  }, [_c('AppBooleanDisplay', {
    attrs: {
      "icon-size": "16px"
    },
    model: {
      value: _vm.transformedSelectedEntity.inheritLimitedLifeFromMaster,
      callback: function ($$v) {
        _vm.$set(_vm.transformedSelectedEntity, "inheritLimitedLifeFromMaster", $$v);
      },
      expression: "transformedSelectedEntity.inheritLimitedLifeFromMaster"
    }
  })], 1)]) : _vm._e(), _vm.transformedItemMaster.isFractureCritical ? _c('div', {
    staticClass: "ahd--grid-item"
  }, [_c('p', {
    staticClass: "ahd--label"
  }, [_vm._v("Fracture crit:")]), _c('p', {
    staticClass: "pl-0 font-weight-bold"
  }, [_c('AppBooleanDisplay', {
    attrs: {
      "icon-size": "16px"
    },
    model: {
      value: _vm.transformedItemMaster.isFractureCritical,
      callback: function ($$v) {
        _vm.$set(_vm.transformedItemMaster, "isFractureCritical", $$v);
      },
      expression: "transformedItemMaster.isFractureCritical"
    }
  })], 1)]) : _vm._e(), _vm.transformedItemMaster.isHazardousMaterial ? _c('div', {
    staticClass: "ahd--grid-item"
  }, [_c('p', {
    staticClass: "ahd--label"
  }, [_vm._v("Hazardous mat:")]), _c('p', {
    staticClass: "pl-0 font-weight-bold"
  }, [_c('AppBooleanDisplay', {
    attrs: {
      "icon-size": "16px"
    },
    model: {
      value: _vm.transformedItemMaster.isHazardousMaterial,
      callback: function ($$v) {
        _vm.$set(_vm.transformedItemMaster, "isHazardousMaterial", $$v);
      },
      expression: "transformedItemMaster.isHazardousMaterial"
    }
  })], 1)]) : _vm._e(), _c('div', {
    staticClass: "ahd--grid-item"
  }, [_c('p', {
    staticClass: "ahd--label"
  }, [_vm._v("Initialization:")]), _vm.transformedSelectedEntity ? _c('p', {
    staticClass: "pl-0 font-weight-bold"
  }, [_c('c-icon-fa', {
    attrs: {
      "icon-size": "16px",
      "icon": _vm.transformedSelectedEntity._initializationStatusIconOnly,
      "icon-color": _vm.transformedSelectedEntity._initializationStatusIconColor,
      "tooltip": _vm.transformedSelectedEntity._initializationStatusIconTitle
    }
  })], 1) : _vm._e()]), _vm.transformedItemMaster.isManifestable ? _c('div', {
    staticClass: "ahd--grid-item"
  }, [_c('p', {
    staticClass: "ahd--label"
  }, [_vm._v("Manifestable:")]), _vm.transformedItemMaster ? _c('p', {
    staticClass: "pl-0 font-weight-bold"
  }, [_c('AppBooleanDisplay', {
    attrs: {
      "icon-size": "16px"
    },
    model: {
      value: _vm.transformedItemMaster.isManifestable,
      callback: function ($$v) {
        _vm.$set(_vm.transformedItemMaster, "isManifestable", $$v);
      },
      expression: "transformedItemMaster.isManifestable"
    }
  })], 1) : _vm._e()]) : _vm._e(), _vm.transformedItemMaster.isRefurbishable ? _c('div', {
    staticClass: "ahd--grid-item"
  }, [_c('p', {
    staticClass: "ahd--label"
  }, [_vm._v("Refurbishable:")]), _vm.transformedItemMaster ? _c('p', {
    staticClass: "pl-0 font-weight-bold"
  }, [_c('AppBooleanDisplay', {
    attrs: {
      "icon-size": "16px"
    },
    model: {
      value: _vm.transformedItemMaster.isRefurbishable,
      callback: function ($$v) {
        _vm.$set(_vm.transformedItemMaster, "isRefurbishable", $$v);
      },
      expression: "transformedItemMaster.isRefurbishable"
    }
  })], 1) : _vm._e()]) : _vm._e(), _vm.transformedItemMaster.isSpecialHandling ? _c('div', {
    staticClass: "ahd--grid-item"
  }, [_c('p', {
    staticClass: "ahd--label"
  }, [_vm._v("Special handling:")]), _c('p', {
    staticClass: "pl-0 font-weight-bold"
  }, [_c('AppBooleanDisplay', {
    attrs: {
      "icon-size": "16px"
    },
    model: {
      value: _vm.transformedItemMaster.isSpecialHandling,
      callback: function ($$v) {
        _vm.$set(_vm.transformedItemMaster, "isSpecialHandling", $$v);
      },
      expression: "transformedItemMaster.isSpecialHandling"
    }
  })], 1)]) : _vm._e(), _vm.transformedItemMaster.isSpecialPackaging ? _c('div', {
    staticClass: "ahd--grid-item"
  }, [_c('p', {
    staticClass: "ahd--label"
  }, [_vm._v("Special packaging:")]), _c('p', {
    staticClass: "pl-0 font-weight-bold"
  }, [_c('AppBooleanDisplay', {
    attrs: {
      "icon-size": "16px",
      "tooltip": _vm.transformedItemMaster.isSpecialPackaging ? 'Yes, special packaging is required' : 'No special packaging required'
    },
    model: {
      value: _vm.transformedItemMaster.isSpecialPackaging,
      callback: function ($$v) {
        _vm.$set(_vm.transformedItemMaster, "isSpecialPackaging", $$v);
      },
      expression: "transformedItemMaster.isSpecialPackaging"
    }
  })], 1)]) : _vm._e(), _vm.transformedItemMaster.isTool ? _c('div', {
    staticClass: "ahd--grid-item"
  }, [_c('p', {
    staticClass: "ahd--label"
  }, [_vm._v("Tool:")]), _c('p', {
    staticClass: "pl-0 font-weight-bold"
  }, [_c('AppBooleanDisplay', {
    attrs: {
      "icon-size": "16px"
    },
    model: {
      value: _vm.transformedItemMaster.isTool,
      callback: function ($$v) {
        _vm.$set(_vm.transformedItemMaster, "isTool", $$v);
      },
      expression: "transformedItemMaster.isTool"
    }
  })], 1)]) : _vm._e()]) : _vm._e(), _vm.transformedItemMaster ? _c('div', {
    staticClass: "ahd--column-six"
  }, [_c('div', {
    staticClass: "ahd--grid-item"
  }, [_c('p', {
    staticClass: "ahd--label"
  }, [_vm._v("Inv control:")]), _c('p', {
    staticClass: "pl-0 font-weight-bold monospace_font"
  }, [_vm._v(" " + _vm._s(_vm.transformedItemMaster._inventoryControl) + " ")])]), _c('div', {
    staticClass: "ahd--grid-item"
  }, [_c('p', {
    staticClass: "ahd--label"
  }, [_vm._v("Inv method:")]), _c('p', {
    staticClass: "pl-0 font-weight-bold monospace_font"
  }, [_vm._v(" " + _vm._s(_vm.transformedItemMaster._inventoryMethod) + " ")])]), _vm.transformedItemMaster._showInventoryUnitType ? _c('div', {
    staticClass: "ahd--grid-item"
  }, [_c('p', {
    staticClass: "ahd--label"
  }, [_vm._v("Inv unit:")]), _c('p', {
    staticClass: "pl-0 font-weight-bold monospace_font"
  }, [_vm._v(" " + _vm._s(_vm.transformedItemMaster._inventoryUnitTypeShort) + " ")])]) : _vm._e(), _c('hr', {
    staticClass: "ml-3"
  }), _c('div', {
    staticClass: "ahd--grid-item",
    attrs: {
      "tooltip": "The total quantity of all IN_INVENTORY inventory that is not installed on a parent.",
      "tooltip-position": "left"
    }
  }, [_c('p', {
    staticClass: "ahd--label"
  }, [_vm._v("Avail qty:")]), _vm.transformedSelectedEntity ? _c('p', {
    staticClass: "pl-0 font-weight-bold"
  }, [_c('span', {
    staticClass: "monospace_font"
  }, [_vm._v(_vm._s(_vm.transformedSelectedEntity._quantityAvailable))])]) : _vm._e()]), _c('div', {
    staticClass: "ahd--grid-item",
    attrs: {
      "tooltip": "he total quantity of all IN_INVENTORY inventory that is installed on a parent.",
      "tooltip-position": "left"
    }
  }, [_c('p', {
    staticClass: "ahd--label"
  }, [_vm._v("Inst qty:")]), _vm.transformedSelectedEntity ? _c('p', {
    staticClass: "pl-0 font-weight-bold"
  }, [_c('span', {
    staticClass: "monospace_font"
  }, [_vm._v(_vm._s(_vm.transformedSelectedEntity._quantityInstalled))])]) : _vm._e()]), _c('div', {
    staticClass: "ahd--grid-item",
    attrs: {
      "tooltip": "The total quantity of all IN_INVENTORY inventory.",
      "tooltip-position": "left"
    }
  }, [_c('p', {
    staticClass: "ahd--label"
  }, [_vm._v("Inv qty:")]), _vm.transformedSelectedEntity ? _c('p', {
    staticClass: "pl-0 font-weight-bold"
  }, [_c('span', {
    staticClass: "monospace_font"
  }, [_vm._v(_vm._s(_vm.transformedSelectedEntity._quantityInventory))])]) : _vm._e()]), _c('div', {
    staticClass: "ahd--grid-item",
    attrs: {
      "tooltip": "The total quantity of all IN_INVENTORY inventory at a location with a contract number.",
      "tooltip-position": "left"
    }
  }, [_c('p', {
    staticClass: "ahd--label"
  }, [_vm._v("Mgd qty:")]), _vm.transformedSelectedEntity ? _c('p', {
    staticClass: "pl-0 font-weight-bold"
  }, [_c('span', {
    staticClass: "monospace_font"
  }, [_vm._v(_vm._s(_vm.transformedSelectedEntity._quantityManaged))])]) : _vm._e()]), _c('div', {
    staticClass: "ahd--grid-item",
    attrs: {
      "tooltip": "The total quantity of all IN_INVENTORY inventory at a location without a contract number.",
      "tooltip-position": "left"
    }
  }, [_c('p', {
    staticClass: "ahd--label"
  }, [_vm._v("Unmgd qty:")]), _vm.transformedSelectedEntity ? _c('p', {
    staticClass: "pl-0 font-weight-bold"
  }, [_c('span', {
    staticClass: "monospace_font"
  }, [_vm._v(_vm._s(_vm.transformedSelectedEntity._quantityUnmanaged))])]) : _vm._e()])]) : _vm._e()]), _c('div', {
    staticClass: "ahd--solo-row ahd--grid-item"
  }, [_c('p', {
    staticClass: "ahd--label"
  }, [_vm._v("Description:")]), _vm.transformedSelectedEntity ? _c('span', [_vm._v(_vm._s(_vm.transformedSelectedEntity._description))]) : _vm._e()]), _c('div', {
    staticClass: "ahd--solo-row ahd--grid-item"
  }, [_vm.inventories.length <= 1 ? _c('p', {
    staticClass: "ahd--label"
  }, [_vm._v("Location:")]) : _c('p', {
    staticClass: "ahd--label"
  }, [_vm._v("Locations:")]), _vm.inventories.length ? _c('ul', {
    staticClass: "ahd--inventory-row"
  }, _vm._l(_vm.transformedInventories, function (inv) {
    return _c('li', {
      key: inv.id,
      staticClass: "pl-0 mb-2 font-weight-bold text-truncate",
      class: {
        highlight: inv.id === _vm.inventoryId
      }
    }, [_c('div', {
      staticClass: "d-flex mb-1 align-center"
    }, [_c('div', {
      staticClass: "pr-2"
    }, [_c('span', {
      staticStyle: {
        "font-weight": "normal"
      }
    }, [_vm._v("qty: ")]), _c('span', {
      staticClass: "monospace_font"
    }, [_vm._v(_vm._s(inv.quantity))])]), _c('div', {
      staticClass: "ahd--inventory-status"
    }, [_c('c-icon-fa', {
      staticClass: "pr-1",
      attrs: {
        "icon": _vm.$icons[_vm.EntityType.INVENTORY],
        "icon-color": "#fff",
        "icon-size": "13px"
      }
    }), _vm._v(" " + _vm._s(inv._status) + " ")], 1)]), inv._location ? _c('div', {
      staticClass: "ahd--organization",
      attrs: {
        "tooltip": "Organization"
      }
    }, [_c('c-icon-fa', {
      staticClass: "pr-1",
      attrs: {
        "icon": _vm.$icons[_vm.EntityType.ORGANIZATION],
        "icon-color": "#fff",
        "icon-size": "13px"
      }
    }), _c('span', [_vm._v(_vm._s(inv._location._organizationDisplay))])], 1) : _vm._e(), inv._location ? _c('div', {
      staticClass: "ahd--location mt-1",
      attrs: {
        "tooltip": "Location"
      }
    }, [_c('c-icon-fa', {
      staticClass: "pr-1",
      attrs: {
        "icon": _vm.$icons[_vm.EntityType.LOCATION],
        "icon-color": "#fff",
        "icon-size": "13px"
      }
    }), _c('span', [_vm._v(_vm._s(inv._location._location))])], 1) : _vm._e(), inv.projectCode ? _c('div', {
      staticClass: "ahd--project-code monospace_font mt-1"
    }, [_c('span', [_vm._v(_vm._s(inv.projectCode))])]) : _vm._e(), inv.isBeingAudited ? _c('div', {
      staticClass: "ahd--pia mt-1"
    }, [_c('c-icon-fa', {
      staticClass: "pr-2",
      attrs: {
        "icon": _vm.$icons[_vm.EntityType.PHYSICAL_INVENTORY_AUDIT],
        "icon-color": "#fff",
        "icon-size": "13px"
      }
    }), _c('span', [_vm._v("Being audited")])], 1) : _vm._e()]);
  }), 0) : _c('p', {
    staticClass: "pl-0 font-weight-bold"
  }, [_vm._v("—")])]), _vm.transformedItemMaster && _vm.transformedItemMaster.opsNom ? _c('div', {
    staticClass: "ahd--solo-row ahd--grid-item",
    attrs: {
      "tooltip": `Inherited from Item master ${_vm.transformedItemMaster._itemNumber}`
    }
  }, [_c('p', {
    staticClass: "ahd--label"
  }, [_vm._v("Ops nom:")]), _c('p', {
    staticClass: "pl-0 font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.transformedItemMaster._opsNom) + " ")])]) : _vm._e(), _vm.transformedSelectedEntity && _vm.myLimitedLife && _vm.myLimitedLife.hasLimitedLifeDescription ? _c('div', {
    staticClass: "ahd--solo-row ahd--grid-item",
    attrs: {
      "tooltip": "This is taken from the 'Description' field in the Limited Life section"
    }
  }, [_c('p', {
    staticClass: "ahd--label"
  }, [_vm._v("Limited life:")]), _c('p', {
    staticClass: "pl-0 font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.transformedSelectedEntity.limitedLife.description) + " ")])]) : _vm._e(), _vm.transformedItemMaster && _vm.transformedItemMaster.hazardousMaterialText ? _c('div', {
    staticClass: "ahd--solo-row ahd--grid-item",
    attrs: {
      "tooltip": `Inherited from Item master ${_vm.transformedItemMaster._itemNumber}`
    }
  }, [_c('p', {
    staticClass: "ahd--label"
  }, [_vm._v("Hazardous material:")]), _c('span', [_vm._v(_vm._s(_vm.transformedItemMaster._hazardousMaterialText))])]) : _vm._e(), _vm.transformedSelectedEntity && _vm.transformedSelectedEntity.overrideHazardousMaterialText ? _c('div', {
    staticClass: "ahd--solo-row ahd--grid-item",
    attrs: {
      "tooltip": "Specifically for this instance"
    }
  }, [_c('p', {
    staticClass: "ahd--label"
  }, [_vm._v("Hazardous material:")]), _c('span', [_vm._v(_vm._s(_vm.transformedSelectedEntity._overrideHazardousMaterialText))])]) : _vm._e(), _vm.transformedItemMaster && _vm.transformedItemMaster.specialHandlingText ? _c('div', {
    staticClass: "ahd--solo-row ahd--grid-item",
    attrs: {
      "tooltip": `Inherited from Item master ${_vm.transformedItemMaster._itemNumber}`
    }
  }, [_c('p', {
    staticClass: "ahd--label"
  }, [_vm._v("Special handling:")]), _c('span', [_vm._v(_vm._s(_vm.transformedItemMaster._specialHandlingText))])]) : _vm._e(), _vm.transformedItemMaster && _vm.transformedItemMaster.cleanlinessTrackingText ? _c('div', {
    staticClass: "ahd--solo-row ahd--grid-item",
    attrs: {
      "tooltip": `Inherited from Item master ${_vm.transformedItemMaster._itemNumber}`
    }
  }, [_c('p', {
    staticClass: "ahd--label"
  }, [_vm._v("Cleanliness tracking:")]), _c('span', [_vm._v(_vm._s(_vm.transformedItemMaster._cleanlinessTrackingText))])]) : _vm._e(), _vm.transformedItemMaster && _vm.transformedItemMaster.specialPackagingText ? _c('div', {
    staticClass: "ahd--solo-row ahd--grid-item",
    attrs: {
      "tooltip": `Inherited from Item master ${_vm.transformedItemMaster._itemNumber}`
    }
  }, [_c('p', {
    staticClass: "ahd--label"
  }, [_vm._v("Special packaging:")]), _c('span', [_vm._v(_vm._s(_vm.transformedItemMaster._specialPackagingText))])]) : _vm._e(), _vm.isArchived ? _c('div', {
    staticClass: "ahd--solo-row mt-2"
  }, [_c('AppPageDescription', {
    attrs: {
      "color": "error"
    }
  }, [_c('strong', {
    staticClass: "error--text lighten-2"
  }, [_vm._v("Archived")])])], 1) : _vm._e()]) : _vm._e(), !_vm.isFetching && _vm.isMinimized && _vm.selectedEntity ? _c('div', {
    staticClass: "is_initialized"
  }, [_c('MiniItemInstance', {
    attrs: {
      "id": _vm.selectedEntity.id,
      "show-manage-link": false
    }
  })], 1) : _vm._e(), _c('div', {
    staticClass: "ahd--solo-row mt-2",
    class: {
      is_fetching: _vm.isFetching,
      is_initialized: _vm.shouldShowItemInstanceDisplay
    }
  }, [_vm.transformedItemDrawing && _vm.transformedItemDrawing._urlManage ? _c('router-link', {
    staticClass: "adh_button_link",
    attrs: {
      "tooltip": "View Item drawing manage page",
      "tooltip-position": "right",
      "to": _vm.transformedItemDrawing._urlManage
    }
  }, [_c('c-icon-fa', {
    attrs: {
      "icon": _vm.$icons[_vm.EntityType.ITEM_DRAWING],
      "icon-color": "#fff",
      "icon-size": "16px"
    }
  })], 1) : _vm._e(), _vm.transformedItemMaster && _vm.transformedItemMaster._urlManage ? _c('router-link', {
    staticClass: "adh_button_link ml-2",
    attrs: {
      "tooltip": "View Item master manage page",
      "tooltip-position": "right",
      "to": _vm.transformedItemMaster._urlManage
    }
  }, [_c('c-icon-fa', {
    attrs: {
      "icon": _vm.$icons[_vm.EntityType.ITEM_MASTER],
      "icon-color": "#fff",
      "icon-size": "16px"
    }
  })], 1) : _vm._e(), _vm.transformedSelectedEntity && _vm.transformedSelectedEntity._urlManage ? _c('router-link', {
    staticClass: "adh_button_link ml-2",
    attrs: {
      "tooltip": "View Item instance manage page",
      "tooltip-position": "right",
      "to": _vm.transformedSelectedEntity._urlManage
    }
  }, [_c('c-icon-fa', {
    attrs: {
      "icon": _vm.$icons[_vm.EntityType.ITEM_INSTANCE],
      "icon-color": "#fff",
      "icon-size": "16px"
    }
  })], 1) : _vm._e(), _vm.hasLinks ? _c('c-menu', {
    attrs: {
      "align-left": "",
      "border-color": "var(--v-comments-base)",
      "border-size": "2px"
    }
  }, [_c('ButtonGeneric', {
    attrs: {
      "slot": "activator",
      "small": "",
      "icon-color": "white",
      "color": "var(--v-comments-base)"
    },
    slot: "activator"
  }, [_c('c-icon-fa', {
    staticClass: "pr-2",
    attrs: {
      "icon": _vm.$icons.link,
      "icon-color": "#fff"
    }
  }), _vm._v(" Links ")], 1), _vm._l(_vm.transformedLinks, function (link, index) {
    return _c('div', {
      key: index
    }, [link.currentVersionUrl ? _c('c-menu-item', {
      attrs: {
        "href": link.currentVersionUrl,
        "icon": _vm.$icons.link,
        "icon-color": "var(--cosmic-text)"
      }
    }, [_vm._v(" " + _vm._s(link.currentVersionUrl)), _c('br'), link.currentRevision ? _c('small', {
      staticClass: "d-block"
    }, [_vm._v(" current rev: "), _c('span', {
      staticClass: "monospace_font"
    }, [_vm._v(" " + _vm._s(link._currentRevision) + " ")])]) : _vm._e(), link.referenceNumber ? _c('small', {
      staticClass: "d-block"
    }, [_vm._v(" ref num: "), _c('span', {
      staticClass: "monospace_font"
    }, [_vm._v(" " + _vm._s(link._referenceNumber) + " ")])]) : _vm._e(), link.subType ? _c('small', {
      staticClass: "d-block"
    }, [_vm._v("type: " + _vm._s(link._subType))]) : _vm._e()]) : _vm._e(), _c('v-divider'), link.workingVersionUrl ? _c('c-menu-item', {
      attrs: {
        "href": link.workingVersionUrl,
        "icon": _vm.$icons.link,
        "icon-color": "var(--cosmic-text)"
      }
    }, [_vm._v(" " + _vm._s(link.workingVersionUrl)), _c('br'), link.currentRevision ? _c('small', {
      staticClass: "d-block"
    }, [_vm._v(" current rev: "), _c('span', {
      staticClass: "monospace_font"
    }, [_vm._v(" " + _vm._s(link._currentRevision) + " ")])]) : _vm._e(), link.referenceNumber ? _c('small', {
      staticClass: "d-block"
    }, [_vm._v(" ref num: "), _c('span', {
      staticClass: "monospace_font"
    }, [_vm._v(" " + _vm._s(link._referenceNumber) + " ")])]) : _vm._e(), link.subType ? _c('small', {
      staticClass: "d-block"
    }, [_vm._v("type: " + _vm._s(link._subType))]) : _vm._e()]) : _vm._e()], 1);
  })], 2) : _vm._e(), _vm.transformedSelectedEntity && _vm.changelogHref ? _c('router-link', {
    staticClass: "adh_button_link ml-2",
    attrs: {
      "tooltip": `View the changelog for ${_vm.transformedSelectedEntity._itemNumber}`,
      "tooltip-position": "right",
      "to": _vm.changelogHref
    }
  }, [_c('c-icon-fa', {
    attrs: {
      "icon": _vm.$icons.history,
      "icon-color": "#fff",
      "icon-size": "16px"
    }
  })], 1) : _vm._e(), _vm.transformedSelectedEntity && _vm.computedId ? _c('router-link', {
    staticClass: "adh_button_link ml-2",
    attrs: {
      "tooltip": `View all Activities for ${_vm.transformedSelectedEntity._itemNumber}`,
      "tooltip-position": "right",
      "to": `/reporting/hardware_activity/results?itemInstanceId=${_vm.computedId}`
    }
  }, [_c('c-icon-fa', {
    attrs: {
      "icon": _vm.$icons[_vm.EntityType.ACTIVITY],
      "icon-color": "#fff",
      "icon-size": "16px"
    }
  })], 1) : _vm._e(), _c('div', {
    staticClass: "d-flex align-center ml-2"
  }, [!_vm.isMinimized ? _c('c-button-icon', {
    attrs: {
      "icon-size": "16px",
      "icon": "fa-regular fa-arrow-down-left-and-arrow-up-right-to-center",
      "tooltip": "Minimize card",
      "tooltip-position": "right"
    },
    on: {
      "c-click": _vm.onClickOfMinimize
    }
  }) : _vm.isMinimized ? _c('c-button-icon', {
    attrs: {
      "icon-size": "16px",
      "icon": "fa-regular fa-arrow-up-right-and-arrow-down-left-from-center",
      "tooltip": "Maximize card",
      "tooltip-position": "right"
    },
    on: {
      "c-click": _vm.onClickOfMaximize
    }
  }) : _vm._e()], 1)], 1), _c('v-slide-y-transition', {
    attrs: {
      "hide-on-leave": ""
    }
  }, [_vm.isFetching ? _c('AppLoader', {
    attrs: {
      "type": "linear",
      "color": _vm.EntityType.ITEM_INSTANCE
    }
  }) : _vm._e()], 1)], 1);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('strong', {
    staticClass: "pl-2"
  }, [_c('i', [_vm._v("No hardware dates")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "ahd--no_limited_life_set"
  }, [_c('strong', {
    staticClass: "pl-2"
  }, [_c('i', [_vm._v("No LL quotas set")])])]);
}];
export { render, staticRenderFns };