import { __decorate, __metadata } from "tslib";
import { Component, Emit, Mixins, Prop } from 'vue-property-decorator';
import BaseVue from '~/nasa_ui/base/BaseVue';
import { COSMIC_COLORS } from '~/nasa_ui/constants/colors';
let AppTimeline = class AppTimeline extends Mixins(BaseVue) {
    hoveredMarker = null;
    eventDefaults = {
        detail: null,
        emphasizedIconSize: '28px',
        endDate: null,
        endLabel: null,
        icon: this.$icons[this.EntityType.EVENT],
        iconColor: 'var(--v-events-base)',
        iconSize: '20px',
        isEmphasized: false,
        startDate: this.$dateDisplay(new Date()),
        startLabel: null
    };
    events;
    get earliestDate() {
        return this.sortedEvents[0].startDate;
    }
    get latestDate() {
        return this.sortedEvents.reduce((latest, event) => {
            if (!event.endDate) {
                return new Date(event.startDate).getTime() > new Date(latest).getTime() ? event.startDate : latest;
            }
            return new Date(event.endDate).getTime() > new Date(latest).getTime() ? event.endDate : latest;
        }, this.sortedEvents[0].startDate);
    }
    get plottedFills() {
        if (!this.plottedMarkers.length) {
            return [];
        }
        return this.plottedMarkers.reduce((fills, marker, index) => {
            if (!marker.isWindow) {
                return fills;
            }
            const startMarker = marker;
            const endMarker = this.plottedMarkers.find((m) => m.id === marker.endMarkerId);
            if (!endMarker) {
                return fills;
            }
            const width = endMarker.translateX - startMarker.translateX;
            const fillId = `${startMarker.id}-${endMarker.id}`;
            return [
                ...fills,
                {
                    color: marker.color,
                    endDate: endMarker.date,
                    endMarkerId: endMarker.id,
                    id: fillId,
                    isActive: this.hoveredMarker?.id ? fillId.includes(this.hoveredMarker.id) : false,
                    startDate: startMarker.date,
                    startMarkerId: startMarker.id,
                    translateX: startMarker.translateX,
                    width
                }
            ];
        }, []);
    }
    get plottedMarkers() {
        if (!this.sortedEvents.length) {
            return [];
        }
        const earliestDateInMilliseconds = new Date(this.earliestDate).getTime();
        const colorsForRanges = [
            COSMIC_COLORS['--cosmic-teal-accent-4'],
            COSMIC_COLORS['--cosmic-light-blue-accent-4'],
            COSMIC_COLORS['--cosmic-deep-purple-accent-4'],
            COSMIC_COLORS['--cosmic-light-green-accent-4']
        ];
        let pairs = 0;
        const markers = this.sortedEvents.flatMap((event) => {
            const startDateInMilliseconds = new Date(event.startDate).getTime() - earliestDateInMilliseconds;
            const startTranslateX = (startDateInMilliseconds / this.timelineLengthInMilliseconds) * 100;
            if (!event.endDate) {
                return {
                    color: 'var(--cosmic-gray-darken-2)',
                    date: event.startDate,
                    event,
                    id: crypto.randomUUID(),
                    isWindow: false,
                    label: event.startLabel || '',
                    tooltip: event.tooltip || null,
                    translateX: startTranslateX
                };
            }
            const markerColor = colorsForRanges[pairs % colorsForRanges.length];
            pairs++;
            const endDateInMilliseconds = new Date(event.endDate).getTime() - earliestDateInMilliseconds;
            const endTranslateX = (endDateInMilliseconds / this.timelineLengthInMilliseconds) * 100;
            const startMarkerId = crypto.randomUUID();
            const endMarkerId = crypto.randomUUID();
            const startMarker = {
                color: markerColor,
                date: event.startDate,
                endMarkerId,
                event,
                id: startMarkerId,
                isWindow: true,
                label: event.startLabel || '',
                tooltip: event.tooltip || null,
                translateX: startTranslateX
            };
            const endMarker = {
                color: markerColor,
                date: event.endDate,
                event,
                id: endMarkerId,
                isWindow: true,
                label: event.endLabel || '',
                startMarkerId,
                tooltip: event.tooltip || null,
                translateX: endTranslateX
            };
            return [startMarker, endMarker];
        });
        markers.sort((a, b) => {
            return new Date(a.date).getTime() - new Date(b.date).getTime();
        });
        return markers;
    }
    get sortedEvents() {
        if (!this.events.length) {
            return [];
        }
        const events = [...this.events].map((event) => {
            return Object.assign({}, this.eventDefaults, {
                ...event,
                id: event.id || crypto.randomUUID()
            });
        });
        events.sort((a, b) => {
            return new Date(a.startDate).getTime() - new Date(b.startDate).getTime();
        });
        return events;
    }
    get timelineLengthInMilliseconds() {
        return new Date(this.latestDate).getTime() - new Date(this.earliestDate).getTime();
    }
    getVerticalOffsetForMarker(marker) {
        const index = this.plottedMarkers.findIndex((m) => m.id === marker.id);
        const existingMarkersAtDate = this.plottedMarkers.slice(0, index).filter((m) => m.date === marker.date);
        const offset = marker.isWindow ? 57 : 30;
        return `${existingMarkersAtDate.length * offset}px`;
    }
    isDuplicateDateMarker(marker) {
        const index = this.plottedMarkers.findIndex((m) => m.id === marker.id);
        return this.plottedMarkers.slice(0, index).some((m) => m.date === marker.date);
    }
    onEventIconMouseEnter(marker) {
        this.hoveredMarker = marker;
    }
    onEventIconMouseLeave() {
        this.hoveredMarker = null;
    }
    emitEventClick(marker) { }
};
__decorate([
    Prop({
        type: Array,
        required: true
    }),
    __metadata("design:type", Array)
], AppTimeline.prototype, "events", void 0);
__decorate([
    Emit('event-click'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", void 0)
], AppTimeline.prototype, "emitEventClick", null);
AppTimeline = __decorate([
    Component
], AppTimeline);
export default AppTimeline;
