var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('AppModal', {
    attrs: {
      "color": _vm.EntityType.CHANGE_SET,
      "modal-width": "95vw",
      "hide-title-section": ""
    },
    on: {
      "modal-close": _vm.emitModalClose,
      "keydown": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])) return null;
        return _vm.emitModalClose.apply(null, arguments);
      }
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function () {
        return [_c('div', {
          staticClass: "c-grid"
        }, [_c('div', {
          staticClass: "col-sixth"
        }, [_c('AppSelectEnum', {
          attrs: {
            "chips": false,
            "display": _vm.DeliveryOrderLineItemStatusDisplay,
            "enum": _vm.DeliveryOrderLineItemStatus,
            "clearable": "",
            "label": "Item status"
          },
          model: {
            value: _vm.formData.deliveryOrderLineItemStatus,
            callback: function ($$v) {
              _vm.$set(_vm.formData, "deliveryOrderLineItemStatus", $$v);
            },
            expression: "formData.deliveryOrderLineItemStatus"
          }
        })], 1), _c('div', {
          staticClass: "col-sixth"
        }, [_c('AppSelectEnum', {
          attrs: {
            "chips": false,
            "display": _vm.DeliveryOrderLineItemTypeDisplay,
            "enum": _vm.DeliveryOrderLineItemType,
            "clearable": "",
            "label": "Item type"
          },
          model: {
            value: _vm.formData.lineItemType,
            callback: function ($$v) {
              _vm.$set(_vm.formData, "lineItemType", $$v);
            },
            expression: "formData.lineItemType"
          }
        })], 1), _c('div', {
          staticClass: "col-half"
        }, [_c('AppContact', {
          attrs: {
            "label": "Task owner"
          },
          model: {
            value: _vm.formData.ownerContactId,
            callback: function ($$v) {
              _vm.$set(_vm.formData, "ownerContactId", $$v);
            },
            expression: "formData.ownerContactId"
          }
        })], 1)]), _c('div', {
          staticClass: "c-grid mt-2"
        }, [_c('div', {
          staticClass: "col-sixth"
        }, [_c('AppText', {
          attrs: {
            "label": "DIL #"
          },
          model: {
            value: _vm.formData.deliveryItemsListNumber,
            callback: function ($$v) {
              _vm.$set(_vm.formData, "deliveryItemsListNumber", $$v);
            },
            expression: "formData.deliveryItemsListNumber"
          }
        })], 1), _c('div', {
          staticClass: "col-twelveth"
        }, [_c('AppText', {
          attrs: {
            "label": "J Doc #"
          },
          model: {
            value: _vm.formData.jDocumentNumber,
            callback: function ($$v) {
              _vm.$set(_vm.formData, "jDocumentNumber", $$v);
            },
            expression: "formData.jDocumentNumber"
          }
        })], 1), _c('div', {
          staticClass: "col-sixth"
        }, [_c('AppText', {
          attrs: {
            "label": "Shipper #"
          },
          model: {
            value: _vm.formData.shipperNumber,
            callback: function ($$v) {
              _vm.$set(_vm.formData, "shipperNumber", $$v);
            },
            expression: "formData.shipperNumber"
          }
        })], 1), _c('div', {
          staticClass: "col-fourth"
        }, [_c('AppText', {
          attrs: {
            "label": "WBS",
            "pattern": "/^W\\d{4}-\\d{5}-\\d{5}$/",
            "placeholder": "W0000-00000-00000"
          },
          model: {
            value: _vm.formData.workBreakdownStructureNumber,
            callback: function ($$v) {
              _vm.$set(_vm.formData, "workBreakdownStructureNumber", $$v);
            },
            expression: "formData.workBreakdownStructureNumber"
          }
        })], 1)]), _c('div', {
          staticClass: "c-grid mt-2"
        }, [_c('div', {
          staticClass: "col-third"
        }, [_c('AppDate', {
          attrs: {
            "label": "Open"
          },
          model: {
            value: _vm.formData.openedDate,
            callback: function ($$v) {
              _vm.$set(_vm.formData, "openedDate", $$v);
            },
            expression: "formData.openedDate"
          }
        })], 1), _c('div', {
          staticClass: "col-third"
        }, [_c('AppDate', {
          attrs: {
            "label": "Est. completion"
          },
          model: {
            value: _vm.formData.projectedDate,
            callback: function ($$v) {
              _vm.$set(_vm.formData, "projectedDate", $$v);
            },
            expression: "formData.projectedDate"
          }
        })], 1)]), _c('div', {
          staticClass: "c-grid mt-2"
        }, [_c('div', {
          staticClass: "col-twelveth"
        }, [_c('AppNumber', {
          attrs: {
            "label": "Quantity"
          },
          model: {
            value: _vm.formData.lineItemQuantity,
            callback: function ($$v) {
              _vm.$set(_vm.formData, "lineItemQuantity", $$v);
            },
            expression: "formData.lineItemQuantity"
          }
        })], 1), _c('div', {
          staticClass: "col-fourth"
        }, [_c('AppSelectMegaAssemblyTemplate', {
          attrs: {
            "label": "Need"
          },
          model: {
            value: _vm.formData.need,
            callback: function ($$v) {
              _vm.$set(_vm.formData, "need", $$v);
            },
            expression: "formData.need"
          }
        })], 1)]), _c('div', {
          staticClass: "c-grid mt-3"
        }, [_vm.selectedPartReferenceEdited ? _c('div', {
          staticClass: "col-full"
        }, [_c('AppPageDescription', {
          attrs: {
            "color": "warning"
          }
        }, [_vm._v(" This Part Reference will be updated wherever it is used in the system. ")])], 1) : _vm._e(), _c('div', {
          staticClass: "col-full"
        }, [_c('SelectorPartReference', {
          attrs: {
            "part-reference-id": _vm.existingPartReferenceId
          },
          on: {
            "edited-part-reference-selected": _vm.onPartReferenceEdited,
            "new-part-reference-selected": _vm.onNewPartReferenceSelected,
            "part-reference-selected": _vm.onPartReferenceSelected
          }
        })], 1)])];
      },
      proxy: true
    }, {
      key: "actions",
      fn: function () {
        return [_vm.lineItem ? _c('ButtonSave', {
          attrs: {
            "isSaving": _vm.isSaving
          },
          on: {
            "click": _vm.onClickOfEmitLineItem
          }
        }, [_vm._v("Save")]) : _c('ButtonCreate', {
          on: {
            "click": _vm.onClickOfEmitLineItem
          }
        }, [_vm._v("Add")]), _c('ButtonCancel', {
          on: {
            "click": _vm.emitModalClose
          }
        })];
      },
      proxy: true
    }])
  });
};
var staticRenderFns = [];
export { render, staticRenderFns };