var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('AppModal', {
    attrs: {
      "color": "var(--v-item_instances-base)",
      "hide-title-section": "",
      "modal-width": "75vw"
    },
    on: {
      "modal-close": _vm.emitModalClose,
      "keydown": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])) return null;
        return _vm.emitModalClose.apply(null, arguments);
      }
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function () {
        return [_vm.transformedSelectedEntity ? _c('div', {
          staticClass: "c-grid"
        }, [_c('div', {
          staticClass: "col-third"
        }, [_c('AppBlockquote', {
          attrs: {
            "title": "Name"
          }
        }, [_c('span', {
          staticClass: "monospace_font"
        }, [_vm._v(" " + _vm._s(_vm.transformedSelectedEntity._name) + " ")])])], 1), _c('div', {
          staticClass: "col-two-thirds"
        }, [_c('AppBlockquote', {
          attrs: {
            "title": "Description"
          }
        }, [_vm._v(_vm._s(_vm.transformedSelectedEntity._description))])], 1), _c('div', {
          staticClass: "col-full"
        }, [_c('AppBlockquote', {
          attrs: {
            "title": "Drawing number prefixes"
          }
        }, [_vm.transformedSelectedEntity.drawingNumberPrefix.length ? _c('ul', _vm._l(_vm.transformedSelectedEntity._drawingNumberPrefix, function (dn) {
          return _c('li', {
            key: dn
          }, [_c('span', {
            staticClass: "monospace_font"
          }, [_vm._v(" " + _vm._s(dn) + " ")])]);
        }), 0) : _vm._e()])], 1)]) : _vm._e(), _vm.transformedPartReferences.length ? _c('AppBlockquote', {
          attrs: {
            "title": "Matching parts"
          }
        }, [_c('AppFormWrapper', {
          attrs: {
            "size": "1",
            "color": "item_instances"
          }
        }, [_c('div', {
          staticClass: "c-grid part_container"
        }, _vm._l(_vm.transformedPartReferences, function (part) {
          return _c('div', {
            key: part.id,
            staticClass: "col-full part_display pa-1"
          }, [_c('AppPartReferenceDisplay', {
            attrs: {
              "part-reference-id": part.id
            }
          })], 1);
        }), 0)])], 1) : _c('AppPageDescription', {
          attrs: {
            "color": "warning",
            "remove-margin-left": ""
          }
        }, [_vm._v(" No matching parts found. ")])];
      },
      proxy: true
    }, {
      key: "actions",
      fn: function () {
        return [_c('ButtonCancel', {
          on: {
            "click": _vm.emitModalClose
          }
        }, [_vm._v(" Cancel "), _c('small', {
          staticClass: "ml-2"
        }, [_vm._v("(esc)")])])];
      },
      proxy: true
    }])
  });
};
var staticRenderFns = [];
export { render, staticRenderFns };