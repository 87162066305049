var _a, _b, _c, _d;
import { __decorate, __metadata } from "tslib";
import { Component, Emit, Mixins, Prop, Watch } from 'vue-property-decorator';
import { DeliveryOrderLineItemDetailsRequest, DeliveryOrderLineItemStatus, DeliveryOrderLineItemType } from '~/db_types/swagger_types';
import BaseModal from '~/nasa_ui/base/BaseModal';
import { Maybe } from '~/nasa_ui/types';
import { DeliveryOrderLineItemStatusDisplay, DeliveryOrderLineItemTypeDisplay } from '~/nasa_ui/types/enums/document';
import { putPartReference } from '~/nasa_ui/utils';
/**
 * This modal is used to create the swagger obj DeliveryOrderLineItemDetailsRequest
 *
 * Modal DOES NOT make http requests; instead it emits the DeliveryOrderLineItemDetailsRequest obj on click of the green go button
 */
let ModalDeliveryOrderLineItemDetailsRequest = class ModalDeliveryOrderLineItemDetailsRequest extends Mixins(BaseModal) {
    DeliveryOrderLineItemStatus = DeliveryOrderLineItemStatus;
    DeliveryOrderLineItemStatusDisplay = DeliveryOrderLineItemStatusDisplay;
    DeliveryOrderLineItemType = DeliveryOrderLineItemType;
    DeliveryOrderLineItemTypeDisplay = DeliveryOrderLineItemTypeDisplay;
    selectedPartReference = null;
    selectedPartReferenceEdited = null;
    selectedPartReferenceRequest = null;
    formData = {
        attributes: {},
        deliveryItemsListNumber: null,
        deliveryOrderLineItemStatus: null,
        jDocumentNumber: null,
        lineItemQuantity: null,
        lineItemType: null,
        openedDate: null,
        ownerContactId: null,
        projectedDate: null,
        shipperNumber: null,
        workBreakdownStructureNumber: null
    };
    lineItem;
    get existingPartReferenceId() {
        return this.lineItem?.partReferenceId;
    }
    async onClickOfEmitLineItem() {
        if (this.selectedPartReferenceEdited) {
            await this.updateExistingPartReference(this.selectedPartReferenceEdited);
        }
        const obj = {
            attributes: this.formData.attributes,
            deliveryItemsListNumber: this.formData.deliveryItemsListNumber,
            deliveryOrderLineItemStatus: this.formData.deliveryOrderLineItemStatus ?? DeliveryOrderLineItemStatus.IN_PROCESS,
            jDocumentNumber: this.formData.jDocumentNumber,
            lineItemQuantity: this.formData.lineItemQuantity,
            lineItemType: this.formData.lineItemType ?? undefined,
            openedDate: this.formData.openedDate,
            ownerContactId: this.formData.ownerContactId,
            projectedDate: this.formData.projectedDate,
            shipperNumber: this.formData.shipperNumber,
            workBreakdownStructureNumber: this.formData.workBreakdownStructureNumber
        };
        if (this.selectedPartReference) {
            obj.partReferenceId = this.selectedPartReference.id;
            // emit the full shape in case the consumer needs to display the part reference
            this.emitPartReference(this.selectedPartReference);
        }
        else if (this.selectedPartReferenceEdited) {
            obj.partReferenceId = this.selectedPartReferenceEdited.id;
            // emit the full shape in case the consumer needs to display the part reference
            this.emitPartReference(this.selectedPartReferenceEdited);
        }
        else if (this.selectedPartReferenceRequest) {
            obj.createPartReference = { ...this.selectedPartReferenceRequest };
            // emit the full shape for consistency
            this.emitPartReference({ ...this.selectedPartReferenceRequest });
        }
        else {
            // emit null because we're not creating a part reference
            this.emitPartReference(null);
        }
        this.emitLineItem(obj);
        this.closeDialog();
    }
    onNewPartReferenceSelected(val) {
        this.selectedPartReferenceRequest = val;
    }
    onPartReferenceEdited(val) {
        this.selectedPartReferenceEdited = val;
    }
    onPartReferenceSelected(val) {
        this.selectedPartReference = val;
    }
    async updateExistingPartReference(partReference) {
        const request = {
            attributes: {},
            asBuiltNumber: partReference?.asBuiltNumber,
            birthDate: partReference?.birthDate,
            calibrationNumber: partReference?.calibrationNumber,
            contractEndingItemNumber: partReference?.contractEndingItemNumber,
            drawingDescription: partReference?.drawingDescription,
            drawingNumber: partReference?.drawingNumber,
            isTool: partReference?.isTool ?? false,
            itemClass: partReference?.itemClass,
            lastCalibrationDate: partReference?.lastCalibrationDate,
            location: partReference?.location,
            lotNumber: partReference?.lotNumber,
            serialNumber: partReference?.serialNumber,
            shelfLifeExpirationDate: partReference?.shelfLifeExpirationDate,
            side: partReference?.side,
            size: partReference?.size,
            usageLifeExpirationDate: partReference?.usageLifeExpirationDate
        };
        try {
            const resp = await putPartReference(partReference.id, request);
            return resp.data;
        }
        catch (err) {
            if (err instanceof Error) {
                this.$errorUtility({ err });
            }
            else {
                console.error(`Unexpected error: ${err}`);
            }
        }
    }
    emitLineItem(lineItem) { }
    // The DeliveryOrderLineItemDetailsRequest shape will only have a
    // partReferenceId if an existing part reference is chosen, so we'll emit the
    // full shape separately here, in case the consumer needs it.
    emitPartReference(partReference) { }
    onChangeOfLineItem(lineItem) {
        if (!lineItem) {
            return;
        }
        this.formData.attributes = lineItem.attributes;
        this.formData.deliveryItemsListNumber = lineItem.deliveryItemsListNumber;
        this.formData.deliveryOrderLineItemStatus = lineItem.deliveryOrderLineItemStatus;
        this.formData.jDocumentNumber = lineItem.jDocumentNumber;
        this.formData.lineItemQuantity = lineItem.lineItemQuantity;
        this.formData.lineItemType = lineItem.lineItemType;
        this.formData.openedDate = lineItem.openedDate;
        this.formData.ownerContactId = lineItem.ownerContactId;
        this.formData.projectedDate = lineItem.projectedDate;
        this.formData.shipperNumber = lineItem.shipperNumber;
        this.formData.workBreakdownStructureNumber = lineItem.workBreakdownStructureNumber;
    }
};
__decorate([
    Prop({
        type: Object
    }),
    __metadata("design:type", typeof (_a = typeof Maybe !== "undefined" && Maybe) === "function" ? _a : Object)
], ModalDeliveryOrderLineItemDetailsRequest.prototype, "lineItem", void 0);
__decorate([
    Emit('line-item'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_b = typeof DeliveryOrderLineItemDetailsRequest !== "undefined" && DeliveryOrderLineItemDetailsRequest) === "function" ? _b : Object]),
    __metadata("design:returntype", void 0)
], ModalDeliveryOrderLineItemDetailsRequest.prototype, "emitLineItem", null);
__decorate([
    Emit('part-reference'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_c = typeof Maybe !== "undefined" && Maybe) === "function" ? _c : Object]),
    __metadata("design:returntype", void 0)
], ModalDeliveryOrderLineItemDetailsRequest.prototype, "emitPartReference", null);
__decorate([
    Watch('lineItem', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_d = typeof Maybe !== "undefined" && Maybe) === "function" ? _d : Object]),
    __metadata("design:returntype", void 0)
], ModalDeliveryOrderLineItemDetailsRequest.prototype, "onChangeOfLineItem", null);
ModalDeliveryOrderLineItemDetailsRequest = __decorate([
    Component
], ModalDeliveryOrderLineItemDetailsRequest);
export default ModalDeliveryOrderLineItemDetailsRequest;
