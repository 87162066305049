var _a;
import { __decorate, __metadata } from "tslib";
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import BaseModal from '~/nasa_ui/base/BaseModal';
import { Maybe } from '~/nasa_ui/types';
import { getPartReferences } from '~/nasa_ui/utils';
import { transformAutoHardwareListResponseWithCounts, transformPartReferenceResponses } from '~/nasa_ui/utils/helpers/transformEntityToTableFormat';
let ModalAutoHardwareListInfo = class ModalAutoHardwareListInfo extends Mixins(BaseModal) {
    partReferences = null;
    selectedEntity = null;
    id;
    get transformedSelectedEntity() {
        return this.selectedEntity ? transformAutoHardwareListResponseWithCounts([this.selectedEntity])[0] : null;
    }
    get transformedPartReferences() {
        return this.partReferences ? transformPartReferenceResponses(this.partReferences) : [];
    }
    async fetchAutoHardwareList(id) {
        if (!id) {
            return;
        }
        try {
            const resp = await this.$http.get(`/auto-hardware-lists/${id}`);
            if (!resp.data) {
                throw new Error('Could not fetch the Auto Hardware List');
            }
            this.selectedEntity = resp.data;
            this.fetchAutoHardwareListPartReferences(id);
        }
        catch (err) {
            this.$errorUtility({
                err,
                backupErrorText: 'Could not fetch the Auto Hardware List'
            });
        }
    }
    async fetchAutoHardwareListPartReferences(id) {
        if (!id) {
            return;
        }
        try {
            const resp = await getPartReferences({
                autoHardwareListId: [id]
            });
            if (!resp.data) {
                throw new Error(`Failed to fetch parts from ${this.selectedEntity?.name ?? 'listing'}.`);
            }
            this.partReferences = resp.data.results ?? [];
        }
        catch (err) {
            if (err instanceof Error) {
                this.$errorUtility({ err });
            }
            else {
                console.error(`Unexpected error: ${err}`);
            }
        }
    }
};
__decorate([
    Prop({
        type: String,
        required: true
    }),
    __metadata("design:type", String)
], ModalAutoHardwareListInfo.prototype, "id", void 0);
__decorate([
    Watch('id', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_a = typeof Maybe !== "undefined" && Maybe) === "function" ? _a : Object]),
    __metadata("design:returntype", Promise)
], ModalAutoHardwareListInfo.prototype, "fetchAutoHardwareList", null);
ModalAutoHardwareListInfo = __decorate([
    Component
], ModalAutoHardwareListInfo);
export default ModalAutoHardwareListInfo;
