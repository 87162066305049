var _a;
import { __decorate, __metadata } from "tslib";
import { Component, Mixins, Prop } from 'vue-property-decorator';
import { AssemblyTemplateType } from '~/db_types/swagger_types';
import { DEFAULT_DASH } from '~/nasa_ui/constants';
import AutocompleteMixin from '~/nasa_ui/mixins/AutocompleteMixin';
import LoaderMixin from '~/nasa_ui/mixins/LoaderMixin';
import { Maybe } from '~/nasa_ui/types';
import { getAssemblyTemplates, sortObjectBy } from '~/nasa_ui/utils';
let AppSelectMegaAssemblyTemplate = class AppSelectMegaAssemblyTemplate extends Mixins(LoaderMixin, AutocompleteMixin) {
    options = [];
    label;
    get computedLabel() {
        return this.label ? this.label : this.multiple ? 'MEGAs' : 'MEGA';
    }
    async created() {
        try {
            this.isLoading = true;
            const resp = await getAssemblyTemplates({
                rootsOnly: true,
                subType: [AssemblyTemplateType.MEGA, AssemblyTemplateType.SEMU, AssemblyTemplateType.SUPER],
                take: -1
            });
            this.options = (resp.data.results || [])
                .map((ass) => ({
                displayText: ass.name || DEFAULT_DASH,
                value: ass.id
            }))
                .sort(sortObjectBy('displayText'));
            this.isLoading = false;
        }
        catch (err) {
            this.isLoading = false;
            this.$errorUtility({ err, backupErrorText: 'Failed to fetch all megas.' });
        }
    }
};
__decorate([
    Prop({
        required: false,
        type: String
    }),
    __metadata("design:type", typeof (_a = typeof Maybe !== "undefined" && Maybe) === "function" ? _a : Object)
], AppSelectMegaAssemblyTemplate.prototype, "label", void 0);
AppSelectMegaAssemblyTemplate = __decorate([
    Component
], AppSelectMegaAssemblyTemplate);
export default AppSelectMegaAssemblyTemplate;
