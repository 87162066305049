var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('AppModal', {
    attrs: {
      "color": _vm.EntityType.HARDWARE_LIST,
      "hide-title-section": "",
      "modal-width": "90vw"
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function () {
        return [_c('div', [_c('AppTableActions', {
          staticClass: "mt-2",
          attrs: {
            "color": _vm.EntityType.HARDWARE_LIST,
            "headers": _vm.tableHeaders,
            "items": _vm.tableItems,
            "disable-initial-sort": "",
            "show-search-input": "",
            "title": "Hardware items"
          },
          scopedSlots: _vm._u([{
            key: "_entityIcon",
            fn: function ({
              item
            }) {
              return [!item.isExternal ? _c('c-icon-fa', {
                attrs: {
                  "tooltip": "COSMIC hardware",
                  "icon": _vm.$icons[_vm.EntityType.INVENTORY],
                  "icon-color": "var(--v-inventories-base)"
                }
              }) : _vm._e(), item.isExternal ? _c('IconNotCosmic', {
                attrs: {
                  "color": "black",
                  "tooltip": "Non-COSMIC tracked hardware"
                }
              }) : _vm._e()];
            }
          }])
        })], 1)];
      },
      proxy: true
    }, {
      key: "actions",
      fn: function () {
        return [_c('ButtonCancel', {
          on: {
            "click": _vm.closeDialog
          }
        }, [_vm._v("Close")])];
      },
      proxy: true
    }])
  });
};
var staticRenderFns = [];
export { render, staticRenderFns };