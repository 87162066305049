<template><section><h1>Change Log</h1>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v5.20.2...v5.21.0">5.21.0</a> (2024-12-13)</h1>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Comments:</strong> update activates commenting on a MEGA (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/2a4a1c7d8617819add98a9c58633432f6e234894">2a4a1c7</a>)</li>
<li><strong>Mega allocation:</strong> ability to comment on a mega leaf (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/6e562237e045a1c1c99cac2f6f1c082c70429f13">6e56223</a>)</li>
<li><strong>Mega allocations:</strong> adding toggles (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/a850200a3ae0e8954353425ec7daab75f71510e0">a850200</a>)</li>
<li><strong>Schema:</strong> update to v46.0.0-at-waiver-date-hotfix (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/41270aa8b986ebfe349e947e25522c6f09e8a38d">41270aa</a>)</li>
<li><strong>Schema:</strong> update to v47.0.1 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/92294328796c790f70f01f0ae156e9adc042cb76">9229432</a>)</li>
<li><strong>Schema:</strong> update to v47.0.2 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/75080b6d40bf3fd37dd90712e935d9ed9d57c887">75080b6</a>)</li>
<li><strong>Schema:</strong> update to v47.1.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/834b364ed71514b731f1a0b4f20b51a66c87f892">834b364</a>)</li>
<li><strong>Schema:</strong> update to v47.1.1 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/7f7361386911c558feb4da41c49e713c9bfaf040">7f73613</a>)</li>
<li><strong>Schema:</strong> update to v48.0.1 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/7f05483f1b7711fb62db0e4e122a3b6abe27eabd">7f05483</a>)</li>
</ul>
<h3>Features</h3>
<ul>
<li><strong>Mega allocation:</strong> ability to manipulate a single MEGA and corresponding data (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/b939ea82b556053aef6396ece88894ea89a80dac">b939ea8</a>)</li>
<li><strong>Schema:</strong> update to v48.0.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/4f381dec1e98263e348103050ac81f26d2a26c85">4f381de</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v5.20.1...v5.20.2">5.20.2</a> (2024-12-03)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Mega allocation:</strong> update to add events (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/e4e0296e7341d609d0913bea844ebc1416b3a0a6">e4e0296</a>)</li>
<li><strong>Mega allocation:</strong> update to add events (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/dca3c96d2f94936e9a6aff5ad14eb111905feb7f">dca3c96</a>)</li>
<li><strong>Schema:</strong> update to v46.0.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/e0a2f96dd6d87159d106a2db8705adfbbce5820b">e0a2f96</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v5.20.0...v5.20.1">5.20.1</a> (2024-11-23)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/467b95e1910bfbd6e466928b98dad5ab4f560f99">467b95e</a>)</li>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/185e2fabe13ed787ad1e93b94430a6a1c4fd7f82">185e2fa</a>)</li>
<li><strong>Delivery Matrix:</strong> updated column for item list (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/19d9694528e0c220dc1d785ebbc8688843e3e7bd">19d9694</a>)</li>
<li><strong>Delivery Order:</strong> updating column for item list (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/d5d39d530493a9331917eebfca9d7b1c8ca1da77">d5d39d5</a>)</li>
<li><strong>Schema:</strong> update to v45.1.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/bc8ba35cd25c15cdaa193dc7ea9b4fcf62b18291">bc8ba35</a>)</li>
<li><strong>Schema:</strong> update to v45.1.1 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/3ae30f7cccc8dec85a6cf883579d714085b3b2ef">3ae30f7</a>)</li>
<li><strong>Schema:</strong> update to v45.2.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/b19ba59b197075fd07a27e36b916122e67ab8ec4">b19ba59</a>)</li>
<li><strong>Schema:</strong> update to v45.3.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/84ba192b08f9ef6320a2ee88a473bddfeb6861cf">84ba192</a>)</li>
<li><strong>Schema:</strong> update to v45.3.1 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/26ec04177a7428898317ad56ba8552f2f42fcc12">26ec041</a>)</li>
<li><strong>Schema:</strong> update to v45.3.2 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/6c45f1f513752aca1041afd14f0e1d43a89bf211">6c45f1f</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v5.19.1...v5.20.0">5.20.0</a> (2024-11-18)</h1>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/35558b73f4d8f4712a5ad6062f35d511d8543bcb">35558b7</a>)</li>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/48410f523f03e7f8c71c25ccd8b4819f5ef2ff99">48410f5</a>)</li>
<li><strong>Schema:</strong> update to v45.0.3 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/49a2bef4f44fd28579ee728547c465db8ac32350">49a2bef</a>)</li>
<li><strong>Schema:</strong> update to v45.0.4 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/dba15dc4b91464d6a0612d375cf6e9c4917f5e92">dba15dc</a>)</li>
</ul>
<h3>Features</h3>
<ul>
<li><strong>Vehicles:</strong> add ADMIN ability to create/edit vehicles (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/ebd848987d60af9aff19cdbb3c064ef32c43ab40">ebd8489</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v5.19.0...v5.19.1">5.19.1</a> (2024-11-15)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Item listings:</strong> update layout (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/9102574a6c823f4399173e1916d9bf402de2655a">9102574</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v5.18.0...v5.19.0">5.19.0</a> (2024-11-15)</h1>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Info:</strong> update to display the CEIs (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/d37b727f35c9932bdf6ac8ac683dceca61408ddb">d37b727</a>)</li>
<li><strong>Schema:</strong> update to v45.0.2 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/6fe564207cf1db65135479f7c08eaec1df3c745f">6fe5642</a>)</li>
</ul>
<h3>Features</h3>
<ul>
<li><strong>Item listings:</strong> add ui for viewing/interacting with item listings (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/a7793d6e15ea21adbb90654713dec5fb20194030">a7793d6</a>)</li>
<li><strong>Report:</strong> Delivery matrix report will display all line items (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/a9341f4c294eed5b1a9ba5b55e64851f73e93f06">a9341f4</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v5.17.0...v5.18.0">5.18.0</a> (2024-11-13)</h1>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/bca0cacb412132236332e5bf0427e38c0cf7cde7">bca0cac</a>)</li>
</ul>
<h3>Features</h3>
<ul>
<li><strong>Schema:</strong> update to v45.0.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/31709820441b79caced6c488bc4d5487403305d8">3170982</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v5.16.0...v5.17.0">5.17.0</a> (2024-11-12)</h1>
<h3>Bug Fixes</h3>
<ul>
<li><strong>DO:</strong> update input field sizing (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/4149c71c096297e6c9a970c60537ec40f49aaf96">4149c71</a>)</li>
</ul>
<h3>Features</h3>
<ul>
<li><strong>Delivery matrix:</strong> make a multi edit modal (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/4b1e1d1c06560c071a6c5ba6616d4a6a4b1d1907">4b1e1d1</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v5.15.4...v5.16.0">5.16.0</a> (2024-11-12)</h1>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/561dc00588bacd48b2785848bcc5388ed4f9a919">561dc00</a>)</li>
<li><strong>COSMIC:</strong> Modal dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/e44459e9c5729c1af1249215d3857abb04866b6a">e44459e</a>)</li>
<li><strong>Delivery order:</strong> adding size to a part reference (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/9e1b7bfe71a10d76d9419038df7a751ec41874f6">9e1b7bf</a>)</li>
<li><strong>Line item type:</strong> added this field to the info popup and edit popup (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/570682fd1236634d75351a6b36f849430ca20f23">570682f</a>)</li>
</ul>
<h3>Features</h3>
<ul>
<li><strong>Delivery Orders:</strong> complete line items (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/fd9cc76f1d02a5a879ab42f3f2103dbc65febd57">fd9cc76</a>)</li>
<li><strong>Schema:</strong> update to v44.1.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/b6b33d511fcc90d35f321443ab161fe850838aaa">b6b33d5</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v5.15.3...v5.15.4">5.15.4</a> (2024-11-08)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Auth:</strong> is hard. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/4c1a013ef7745e7c30b71c55888e8b734d3e06e4">4c1a013</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v5.15.2...v5.15.3">5.15.3</a> (2024-11-08)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Auth:</strong> is hard. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/ad3c671c6efceb678cb177bd9864e2ace9aac03c">ad3c671</a>)</li>
<li><strong>TabDeliveryOrderInfo:</strong> add Next ECD field (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/4801c544aa4110d344e895de6923fdf357d71b27">4801c54</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v5.15.1...v5.15.2">5.15.2</a> (2024-11-08)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Auth:</strong> is hard. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/52d56ed7ff6baa007f855365af8ea3e9e188ab1c">52d56ed</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v5.15.0...v5.15.1">5.15.1</a> (2024-11-08)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Auth:</strong> is hard. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/91b1923dbf179a7f023a25e645f0b41272419bbc">91b1923</a>)</li>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/73d1897256cdb30e6034ca870d9d774b2683e081">73d1897</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v5.14.0...v5.15.0">5.15.0</a> (2024-11-07)</h1>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/d0775f1949b0a521923d923ec94fedc7e552beff">d0775f1</a>)</li>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/2dbb8377d934e292da5463b06460a67a6e277868">2dbb837</a>)</li>
<li><strong>Delivery Matrix:</strong> added latest comment (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/21535251d3c08f58a30fa42dfd208682128c4b8d">2153525</a>)</li>
<li><strong>Delivery matrix:</strong> update adds task owner display (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/8fe59cdea8374eb00becedc1b47673de20634c8b">8fe59cd</a>)</li>
<li><strong>Delivery matrix:</strong> users can now update status/ecd (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/055d08614be8eb31b0b42b6cbd120607d229b34f">055d086</a>)</li>
<li><strong>Delivery Order create:</strong> assign featured attachment (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/aebb9558d8d3e95989bd58fb4e6b97e7a9155d03">aebb955</a>)</li>
<li><strong>Delivery Order:</strong> adding delete modal (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/f603dfcf00116e95117ac9dcb1a9b5e598f65948">f603dfc</a>)</li>
</ul>
<h3>Features</h3>
<ul>
<li><strong>Delivery Orders:</strong> info tab layout improvements (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/70e7b663f6e6bf37bd69fc50153300d4aaab23d4">70e7b66</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v5.13.0...v5.14.0">5.14.0</a> (2024-11-06)</h1>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/f443cfb6be512911f1923ed75f14b737f08ea8d8">f443cfb</a>)</li>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/d5e3cdaf524a862ace413f7bdb31ce106a43905d">d5e3cda</a>)</li>
<li><strong>Delivery matrix home:</strong> update comment modal (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/08713a690140a3fd4271cf59c23d0c110d962ceb">08713a6</a>)</li>
<li><strong>Delivery matrix:</strong> adding DO number to table (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/91e4432afdc1d66a0c40a05622cd16320ddf5b37">91e4432</a>)</li>
<li><strong>Delivery Order create:</strong> update fields to match manage page (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/1689b40d45a9f913d0032e42787743c55302a644">1689b40</a>)</li>
<li><strong>Delivery order search:</strong> update attachments icon (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/a198f5a3218e1e747c86503fa7bffc44998e1b29">a198f5a</a>)</li>
<li><strong>Delivery Orders:</strong> added task owner field (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/43253fd05e513dffe7b5698554229bb358ffeca8">43253fd</a>)</li>
<li><strong>DO Information:</strong> remove initiator field and fix spacing on dates (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/edadaa5e40abd358ab8798fb1bc1c31d7a281d93">edadaa5</a>)</li>
<li><strong>DO:</strong> adding action to preview attachment from search view (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/fd532eb2812f0ec117f131f8ed3210e08d0627b0">fd532eb</a>)</li>
<li><strong>Naming:</strong> update Projected to Est. completion (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/ade23f69dfe30f23152e1102093c36b76a114d8c">ade23f6</a>)</li>
<li><strong>Schema:</strong> update to v43.3.2 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/f61ad6d41adca6e879e7ddb31577ab3ad88975a3">f61ad6d</a>)</li>
<li><strong>Schema:</strong> update to v43.3.3 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/84b74d718ebb46e47a89c4b0d8c9361ce4a11fc3">84b74d7</a>)</li>
<li><strong>Schema:</strong> update to v43.3.4 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/8f91e01d143fc6de1f0e689fbfbec89d6d259f15">8f91e01</a>)</li>
</ul>
<h3>Features</h3>
<ul>
<li><strong>Delivery Order creation:</strong> fixed adding line items to a delivery order (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/33b08336a9b09730ba12f64a2d09061bd7c70281">33b0833</a>)</li>
<li><strong>Delivery Orders:</strong> ability to edit line items (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/981fc40ddbb80cf1a6908f96ecc956d2820356e3">981fc40</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v5.12.0...v5.13.0">5.13.0</a> (2024-11-04)</h1>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/7667708b6fe65ef12df4c5ac0210c445db975fac">7667708</a>)</li>
<li><strong>Delivery matrix:</strong> update home view (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/4ff260253158b0c11dc5e7f2553e90f82fbabca8">4ff2602</a>)</li>
<li><strong>DO manage:</strong> update corrects caching issue after adding a line item (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/ec132b19eca13749677eb261318d203f93161101">ec132b1</a>)</li>
<li><strong>DO:</strong> update info tab (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/65c7ff64e34010c5b4170fcf8c65b0e1f4a662d2">65c7ff6</a>)</li>
<li><strong>Schema:</strong> update to v43.3.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/f6de71ded3f5e6ac9c12f7ca3dcd6f28483ffbce">f6de71d</a>)</li>
<li><strong>Schema:</strong> update to v43.3.1 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/667f0faaf0fdc9cde9c11cd6cc7168abcb8f2e3a">667f0fa</a>)</li>
</ul>
<h3>Features</h3>
<ul>
<li><strong>DO line items:</strong> ability to see all details related to a line item (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/f05117b9a8ae7caa993a380395fe660148eb1da9">f05117b</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v5.11.0...v5.12.0">5.12.0</a> (2024-11-01)</h1>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Approvals:</strong> adding a way to manage the approvals of a DO_LINE_ITEM (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/c197f2cc14c297d4a234b9b3329f68df409625f6">c197f2c</a>)</li>
<li><strong>Delivery matrix:</strong> update query path (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/1f88212af6a70e018eca68c71b6fbfac9495e2d7">1f88212</a>)</li>
<li><strong>Part ref:</strong> made side not a required form field (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/983645260df91d41b3badfd9f8ef9762f3663b00">9836452</a>)</li>
<li><strong>Schema:</strong> update to v43.2.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/855f7b104f231cbf96dabe6d62a934788476e459">855f7b1</a>)</li>
<li><strong>Schema:</strong> update to v43.2.1 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/088170b5469e6c07d40dd785e804dc5169b29dc1">088170b</a>)</li>
<li><strong>Schema:</strong> update to v43.2.2 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/b8729e1f5d62501f9ebc35fd8ce38799bea42389">b8729e1</a>)</li>
</ul>
<h3>Features</h3>
<ul>
<li><strong>Delivery Order create:</strong> ability to add an attachment (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/f2b5c88157f12383576e11f61a927f6f02ca1d07">f2b5c88</a>)</li>
<li><strong>Delivery Order manage:</strong> ability to feature an attachment (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/a84003e2a6c1181e0aff4bcfbcd2669c894e9a39">a84003e</a>)</li>
<li><strong>Delivery order:</strong> finalized search screen (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/e06148b506834d07b84d7c63c7c13e90b2de2fbf">e06148b</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v5.10.0...v5.11.0">5.11.0</a> (2024-10-30)</h1>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Delivery order:</strong> update search (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/535dd60b9ac384e5d705559670740ccde8099c5e">535dd60</a>)</li>
<li><strong>Side nav:</strong> update icon to be the correct color (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/e4074b910590f83112239d8e24f9d9de3ef4b613">e4074b9</a>)</li>
</ul>
<h3>Features</h3>
<ul>
<li><strong>Cache:</strong> implementing magic (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/d3da1b4834e460561400ba80151fa92b61c7e3b5">d3da1b4</a>)</li>
<li><strong>Delivery Orders manage:</strong> add and delete line items (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/b600cbd0688f016e073b7c7e38f6f7750733bced">b600cbd</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v5.9.1...v5.10.0">5.10.0</a> (2024-10-30)</h1>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/f7b43a800c8b6706301158922de855fd69a46768">f7b43a8</a>)</li>
<li><strong>Delivery matrix:</strong> update query for matrix home (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/3786a24233034472e24da7498aeab02b2b2c7d41">3786a24</a>)</li>
<li><strong>Delivery order search:</strong> ability to view closed DOs (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/b7df11520e71fd4027797d60ab27b5215845eb22">b7df115</a>)</li>
<li><strong>Delivery order search:</strong> update column layout (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/0f5641e8c078ae31a94fd84a8797310474b79c5a">0f5641e</a>)</li>
<li><strong>Delivery Orders:</strong> add comment feature to search view (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/fe6ecaaf0383c36774176869d6db8382b4dd62f8">fe6ecaa</a>)</li>
<li><strong>Delivery orders:</strong> update corrects search view (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/fc2760b587c31336e21c73a7a07d95e90c16ed40">fc2760b</a>)</li>
<li><strong>LI create:</strong> DeliveryOrderLineItemStatus implementation (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/d03c453c5c08b4c2124152a8c50a8e44f84f24ee">d03c453</a>)</li>
<li><strong>Schema:</strong> update to v43.1.1 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/85d2845135c87c6bf4450b2351869e2081a7720b">85d2845</a>)</li>
<li><strong>Schema:</strong> update to v43.1.2 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/0a8ca6444909785d9fdd8652a5eb07cdfd8f62ac">0a8ca64</a>)</li>
<li><strong>Schema:</strong> update to v43.1.2 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/df484c387c30e818285286e91f7063b24b8ebd7b">df484c3</a>)</li>
</ul>
<h3>Features</h3>
<ul>
<li><strong>Delivery Order create:</strong> ability to add line items (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/63c809775a90c1a58490363afe98142660285be5">63c8097</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v5.9.0...v5.9.1">5.9.1</a> (2024-10-28)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Delivery order:</strong> Update the creation screen. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/49199f210e9f4fb5a57d5146ca37f8d624883175">49199f2</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v5.8.1...v5.9.0">5.9.0</a> (2024-10-25)</h1>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Comments:</strong> update control to pass the correct id (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/bc65b02b7e4cfcf53046cfac6a46f1c7a81328bb">bc65b02</a>)</li>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/f739fbedfbedaf486f0356749390186685e5b8b3">f739fbe</a>)</li>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/fa858291d326c6d86396597c07d93de89c885502">fa85829</a>)</li>
<li><strong>COSMIC:</strong> Http dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/bf5838e3583bf81cad161885e35a177b2c33068d">bf5838e</a>)</li>
</ul>
<h3>Features</h3>
<ul>
<li><strong>Delivery order:</strong> creation (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/5ae43a096d9b6d028ea1997ff370772e56015c44">5ae43a0</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v5.8.0...v5.8.1">5.8.1</a> (2024-10-23)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Schema:</strong> update to v43.1.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/f420878aa89d83260d294b8eba24a74ce36d2801">f420878</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v5.7.5...v5.8.0">5.8.0</a> (2024-10-22)</h1>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Schema:</strong> update to v43.0.2 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/ae4a4815a98004b6c41fc1520645479502c42367">ae4a481</a>)</li>
</ul>
<h3>Features</h3>
<ul>
<li><strong>COSMIC:</strong> update Delivery Matrix (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/9f43e41e9a1e75388174695d2a01896b1a099fa9">9f43e41</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v5.7.4...v5.7.5">5.7.5</a> (2024-10-09)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>UI:</strong> add new grid css (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/aa4ce62f8ef1db9d17c53103f54ae0bde0d0963a">aa4ce62</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v5.7.3...v5.7.4">5.7.4</a> (2024-10-09)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/4d4faaf160b38183cdfee07682f401623959ee4f">4d4faaf</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v5.7.2...v5.7.3">5.7.3</a> (2024-10-08)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/6024d1b5d455a06e1558e556801a4703b5a518ec">6024d1b</a>)</li>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/a31730ffcc926d030c609f8fadfc7a3d97c95228">a31730f</a>)</li>
<li><strong>Schema:</strong> update to v41.6.1 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/26683f281bfb9c8972823c4c34d4dbe8666ec6f4">26683f2</a>)</li>
<li><strong>Schema:</strong> update to v41.6.2 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/ec7fba5989a8eed93292a03d79b2558078fff6b9">ec7fba5</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v5.7.1...v5.7.2">5.7.2</a> (2024-10-03)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/204f114ff43681bacfd567ccec09a99fe8e35f11">204f114</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v5.7.0...v5.7.1">5.7.1</a> (2024-10-01)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Schema:</strong> update to v41.5.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/3698fe0bfdec27ab3220cf50100217841f74eae2">3698fe0</a>)</li>
<li><strong>Schema:</strong> update to v41.6.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/8f77ed68ab946b71dc76e7ec3f920f792e617031">8f77ed6</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v5.6.1...v5.7.0">5.7.0</a> (2024-09-25)</h1>
<h3>Features</h3>
<ul>
<li><strong>Delivery order:</strong> initialized a new COSMIC entity called Delivery ordes (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/8b480175d6e9c0bc9cf7879e89cd0fdd00e7e470">8b48017</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v5.6.0...v5.6.1">5.6.1</a> (2024-09-25)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/050ec8fb11255f307e32a1fabd7d59869134df30">050ec8f</a>)</li>
<li><strong>Schema:</strong> update to v41.4.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/0941cd551ebd0fd15f16b1d3cad0bc91054d3f0c">0941cd5</a>)</li>
<li><strong>Schema:</strong> update to v41.4.1 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/e75ff0e06b0aaa9ebec687010b2228ce3f50d5e7">e75ff0e</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v5.5.2...v5.6.0">5.6.0</a> (2024-09-24)</h1>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/46c915873869d94c645d081b4365a215b992d9b4">46c9158</a>)</li>
<li><strong>Schema:</strong> update to v41.3.1 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/714b30705aa00eee0462ee00ba45f3eeaf90eb79">714b307</a>)</li>
<li><strong>Schema:</strong> update to v41.3.2 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/89ac3f3cb9fdc9a4ddfe3753f1c8e606b7228688">89ac3f3</a>)</li>
</ul>
<h3>Features</h3>
<ul>
<li><strong>Docker:</strong> update root node location (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/f728f5583c23bd993b89c91e1bd79611cfe428da">f728f55</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v5.5.1...v5.5.2">5.5.2</a> (2024-09-23)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/f505156462b296bf3e82954498c98ecca6d76ee2">f505156</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v5.5.0...v5.5.1">5.5.1</a> (2024-09-23)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/251cb5ce86ec2013194a7703b341784f430972ef">251cb5c</a>)</li>
<li><strong>Schema:</strong> update to v41.1.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/a14dafeca6ca7cd15d37e55619d3e4132e3c8209">a14dafe</a>)</li>
<li><strong>Schema:</strong> update to v41.2.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/cd127612a76c5af79c5013784d238439a329e3d8">cd12761</a>)</li>
<li><strong>Schema:</strong> update to v41.2.1 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/eefdc318fe481cf3be7d4ce7e32156dee70af475">eefdc31</a>)</li>
<li><strong>Schema:</strong> update to v41.2.2 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/3373968bd489d6d252888a53c13bdd5c6ab0a667">3373968</a>)</li>
<li><strong>Schema:</strong> update to v41.3.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/42aefb64382a10cfb2a7e78d5d35d6719d30af77">42aefb6</a>)</li>
<li><strong>UI:</strong> improved date picker popover (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/f11c0e7203d8cf64b206c4418798b542d3d5b88f">f11c0e7</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v5.4.2...v5.5.0">5.5.0</a> (2024-09-18)</h1>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/d94c19eda4b2bb96e1397d715fa91ccc1eaed408">d94c19e</a>)</li>
<li><strong>Schema:</strong> update to v41.0.2 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/c46f2b189020cb36aad17e74343851c95e93dae3">c46f2b1</a>)</li>
</ul>
<h3>Features</h3>
<ul>
<li><strong>Schema:</strong> update to v40.0.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/53fac86fc1c8cd178e0817313353b01870f7e109">53fac86</a>)</li>
<li><strong>Schema:</strong> update to v41.0.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/14dd5bf39a23b34712bb93ab3b2efe6439589477">14dd5bf</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v5.4.1...v5.4.2">5.4.2</a> (2024-09-11)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/12a4daaaf82be129b898f0c24c86d174e05bcba7">12a4daa</a>)</li>
<li><strong>Schema:</strong> update to v39.0.1 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/af241f22f2077e93b5105b22618b8a7ab4eca5ec">af241f2</a>)</li>
<li><strong>Schema:</strong> update to v39.0.2 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/3c701dabff64bc6f51f9779d4af533c8e002d5ee">3c701da</a>)</li>
<li><strong>Schema:</strong> update to v39.1.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/b0f7510c196c279b90cf4eea7c93489befdd38cb">b0f7510</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v5.4.0...v5.4.1">5.4.1</a> (2024-09-05)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/fc611c49e0a0c2e0a205f6c9ceca4de8dff33b69">fc611c4</a>)</li>
<li><strong>UI:</strong> streamlined checkbox controls (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/5a90d5daf2a2aee30911c9a42c5c712f0a091bd4">5a90d5d</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v5.3.1...v5.4.0">5.4.0</a> (2024-08-30)</h1>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/2be37fb2df1e8492f1e0300a44f538bcf3f715f9">2be37fb</a>)</li>
<li><strong>Schema:</strong> update fixes breaks from v39.0.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/bfa009c596ceab64d3fec5d82b4f3bf71999e739">bfa009c</a>)</li>
<li><strong>UI:</strong> streamlined radio button controls (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/ed75e258b1aee74a14e1515e20ce8512f0a61cc1">ed75e25</a>)</li>
</ul>
<h3>Features</h3>
<ul>
<li><strong>Schema:</strong> update to v39.0.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/9c18bbfffa6caab69030b1158fdeac345a9f108b">9c18bbf</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v5.3.0...v5.3.1">5.3.1</a> (2024-08-27)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/aa53c52e6a77baeff675ce4b827def0cf2375ba7">aa53c52</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v5.2.1...v5.3.0">5.3.0</a> (2024-08-21)</h1>
<h3>Features</h3>
<ul>
<li><strong>Modal:</strong> implementing new webcomponent backed modal (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/f93ff140a043c670c55f98f3741ac1e050c25365">f93ff14</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v5.2.0...v5.2.1">5.2.1</a> (2024-08-15)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Schema:</strong> update to v38.4.3 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/296cbf29ce9f03954cae3a420699aad512006994">296cbf2</a>)</li>
<li><strong>Schema:</strong> update to v38.5.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/1118561429d9ce33038b297fe3fc90efb1f7f0b5">1118561</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v5.1.1...v5.2.0">5.2.0</a> (2024-08-13)</h1>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Schema:</strong> update to v38.2.2 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/c897bc9f6de92f14cc39b45ab3989b5b9b2d8035">c897bc9</a>)</li>
<li><strong>Schema:</strong> update to v38.4.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/40b796f62e5c1a0bf7d5997436cebb95c75b6ed0">40b796f</a>)</li>
<li><strong>Schema:</strong> update to v38.4.1 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/cfa5503ff68da4fd0dd0b5459a7afb796b31fee3">cfa5503</a>)</li>
<li><strong>Schema:</strong> update to v38.4.2 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/f4d526ab217f9b8b614c186c417532a40fc3692e">f4d526a</a>)</li>
<li><strong>Standardization:</strong> implement icons in top nav as well as other wonderful augmentations. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/3b2182a9d625c805e1d47791537afaed2ddf58a2">3b2182a</a>)</li>
</ul>
<h3>Features</h3>
<ul>
<li><strong>Admins view:</strong> update adds more application level details to a new admin tab (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/bee74958b59825d13ca33b1e3d80124f92f2056c">bee7495</a>)</li>
<li><strong>UI:</strong> implemented new button web component (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/1a7196a712907bb0f72bee17d68c5a380bb5620d">1a7196a</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v5.1.0...v5.1.1">5.1.1</a> (2024-07-31)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/e3950d642d862e1e7d2677b69fda8b1094d24136">e3950d6</a>)</li>
<li><strong>Schema:</strong> update to v38.0.1 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/7d73aea7903a688c85493cdeb11dbae1a26bacc1">7d73aea</a>)</li>
<li><strong>Schema:</strong> update to v38.1.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/8d8f4eb8e39d9ea5cad6ea1cd139b9752f304460">8d8f4eb</a>)</li>
<li><strong>Schema:</strong> update to v38.2.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/2c9113b557d3c8dcfb3d34eb19ad2b47706c2bf2">2c9113b</a>)</li>
<li><strong>Schema:</strong> update to v38.2.1 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/1388886e9aed69a2642c073a865985c759c24759">1388886</a>)</li>
<li><strong>Standardization:</strong> implement icons in top nav as well as other wonderful augmentations. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/dec37cf07ee1b09f25797af4a5d347b2bc892798">dec37cf</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v5.0.0...v5.1.0">5.1.0</a> (2024-07-26)</h1>
<h3>Bug Fixes</h3>
<ul>
<li><strong>UI:</strong> restored more familiar hardware icons and updated the edit icon (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/5860978951cb676262e051d258cf8893d3def383">5860978</a>)</li>
</ul>
<h3>Features</h3>
<ul>
<li><strong>Authorization:</strong> overhauled the order of operations for authorization and authentication (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/b328c63b74452600ceaf7a412946fc320094f35a">b328c63</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v4.0.2...v5.0.0">5.0.0</a> (2024-07-22)</h1>
<h3>Features</h3>
<ul>
<li><strong>Schema:</strong> update to v38.0.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/12dc7406f23327e845ca40ea1a7e8142a8783f83">12dc740</a>)</li>
</ul>
<h3>BREAKING CHANGES</h3>
<ul>
<li><strong>Schema:</strong> date/time updates system wide</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v4.0.1...v4.0.2">4.0.2</a> (2024-07-18)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Build:</strong> correct build error preventing deployment (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/661e8516591a46a758256b23eab2d80a9fe7e901">661e851</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v4.0.0...v4.0.1">4.0.1</a> (2024-07-18)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/de959ab3ed3b58658c97e9a98c69ae4852af1282">de959ab</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v3.0.2...v4.0.0">4.0.0</a> (2024-07-11)</h1>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Asset pathing:</strong> update to point back to the proxy (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/371f86cc49645fc91dbeaddfb2db9458aec92da7">371f86c</a>)</li>
<li><strong>COSMIC:</strong> Dependency update for deployment. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/1a79b0db843675f80cfced5efd152efc4e8b5bb8">1a79b0d</a>)</li>
<li><strong>Schema:</strong> update to v36.0.2 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/a2e366cf6b22284c5cf18891186a1dfcd1bcc186">a2e366c</a>)</li>
<li><strong>Schema:</strong> update to v37.0.1 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/ec136436e06fb948808e565bb05cc232dfda0615">ec13643</a>)</li>
</ul>
<h3>Features</h3>
<ul>
<li><strong>Schema:</strong> update to v36.0.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/2ca23d9a79cd38cb83298b9fdae7aa38e599760d">2ca23d9</a>)</li>
<li><strong>Tables:</strong> change to table styles (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/6120080cebd7a2c94bc192f8d2af7682ec0f248f">6120080</a>)</li>
</ul>
<h3>BREAKING CHANGES</h3>
<ul>
<li><strong>Schema:</strong> column renames and date/time related updates</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v3.0.1...v3.0.2">3.0.2</a> (2024-06-25)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Asset path:</strong> Update GCP bucket url. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/82cff903d3453ad0f862c9de01248c16cc9b3c1e">82cff90</a>)</li>
<li><strong>Footer:</strong> updated knowledgebase link (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/85bd6faa279ac837e8e7d9bf67721a2a710c77e3">85bd6fa</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v3.0.0...v3.0.1">3.0.1</a> (2024-06-20)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Schema:</strong> update to v35.1.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/bf18c6ae607f75f5ed9aba432e947070d83207a9">bf18c6a</a>)</li>
<li><strong>Schema:</strong> update to v35.1.1 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/47ddf65482ba04e0dd5783efc90e2d66d2ea3090">47ddf65</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v2.1.4...v3.0.0">3.0.0</a> (2024-06-18)</h1>
<h3>Features</h3>
<ul>
<li><strong>Schema:</strong> update to v35.0.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/7a19a6043d8a5604f5664a395c76b86e3d194aaa">7a19a60</a>)</li>
</ul>
<h3>BREAKING CHANGES</h3>
<ul>
<li><strong>Schema:</strong> createdDate/updatedDate rename</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v2.1.3...v2.1.4">2.1.4</a> (2024-06-18)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Schema:</strong> update to v34.1.1 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/d708321f97d43c274ac50154a34fc1fe5798ef4f">d708321</a>)</li>
<li><strong>Schema:</strong> update to v34.1.2 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/86283d69ce3894414cd114c25b2e4a017752af22">86283d6</a>)</li>
<li><strong>Schema:</strong> update to v34.1.3 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/b6791545af21c372231443185a1fc6d954e2f1ee">b679154</a>)</li>
<li><strong>Static assets:</strong> update pathing per environment (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/1a6666a2f8fe98dfcf741d418dda25e62ff511ab">1a6666a</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v2.1.2...v2.1.3">2.1.3</a> (2024-06-17)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/087b58decf9d0f01ba8e6dc45476d37156434743">087b58d</a>)</li>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/17af931cdc30d31fe24bafcbb1b4fa40480f00d2">17af931</a>)</li>
<li><strong>Schema:</strong> update to v34.1.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/ae0031e8cf583aeccb5f0f3cd5594368296ef1f7">ae0031e</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v2.1.1...v2.1.2">2.1.2</a> (2024-06-14)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Schema:</strong> update to v34.0.2 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/c7e4bd217b2de551eda774b37cdd2bb11069665e">c7e4bd2</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v2.1.0...v2.1.1">2.1.1</a> (2024-06-12)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Schema:</strong> update to v34.0.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/09535cf6b2790065383b911f2f4e6ae7bb4a7d36">09535cf</a>)</li>
<li><strong>Schema:</strong> update to v34.0.1 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/4bd19f07ab3ea5f662e2e62c6e30a9c395baef3b">4bd19f0</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v2.0.1...v2.1.0">2.1.0</a> (2024-06-11)</h1>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Schema:</strong> update to v32.1.1 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/af7f3df39793a6f9f8c01faa137d61715ff4474f">af7f3df</a>)</li>
<li><strong>Schema:</strong> update to v33.0.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/328ad049a19e250bd66889c7e5e549dc762281da">328ad04</a>)</li>
</ul>
<h3>Features</h3>
<ul>
<li><strong>Dates:</strong> attempt at fixing datetimes (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/c8fcb8aceb457d4004d59c7348f142a052a5b8b2">c8fcb8a</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v2.0.0...v2.0.1">2.0.1</a> (2024-06-10)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Misc:</strong> updates for image pathing (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/c1d01e47df5fe323ff613c7d41df91b74e357d2d">c1d01e4</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v1.0.13...v2.0.0">2.0.0</a> (2024-06-10)</h1>
<h3>Features</h3>
<ul>
<li><strong>Static resource mgmt:</strong> change implements current best practices for serving static content (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/8b986f862ef93c40d3f6436c998dba9634fb11e0">8b986f8</a>)</li>
</ul>
<h3>BREAKING CHANGES</h3>
<ul>
<li><strong>Static resource mgmt:</strong> images could need to be remapped</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v1.0.12...v1.0.13">1.0.13</a> (2024-06-07)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Charts:</strong> adding them (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/6f442d75bee7f50b43b4fed5a32e7a4011a67554">6f442d7</a>)</li>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/d5476fe47207718166d65d5d4abb95d26d868271">d5476fe</a>)</li>
<li><strong>Schema:</strong> update to v31.2.1 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/1f795dc3f7156f76fb39e6dde9e90077e3d91bbd">1f795dc</a>)</li>
<li><strong>Schema:</strong> update to v32.0.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/b96450e7318956da8189d393da02bbcbf37d82db">b96450e</a>)</li>
<li><strong>Schema:</strong> update to v32.1.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/9cec7772c3e3f269c09e8c169699c2f3354c91ed">9cec777</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v1.0.11...v1.0.12">1.0.12</a> (2024-06-04)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Delivery matrix search:</strong> updated to default to IN_PROCESS if no status is set (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/6bc10d57ab4d0a8296fa937bd48113d5b889b061">6bc10d5</a>)</li>
<li><strong>Delivery matrix:</strong> updated search for better ui consistency (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/944c3a727a925d2a1c0f5b76bafb1beacb2c8c73">944c3a7</a>)</li>
<li><strong>Schema:</strong> update to v31.2.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/af875ccec7e33e1e23e58d9337b43bde50b7f61b">af875cc</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v1.0.10...v1.0.11">1.0.11</a> (2024-06-03)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Delivery matrix:</strong> updated how the hardware tracking is saved (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/47b7b4eaa8c122ccf776a0fbe111c77fcc65834a">47b7b4e</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v1.0.9...v1.0.10">1.0.10</a> (2024-06-03)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Delivery matrix changelog:</strong> corrected some data related issues (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/f7bda51fc0cee2c2baf11e732c2b3b6763aec64b">f7bda51</a>)</li>
<li><strong>Delivery matrix:</strong> corrected issue when completing an item (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/0fd518d72fd3cb7c1d971e029c552c4059073dc9">0fd518d</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v1.0.8...v1.0.9">1.0.9</a> (2024-06-03)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Delivery matrix:</strong> update ux (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/839cbe298d029ec65fdc2a8e52635ffc68e279ce">839cbe2</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v1.0.7...v1.0.8">1.0.8</a> (2024-06-03)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>General:</strong> upadates to wording and reenforcement of actions via iconography (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/13b5cb343b52020955142633fffeb71bddbb74d4">13b5cb3</a>)</li>
<li><strong>Logos:</strong> adding them (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/0894acc14f375cd3977224cb587e69feaae4a17b">0894acc</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v1.0.6...v1.0.7">1.0.7</a> (2024-06-03)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Schema:</strong> update to v31.1.1 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/ac1838b561195ac17f0873f9cc31a98eca08ce0b">ac1838b</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v1.0.5...v1.0.6">1.0.6</a> (2024-05-31)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Schema:</strong> update to v31.1.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/23e343d620c778b6199721b4cb3b7fe09ef599a8">23e343d</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v1.0.4...v1.0.5">1.0.5</a> (2024-05-31)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Chicklet:</strong> implement new popups for creating critical HL and ROOT MEGA ATs (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/b235a9c469801c0fb0dc2892e38b24f51751a25a">b235a9c</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v1.0.3...v1.0.4">1.0.4</a> (2024-05-31)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Permissions:</strong> adidng in the all important LOGISTICS_ADMIN permission (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/ef345bf72efd334c4b006049daf331020ce80eaf">ef345bf</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v1.0.2...v1.0.3">1.0.3</a> (2024-05-31)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Chicklet:</strong> added a create HL modal and implement HL based routes/views (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/822597826bdad9bd17eba6bcb9a19450f4db88fd">8225978</a>)</li>
<li><strong>Chicklet:</strong> fun.tional (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/a48e9b21bd281b74def108cbb233274798edfb52">a48e9b2</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v1.0.1...v1.0.2">1.0.2</a> (2024-05-30)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Critical inventory:</strong> initial commit of base feature set (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/5040476a87298612d5c207d2266d3c4ec8375ead">5040476</a>)</li>
<li><strong>Delivery matrix:</strong> initial commit moving over functionality from COSMIC Docs (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/fab4bf40dc3e23f22c6ca8dd0cd58bff8b172ae6">fab4bf4</a>)</li>
<li><strong>Index:</strong> loading up the correct variables file (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/bb0cbfc4fcb3c97d2fd29f5451e14628a0da231d">bb0cbfc</a>)</li>
<li><strong>Schema:</strong> update to v31.0.2 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/ecca39f9873bf7184133cae3ca1d3674c9073128">ecca39f</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v1.0.0...v1.0.1">1.0.1</a> (2024-05-21)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Initial commit:</strong> new app is launched (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/adf546467ba6038a7f48f111ee69f3bb232003f6">adf5464</a>)</li>
<li><strong>Logistics:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/f0f51da2c35c4923997db2f455718c33c890cb89">f0f51da</a>)</li>
</ul>
<h1>1.0.0 (2024-05-21)</h1>
<h3>Bug Fixes</h3>
<ul>
<li>gitlab CI filenam (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/1f2193aa125f0c85990b80055565bcb2fb1a9214">1f2193a</a>)</li>
<li>gitlab CI filename (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/b305e9e1165a94cd66870a5e78bbe7f2a6754618">b305e9e</a>)</li>
</ul>
</section></template><script>export default {components:{}}</script>