var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('AppFormWrapper', {
    attrs: {
      "color": _vm.EntityType.ITEM_INSTANCE,
      "size": _vm.size,
      "validate-on-load": ""
    }
  }, [_c('v-layout', {
    attrs: {
      "row": "",
      "wrap": ""
    }
  }, [_c('v-flex', {
    attrs: {
      "xs12": ""
    }
  }, [_c('v-slide-y-transition', {
    attrs: {
      "hide-on-leave": ""
    }
  }, [_c('SearchInventory', _vm._b({
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.shouldShowSearchInventory && !_vm.manualInputMode,
      expression: "shouldShowSearchInventory && !manualInputMode"
    }],
    attrs: {
      "disable-search": _vm.disableSearch,
      "existing-values": _vm.existingValues,
      "force-hide-results": "",
      "keep-search-value-after-selection": ""
    },
    on: {
      "results": _vm.emitAndCacheResults,
      "searchButtonClicked": _vm.emitSearchButtonClicked,
      "termSearched": _vm.onTermSearched
    }
  }, 'SearchInventory', _vm.$props, false))], 1), _c('v-slide-y-transition', {
    attrs: {
      "hide-on-leave": ""
    }
  }, [_c('v-layout', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.manualInputMode,
      expression: "manualInputMode"
    }],
    attrs: {
      "row": "",
      "wrap": ""
    }
  }, [_c('v-flex', {
    attrs: {
      "xs12": ""
    }
  }, [_c('v-layout', {
    attrs: {
      "row": "",
      "wrap": ""
    }
  }, [_c('v-flex', {
    attrs: {
      "xs12": ""
    }
  }, [_c('AppHeader', {
    attrs: {
      "size": "mini"
    }
  }, [_vm._v("Non-" + _vm._s(_vm.currentSystemName) + " Hardware")])], 1), _c('v-flex', {
    attrs: {
      "xs12": ""
    }
  }, [_c('v-tooltip', {
    attrs: {
      "bottom": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function ({
        on
      }) {
        return [_c('AppCheckbox', _vm._g({
          attrs: {
            "label": "Is this a Tool?"
          },
          model: {
            value: _vm.manualInputForm.externalIsTool,
            callback: function ($$v) {
              _vm.$set(_vm.manualInputForm, "externalIsTool", $$v);
            },
            expression: "manualInputForm.externalIsTool"
          }
        }, on))];
      }
    }])
  }, [_c('span', [_vm._v("This changes form requirements.")])])], 1), _c('v-flex', {
    attrs: {
      "xs6": "",
      "pr-2": ""
    }
  }, [_c('c-text', {
    attrs: {
      "label": "Description",
      "c-value": _vm.manualInputForm.externalDescription,
      "required": _vm.manualInputForm.externalIsTool
    },
    on: {
      "c-input": function ($event) {
        _vm.manualInputForm.externalDescription = $event.detail;
      }
    }
  })], 1), _c('v-flex', {
    attrs: {
      "xs4": "",
      "pr-2": ""
    }
  }, [_c('c-text', {
    attrs: {
      "label": "Drawing number",
      "c-value": _vm.manualInputForm.externalDrawingNumber,
      "required": !_vm.manualInputForm.externalIsTool
    },
    on: {
      "c-input": function ($event) {
        _vm.manualInputForm.externalDrawingNumber = $event.detail;
      }
    }
  })], 1), _c('v-flex', {
    attrs: {
      "xs2": ""
    }
  }, [_c('c-text', {
    attrs: {
      "label": "AsBuilt",
      "c-value": _vm.manualInputForm.externalAsBuiltNumber
    },
    on: {
      "c-input": function ($event) {
        _vm.manualInputForm.externalAsBuiltNumber = $event.detail;
      }
    }
  })], 1)], 1)], 1), _c('v-flex', {
    attrs: {
      "xs12": ""
    }
  }, [_c('v-layout', {
    attrs: {
      "row": "",
      "wrap": ""
    }
  }, [_c('v-flex', {
    attrs: {
      "xs2": "",
      "pr-2": ""
    }
  }, [_c('c-text', {
    attrs: {
      "label": "Serial",
      "c-value": _vm.manualInputForm.externalSerialNumber
    },
    on: {
      "c-input": function ($event) {
        _vm.manualInputForm.externalSerialNumber = $event.detail;
      }
    }
  })], 1), _c('v-flex', {
    attrs: {
      "xs2": "",
      "pr-2": ""
    }
  }, [_c('c-text', {
    attrs: {
      "label": "Lot",
      "c-value": _vm.manualInputForm.externalLotNumber
    },
    on: {
      "c-input": function ($event) {
        _vm.manualInputForm.externalLotNumber = $event.detail;
      }
    }
  })], 1), _c('v-flex', {
    attrs: {
      "xs2": "",
      "pr-2": ""
    }
  }, [_c('c-select', {
    attrs: {
      "chips": false,
      "label": "Class",
      "sort": ""
    },
    domProps: {
      "display": _vm.InventoryClassDisplay,
      "enum": _vm.InventoryClassWithoutMultiple,
      "cValue": _vm.manualInputForm.externalClass
    },
    on: {
      "c-input": function ($event) {
        _vm.manualInputForm.externalClass = $event.detail;
      }
    }
  })], 1), _c('v-flex', {
    attrs: {
      "xs6": ""
    }
  }, [_c('v-layout', {
    attrs: {
      "row": "",
      "wrap": ""
    }
  }, [_c('v-flex', {
    attrs: {
      "xs4": "",
      "pr-2": ""
    }
  }, [_c('c-text', {
    attrs: {
      "color": _vm.EntityType.ACTIVITY,
      "label": "Calibration number",
      "c-value": _vm.manualInputForm.externalCalibrationNumber
    },
    on: {
      "c-input": function ($event) {
        _vm.manualInputForm.externalCalibrationNumber = $event.detail;
      }
    }
  })], 1)], 1)], 1), _c('v-flex', {
    attrs: {
      "xs6": ""
    }
  }, [_c('v-layout', {
    attrs: {
      "row": "",
      "wrap": ""
    }
  }, [_c('v-flex', {
    attrs: {
      "xs4": "",
      "pr-2": ""
    }
  }, [_c('c-text', {
    attrs: {
      "color": _vm.EntityType.ACTIVITY,
      "label": "CEI number",
      "c-value": _vm.manualInputForm.externalContractEndingItemNumber
    },
    on: {
      "c-input": function ($event) {
        _vm.manualInputForm.externalContractEndingItemNumber = $event.detail;
      }
    }
  })], 1)], 1)], 1), _c('v-flex', {
    attrs: {
      "xs12": "",
      "my-2": ""
    }
  }, [_c('v-layout', {
    attrs: {
      "row": "",
      "wrap": ""
    }
  }, [_c('v-flex', {
    attrs: {
      "xs6": ""
    }
  }, [_c('AppDate', {
    attrs: {
      "label": "Birth"
    },
    model: {
      value: _vm.manualInputForm.externalBirthDate,
      callback: function ($$v) {
        _vm.$set(_vm.manualInputForm, "externalBirthDate", $$v);
      },
      expression: "manualInputForm.externalBirthDate"
    }
  })], 1), _c('v-flex', {
    attrs: {
      "xs6": ""
    }
  }, [_c('AppDate', {
    attrs: {
      "color": _vm.EntityType.ACTIVITY,
      "label": "Calibration"
    },
    model: {
      value: _vm.manualInputForm.externalCalibrationDate,
      callback: function ($$v) {
        _vm.$set(_vm.manualInputForm, "externalCalibrationDate", $$v);
      },
      expression: "manualInputForm.externalCalibrationDate"
    }
  })], 1), _c('v-flex', {
    attrs: {
      "xs6": ""
    }
  }, [_c('AppDate', {
    attrs: {
      "label": "Usage life expiry"
    },
    model: {
      value: _vm.manualInputForm.externalUsageLifeDate,
      callback: function ($$v) {
        _vm.$set(_vm.manualInputForm, "externalUsageLifeDate", $$v);
      },
      expression: "manualInputForm.externalUsageLifeDate"
    }
  })], 1)], 1)], 1), _c('v-flex', {
    attrs: {
      "xs1": ""
    }
  }, [_c('v-tooltip', {
    attrs: {
      "bottom": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function ({
        on
      }) {
        return [_c('div', _vm._g({}, on), [_c('v-slide-x-transition', {
          attrs: {
            "hide-on-leave": ""
          }
        }, [Boolean(_vm.manualInputForm.externalSerialNumber) ? _c('AppBlockquote', {
          attrs: {
            "title": "Qty"
          }
        }, [_c('span', {
          staticClass: "monospace_font"
        }, [_vm._v("1")])]) : _vm._e()], 1), _c('v-slide-x-transition', {
          attrs: {
            "hide-on-leave": ""
          }
        }, [!_vm.manualInputForm.externalSerialNumber ? _c('AppNumber', {
          staticClass: "quantity-input",
          attrs: {
            "label": "Qty",
            "required": ""
          },
          model: {
            value: _vm.manualInputForm.quantity,
            callback: function ($$v) {
              _vm.$set(_vm.manualInputForm, "quantity", $$v);
            },
            expression: "manualInputForm.quantity"
          }
        }) : _vm._e()], 1)], 1)];
      }
    }])
  }, [Boolean(_vm.manualInputForm.externalSerialNumber) ? _c('span', [_vm._v("Quantity locked at 1 due to serialization.")]) : _c('span', [_vm._v("Must be a non-zero number.")])])], 1)], 1)], 1), _c('v-flex', {
    attrs: {
      "xs12": ""
    }
  }, [_c('v-layout', {
    attrs: {
      "row": "",
      "wrap": ""
    }
  }, [_c('v-flex', {
    attrs: {
      "xs6": ""
    }
  }, [_c('c-text', {
    attrs: {
      "label": "Location",
      "c-value": _vm.manualInputForm.externalLocation,
      "color": _vm.EntityType.LOCATION
    },
    on: {
      "c-input": function ($event) {
        _vm.manualInputForm.externalLocation = $event.detail;
      }
    }
  })], 1)], 1)], 1), _c('v-flex', {
    attrs: {
      "xs12": ""
    }
  }, [_c('ButtonCreate', {
    attrs: {
      "disabled": !_vm.manualInputFormIsValid
    },
    on: {
      "click": _vm.onClickOfAddExternalHardware
    }
  }, [_vm._v(" Add Non-" + _vm._s(_vm.currentSystemName) + " hardware ")])], 1)], 1)], 1)], 1), _c('v-flex', {
    attrs: {
      "xs12": ""
    }
  }, [_vm.shouldShowSearchInventory && !_vm.hideCreateInventory && !_vm.manualInputMode ? _c('v-layout', {
    attrs: {
      "row": "",
      "wrap": ""
    }
  }, [_c('v-flex', {
    attrs: {
      "xs12": ""
    }
  }, [_c('ButtonCreate', {
    attrs: {
      "href": _vm.createInventoryUrl,
      "rel": "noopener",
      "target": "_blank"
    },
    on: {
      "click": function ($event) {
        _vm.showRefreshAlert = true;
      }
    }
  }, [_vm._v(" Create a new Inventory ")])], 1)], 1) : _vm._e(), _c('v-layout', {
    attrs: {
      "row": "",
      "wrap": ""
    }
  }, [_c('v-flex', {
    attrs: {
      "xs12": ""
    }
  }, [_c('v-btn', {
    staticClass: "ml-0",
    class: {
      'item_instances white--text mt-3': _vm.manualInputMode,
      'mt-4': _vm.hideCreateInventory
    },
    on: {
      "click": _vm.onClickOfManualModeToggle
    }
  }, [_c('v-slide-x-transition', {
    attrs: {
      "hide-on-leave": "",
      "group": ""
    }
  }, [!_vm.manualInputMode ? _c('c-icon-fa', {
    key: "cursor",
    staticClass: "pr-2",
    attrs: {
      "icon": "fal fa-i-cursor"
    }
  }) : _c('c-icon-fa', {
    key: "search",
    staticClass: "pr-2",
    attrs: {
      "icon": "fal fa-search"
    }
  })], 1), _vm.manualInputMode ? _c('span', [_vm._v("Search Inventory")]) : _c('span', [_vm._v("Non-" + _vm._s(_vm.currentSystemName) + " Hardware input")])], 1)], 1)], 1)], 1), _c('v-fade-transition', {
    attrs: {
      "hide-on-leave": ""
    }
  }, [!_vm.manualInputMode && _vm.transformedSearchResults && _vm.searchComponentResults ? _c('v-flex', {
    attrs: {
      "xs12": ""
    }
  }, [_c('AppTable', {
    attrs: {
      "color": _vm.EntityType.INVENTORY,
      "headers": _vm.InventoryTableHeaders,
      "items": _vm.transformedSearchResults,
      "rows-per-page-items": _vm.rowsPerPageItems,
      "show-count": false,
      "dense-rows": "",
      "selectable-rows": ""
    },
    on: {
      "input": _vm.onSearchInventorySelection
    }
  }, [_c('template', {
    slot: "title"
  }, [_vm._v(" Found "), _c('small', {
    staticClass: "monospace_font"
  }, [_c('strong', [_vm._v(_vm._s(_vm.transformedSearchResults.length))])]), _vm._v(" results ")])], 2)], 1) : _vm._e()], 1)], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };