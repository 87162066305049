var _a, _b, _c;
import { __decorate, __metadata } from "tslib";
import gql from 'graphql-tag';
import { Component, Emit, Prop, Watch } from 'vue-property-decorator';
import { Maybe } from '~/db_types';
import BaseCosmic from '~/nasa_ui/base/BaseCosmic';
import { EntityType } from '~/nasa_ui/types';
import { OutputMode } from '~/nasa_ui/types/enums/general';
import { parseNodeId } from '~/nasa_ui/utils/helpers/parseNodeId';
import { addNodeIdToResponseObj } from '~/nasa_ui/vue_plugins/axios';
let SelectorItemDrawing = class SelectorItemDrawing extends BaseCosmic {
    selectedNodeId = null;
    showRefreshAlert = false;
    searchTerm = null;
    clearable;
    hideSelectedTitle;
    itemDrawingNodeId;
    // props from BaseSearch (needed for `v-bind="$props"` on the SearchItemDrawing)
    autofocus;
    autosearch;
    existingValues;
    filters;
    // prop from SearchItemDrawing (needed for `v-bind="$props"` on the SearchItemDrawing)
    inInventory;
    keepSearchResultsOpen;
    keepSearchValueAfterSelection;
    lockedFilters;
    outputMode;
    showFilterButton;
    showMaxResults;
    size;
    // If the search term doesn't contain spaces, we assume it's a drawing number. In that case
    // we add it as a query string parameter to the Create Item Master link so that the create
    // form will prepopulate the drawing number.
    get createItemMasterUrl() {
        const baseUrl = `/hardware/${EntityType.ITEM_MASTER}/create`;
        if (!this.searchTerm || this.searchTerm.includes(' ')) {
            return baseUrl;
        }
        return `${baseUrl}?drawingNumber=${this.searchTerm}`;
    }
    get shouldShowClearSelectionButton() {
        return this.clearable && this.selectedNodeId;
    }
    async fetchItemDrawingByNodeId(nodeId) {
        try {
            if (!nodeId) {
                this.$errorUtility({ err: 'Item Drawing nodeId is required.' });
                return null;
            }
            const resp = await this.$apollo.query({
                query: gql `
          query GetItemDrawingForIdSelector($nodeId: ID!) {
            itemDrawing(nodeId: $nodeId) {
              description
              drawingNumber
              initializationStatus
              inventoryQuantity
              nodeId
            }
          }
        `,
                variables: {
                    nodeId
                },
                fetchPolicy: 'network-only'
            });
            return resp.data.itemDrawing || null;
        }
        catch (err) {
            this.$errorUtility({ err });
            return null;
        }
    }
    refreshSearchQuery() {
        const SearchItemDrawing = this.$refs.SearchItemDrawing;
        if (SearchItemDrawing) {
            SearchItemDrawing.onClickOfSearchButton();
        }
        this.showRefreshAlert = false;
    }
    clearSelectedNodeId() {
        this.selectedNodeId = null;
        return null;
    }
    // TODO probably need to refactor to emit drawingNumber when in string mode
    emitInput(selectedItem) {
        if (!selectedItem) {
            return null;
        }
        // casting to any because the type of selectedItem has a conflict with `drawingNumber`
        this.selectedNodeId =
            typeof selectedItem === 'string' ? selectedItem : addNodeIdToResponseObj(selectedItem).nodeId;
        return selectedItem;
    }
    emitResults() { }
    emitSearchButtonClicked() { }
    async syncNodeId(nodeId) {
        if (nodeId) {
            this.selectedNodeId = nodeId;
            const parsedNodeId = parseNodeId(nodeId);
            const drawingNumber = parsedNodeId?.drawingNumber;
            if (!drawingNumber) {
                return;
            }
            const resp = await this.$http.get(`/hardware/item-drawings/${drawingNumber}`);
            const itemDrawing = resp.data;
            if (itemDrawing) {
                this.emitInput(itemDrawing);
            }
        }
    }
    // called from the UI
    onTermSearched(term) {
        this.searchTerm = term.toUpperCase();
    }
};
__decorate([
    Prop({
        default: true,
        type: Boolean
    }),
    __metadata("design:type", Boolean)
], SelectorItemDrawing.prototype, "clearable", void 0);
__decorate([
    Prop({
        type: Boolean,
        default: false
    }),
    __metadata("design:type", Boolean)
], SelectorItemDrawing.prototype, "hideSelectedTitle", void 0);
__decorate([
    Prop({ type: String }),
    __metadata("design:type", typeof (_a = typeof Maybe !== "undefined" && Maybe) === "function" ? _a : Object)
], SelectorItemDrawing.prototype, "itemDrawingNodeId", void 0);
__decorate([
    Prop({
        default: false,
        type: Boolean
    }),
    __metadata("design:type", Boolean)
], SelectorItemDrawing.prototype, "autofocus", void 0);
__decorate([
    Prop({
        default: false,
        type: Boolean
    }),
    __metadata("design:type", Boolean)
], SelectorItemDrawing.prototype, "autosearch", void 0);
__decorate([
    Prop({
        type: Array
    }),
    __metadata("design:type", Array)
], SelectorItemDrawing.prototype, "existingValues", void 0);
__decorate([
    Prop({ default: () => { } }),
    __metadata("design:type", Object)
], SelectorItemDrawing.prototype, "filters", void 0);
__decorate([
    Prop({
        type: Boolean,
        default: false
    }),
    __metadata("design:type", Boolean)
], SelectorItemDrawing.prototype, "inInventory", void 0);
__decorate([
    Prop({
        default: false,
        type: Boolean
    }),
    __metadata("design:type", Boolean)
], SelectorItemDrawing.prototype, "keepSearchResultsOpen", void 0);
__decorate([
    Prop({
        default: false,
        type: Boolean
    }),
    __metadata("design:type", Boolean)
], SelectorItemDrawing.prototype, "keepSearchValueAfterSelection", void 0);
__decorate([
    Prop({ default: () => [], type: Array }),
    __metadata("design:type", Array)
], SelectorItemDrawing.prototype, "lockedFilters", void 0);
__decorate([
    Prop({ default: OutputMode.string, type: [String, String] }),
    __metadata("design:type", Object)
], SelectorItemDrawing.prototype, "outputMode", void 0);
__decorate([
    Prop({
        default: true,
        type: Boolean
    }),
    __metadata("design:type", Boolean)
], SelectorItemDrawing.prototype, "showFilterButton", void 0);
__decorate([
    Prop({
        default: true,
        type: Boolean
    }),
    __metadata("design:type", Boolean)
], SelectorItemDrawing.prototype, "showMaxResults", void 0);
__decorate([
    Prop({
        type: String,
        default: '2'
    }),
    __metadata("design:type", String)
], SelectorItemDrawing.prototype, "size", void 0);
__decorate([
    Emit('input'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], SelectorItemDrawing.prototype, "clearSelectedNodeId", null);
__decorate([
    Emit('input'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", void 0)
], SelectorItemDrawing.prototype, "emitInput", null);
__decorate([
    Emit('results'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], SelectorItemDrawing.prototype, "emitResults", null);
__decorate([
    Emit('searchButtonClicked'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], SelectorItemDrawing.prototype, "emitSearchButtonClicked", null);
__decorate([
    Watch('itemDrawingNodeId', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", Promise)
], SelectorItemDrawing.prototype, "syncNodeId", null);
SelectorItemDrawing = __decorate([
    Component
], SelectorItemDrawing);
export default SelectorItemDrawing;
