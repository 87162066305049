import { __decorate, __metadata } from "tslib";
import { Debounce } from 'lodash-decorators';
import qs from 'qs';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { AssemblyTemplateType } from '~/db_types/swagger_types';
import BaseSearch, { srFilterOutSelected } from '~/nasa_ui/base/BaseSearch';
import { BASE_TEXT_COL, NAME_COL } from '~/nasa_ui/constants/genericColumnDefinitions';
import { EntityType } from '~/nasa_ui/types';
import { AssemblyTemplateTypeDisplay } from '~/nasa_ui/types/enums/assembly';
import { composeTableHeader } from '~/nasa_ui/utils/helpers/composeTableHeader';
import { transformAssemblyTemplateResponses } from '~/nasa_ui/utils/helpers/transformEntityToTableFormat';
let SearchAssemblyTemplate = class SearchAssemblyTemplate extends BaseSearch {
    AssemblyTemplateType = AssemblyTemplateType;
    AssemblyTemplateTypeDisplay = AssemblyTemplateTypeDisplay;
    entityType = EntityType.ASSEMBLY_TEMPLATE;
    hint = 'Name PBS-item-id';
    placeholder = 'search for assembly templates...';
    selectedAssemblyTemplate = null;
    showFilters = true; // default to showing the filters
    userSelectedMaxResults = 1000;
    searchFilters = {
        drawingNumber: null,
        query: '',
        rootsOnly: true,
        subType: []
    };
    searchResultsTableHeaders = [
        composeTableHeader(BASE_TEXT_COL, {
            text: 'Type',
            value: '_subType',
            width: '150px'
        }),
        NAME_COL,
        composeTableHeader(BASE_TEXT_COL, {
            text: 'PBS item ID',
            value: '_pbsItemId',
            sortable: true
        })
    ];
    expandable;
    get chipsFromFilters() {
        if (!this.pureSearchFilters || Object.keys(this.pureSearchFilters).length === 0) {
            return [];
        }
        let appChips = [];
        appChips = appChips.concat(this.buildAppChipForProperty('drawingNumber', 'item_drawings'));
        return appChips.filter((key) => key !== undefined);
    }
    get computedColor() {
        return EntityType.ASSEMBLY_TEMPLATE;
    }
    get computedSubTypeDisplay() {
        if (!this.selectedAssemblyTemplate?.subType) {
            return;
        }
        return `${AssemblyTemplateTypeDisplay.get(this.selectedAssemblyTemplate?.subType)}`;
    }
    get computedSearchResults() {
        return this.searchResults ? transformAssemblyTemplateResponses(this.searchResults) : [];
    }
    get selectedAssemblyTemplateId() {
        return this.selectedAssemblyTemplate?.id;
    }
    get transformedSearchResponse() {
        return this.rawSearchResponse ? transformAssemblyTemplateResponses(this.rawSearchResponse) : [];
    }
    onClickOfAssemblyTemplate(val) {
        this.selectedAssemblyTemplate = val;
    }
    onChangeOfRawResponse(val) {
        if (!val) {
            this.$set(this, 'searchResults', []);
            return;
        }
        // No matter the response type get the obj that contains 'nodes'
        let data = val;
        // add __SELECTED and pull up nodes a level
        data = this.addSelectedPropIfSelected(data || []);
        // transform the results based on what EntityType this is
        data = transformAssemblyTemplateResponses(data);
        this.$set(this, 'searchResults', data.filter(srFilterOutSelected));
    }
    async performSearch() {
        try {
            this.isLoading = true;
            const queryString = qs.stringify({
                query: this.searchInput,
                ...this.gqlSearchQueryVariables,
                skip: 0,
                take: this.userSelectedMaxResults
            });
            // Execute query
            const response = await this.$http.get(`/assembly-templates?${queryString}`);
            this.isLoading = false;
            // Status errors are valid responses, it won't hit the catch because the Promise is fulfilled
            if (response.status > 399) {
                this.$errorUtility({
                    err: new Error('There was an error searching for this Assembly Template.'),
                    backupErrorText: 'There was an error searching for this Assembly Template.'
                });
            }
            // Set the raw response in case we need that
            this.rawSearchResponse = response.data.results;
            this.emitSearchPerformed();
        }
        catch (error) {
            this.isLoading = false;
            console.log(error);
            this.$errorUtility({ err: error });
        }
    }
    // This overrides the base and DOESNT init searchFilters
    onChangeOfFilterProp(newFilters) {
        this.searchFilters = Object.assign(this.searchFilters, newFilters);
    }
};
__decorate([
    Prop({
        type: Boolean,
        default: true
    }),
    __metadata("design:type", Boolean)
], SearchAssemblyTemplate.prototype, "expandable", void 0);
__decorate([
    Watch('rawSearchResponse', { deep: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Array]),
    __metadata("design:returntype", void 0)
], SearchAssemblyTemplate.prototype, "onChangeOfRawResponse", null);
__decorate([
    Debounce(200),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", Promise)
], SearchAssemblyTemplate.prototype, "performSearch", null);
__decorate([
    Watch('filters', { deep: true, immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", void 0)
], SearchAssemblyTemplate.prototype, "onChangeOfFilterProp", null);
SearchAssemblyTemplate = __decorate([
    Component
], SearchAssemblyTemplate);
export default SearchAssemblyTemplate;
