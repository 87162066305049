var _a;
import { __decorate, __metadata } from "tslib";
import gql from 'graphql-tag';
import groupBy from 'lodash/groupBy';
import uniqBy from 'lodash/uniqBy';
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import { ChangeSetStatus, DocumentType } from '~/db_types';
import BaseCosmic from '~/nasa_ui/base/BaseCosmic';
import { DEFAULT_DASH } from '~/nasa_ui/constants';
import { CosmicChangeSetAuthorityFragment } from '~/nasa_ui/DAL/changeSet';
import { CommentsRepoMixin } from '~/nasa_ui/DAL/comment';
import { CosmicContactSlimFragment as CosmicContactSlimFragmentOverride } from '~/nasa_ui/DAL/contact/slim';
import { CosmicDocumentRcaNoStepsFragment, CosmicDocumentWorkflowNoStepsFragment, CosmicFracasFragment } from '~/nasa_ui/DAL/document/cosmicManaged';
import { DocumentInformationRepoMixin } from '~/nasa_ui/DAL/document/Information';
import { CosmicDocumentSlimFragment } from '~/nasa_ui/DAL/document/slim';
import { EmailNotificationRepoMixin } from '~/nasa_ui/DAL/email';
import { CosmicUserNoSupportNoPermsFragment, CosmicUserSlimFragment } from '~/nasa_ui/DAL/user';
import CurrentUserMixin from '~/nasa_ui/mixins/CurrentUserMixin';
import { AlertType, Maybe } from '~/nasa_ui/types';
import { RcaRootCauseCategoryDisplay } from '~/nasa_ui/types/enums/document';
import { transformComments, transformDocuments } from '~/nasa_ui/utils/helpers/transformEntityToTableFormat';
import { getCdocsUrl } from '~/nasa_ui/utils/url';
let CosmicDocumentEmail = class CosmicDocumentEmail extends Mixins(BaseCosmic, CommentsRepoMixin, DocumentInformationRepoMixin, EmailNotificationRepoMixin, CurrentUserMixin) {
    comments = [];
    entity = null;
    showCosmicUsersControl = false;
    showModal = false;
    recipientsTableHeaders = [
        {
            text: 'Name',
            value: '_contact',
            sortable: true
        },
        {
            text: 'Reason',
            value: '_contactPresenceReason',
            noEscape: true,
            noWrap: true,
            sortable: true
        }
    ];
    formData = {
        additionalUsers: [],
        extraMessage: null,
        recipients: [],
        sendComments: false,
        sendStepInfo: false
    };
    disabled;
    documentId;
    useIcon;
    get isEmailFormValid() {
        return this.formData.recipients.length > 0;
    }
    get commentToggleLabel() {
        return `Comments? (<small class="monospace_font">${this.comments.length || 0}</small>)`;
    }
    get documentStakeholdersAll() {
        if (!this.entity) {
            return [];
        }
        const allStakeHolders = [
            ...this.stripContactsAndUsers(this.entity),
            ...this.stripContactsAndUsers(this.entity.cosmicDocumentFracas),
            ...this.stripContactsAndUsers(this.entity.cosmicDocumentWorkflow),
            ...this.stripContactsAndUsers(this.entity.cosmicDocumentRca),
            ...this.documentStakeholdersStepsAssigneeContact,
            ...this.documentStakeholdersStepsOnly
        ].filter((stakeholder) => stakeholder._contact !== 'cBot');
        const stakeHoldersByName = groupBy(allStakeHolders, (s) => s._contact);
        const unique = Object.entries(stakeHoldersByName).map(([name, stakeHolders]) => {
            const allReasons = stakeHolders.map((s) => s._contactPresenceReason);
            const uniqueReasons = uniqBy(allReasons, (r) => r);
            const [stakeholder] = stakeHolders;
            return {
                ...stakeholder,
                _contactPresenceReason: uniqueReasons.join('<br/>')
            };
        });
        return unique;
    }
    get documentStakeholdersStepsAssigneeContact() {
        if (!this.entity) {
            return [];
        }
        return (this.entity.workOrderSteps.nodes || [])
            .filter((step) => step?.assigneeContact)
            .flatMap((step) => this.stripContactsAndUsers(step));
    }
    get documentStakeholdersStepsOnly() {
        if (!this.entity) {
            return [];
        }
        const stepStakeholders = this.entity.workOrderSteps.nodes
            .flatMap((step) => step?.changeSet?.changeSetAuthorities.nodes || [])
            .map((csa) => {
            const isDelegateUserGroup = Boolean(csa?.userGroup?.delegatesForUser);
            return {
                isDelegateUserGroup: isDelegateUserGroup,
                _contact: isDelegateUserGroup ? this.$contactDisplay(csa?.userGroup?.delegatesForUser) : csa?.userGroup?.name,
                _contactPresenceReason: isDelegateUserGroup
                    ? 'Approval directly assigned'
                    : `Approvals assigned to group<br/><u>${csa?.userGroup?.name}</u>`,
                contacts: csa?.userGroup?.usersToUserGroups.nodes.map((node) => node?.user?.contact) || []
            };
        });
        return stepStakeholders;
    }
    get emailSubjectLine() {
        return this.entity ? `${this.currentSystemName} Document ${this.entity.number} - ${this.entity.title}` : '';
    }
    get linkToDocument() {
        if (!this.transformedDocument) {
            return '';
        }
        const ctx = this.currentUserActiveSupportContext;
        let baseUrl = null;
        baseUrl = getCdocsUrl();
        return `${baseUrl}/${this.transformedDocument?._subType}/manage/${this.transformedDocument?.id}?ctx=${ctx}`;
    }
    get transformedDocument() {
        return this.entity ? transformDocuments([{ ...this.entity }])[0] : null;
    }
    get transformedComments() {
        return this.comments ? transformComments(this.comments) : [];
    }
    get uniqueEmailList() {
        return this.$uniq(this.formData.recipients
            .flatMap((recipient) => recipient.contacts)
            .filter((contact) => contact?.email?.length)
            .map((contact) => contact.email[0])).sort();
    }
    get uniqueRecipientContactIds() {
        return this.$uniq(this.formData.recipients.flatMap((recipient) => recipient.contacts.map((contact) => contact.id)));
    }
    async sendEmail() {
        try {
            if (!this.entity) {
                new Error('No entity');
                return;
            }
            const resp = await this.createEmailNotification({
                emailDetails: {
                    attributes: {
                        documentId: this.documentId
                    },
                    data: {},
                    messageTextTemplate: '',
                    messageHtmlTemplate: this.buildEmailBody(),
                    recipientContactId: this.uniqueRecipientContactIds,
                    subjectTemplate: this.emailSubjectLine
                }
            });
            if (resp?.createNotification?.notification) {
                this.$notification.add({
                    text: `Email sent to ${this.uniqueRecipientContactIds.length} human(s).`,
                    type: AlertType.SUCCESS
                });
                this.reset();
            }
        }
        catch (err) {
            if (err instanceof Error) {
                this.$errorUtility({
                    err
                });
            }
            else {
                console.log(`Unexpected error: ${err}`);
            }
        }
    }
    buildEmailBody() {
        if (!this.transformedDocument) {
            return '';
        }
        const APP_BLOCKQUOTE_STYLE = `
      <style>
        .app-blockquote {
          -moz-osx-font-smoothing: grayscale;
          -webkit-font-smoothing: antialiased;
          -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
          font-family: Roboto, sans-serif;
          font-size: 14px;
          line-height: 1.5;
          margin-bottom: 10px;
          overflow-x: hidden;
          text-rendering: optimizeLegibility;
        }

        .app_blockquote .title {
          font-family: Roboto, sans-serif !important;
          font-size: 16px !important;
          font-weight: 300;
          letter-spacing: 0.02em !important;
          line-height: 1 !important;
        }

        .app_blockquote .blockquote {
          font-size: 18px;
          font-weight: 600;
          margin-top: 4px;
          padding-left: 0 !important;
          padding-top: 5px;
        }
      </style>
    `;
        const STYLES = `
      <link href='https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;1,400' rel='stylesheet'>
      
      <style>
        html {
          font-family: 'Roboto';
        }

        .mt-2 {
          margin-top:8px!important
        }

        .mb-2 {
          margin-bottom:8px!important
        }
        
        h2 { margin-bottom: 0;}
        h3 { margin-bottom: 0;}

        table td {
          border: 1px solid black;
          vertical-align: top;
        }
      </style>

      ${APP_BLOCKQUOTE_STYLE}
    `;
        return `
      ${STYLES}

      ${this.buildCustomUserMessage()}
      
      <h3><a href="${this.linkToDocument}">${this.emailSubjectLine}</a></h3>
      <br/>

      ${this.buildBaseDocumentInformation(this.transformedDocument)}
      ${this.buildRcaInformation(this.transformedDocument)}
      ${this.buildComments()}
      ${this.buildStepInformation(this.transformedDocument)}
    `;
    }
    buildHtmlOnlyAppBlockquote(label, value) {
        return `
      <div class="app_blockquote">
        <div class="title">${label}</div>
        <blockquote class="blockquote">${value}</blockquote>
      </div>
    `;
    }
    buildWorkorderStepTable(document) {
        const nextOrLastAction = document?.workOrderSteps.nodes.map((node) => {
            if (node?.changeSet?.status.status === ChangeSetStatus.REJECTED ||
                node?.changeSet?.status.status === ChangeSetStatus.APPROVED) {
                return {
                    status: ChangeSetStatus.REJECTED,
                    stepNumber: node?.stepNumber,
                    authorityGroupCode: node?.changeSet?.status.lastAuthority.groupCode,
                    authorizedBy: {
                        email: node?.changeSet?.status.lastAuthority.authorizedByUser.email[0] || '',
                        firstName: node?.changeSet?.status.lastAuthority.authorizedByUser.firstName || '',
                        lastName: node?.changeSet?.status.lastAuthority.authorizedByUser.lastName || ''
                    },
                    enteredBy: node?.changeSet?.status.lastAuthority.createdByUser
                };
            }
            else {
                return {
                    status: node?.changeSet?.status.status,
                    stepNumber: node?.stepNumber,
                    authorityGroupCode: node?.changeSet?.status.nextAuthority.groupCode,
                    authorityGroupUsers: [
                        ...node?.changeSet?.status.nextAuthority.userGroup.usersToUserGroups.nodes.map((user) => {
                            return {
                                firstName: user.user.firstName || '',
                                lastName: user.user.lastName || '',
                                email: user.user.email[0] || ''
                            };
                        })
                    ]
                };
            }
        });
        const openTableTag = '<table>';
        const endTableTag = '</table>';
        const tableHeaders = `<tr>
      <th>Step Number</th>
      <th>Status</th>
      <th>Authority</th>
      </tr>`;
        let tableRows = '';
        nextOrLastAction.map((changeSet) => {
            let authorityTableData = '';
            if (changeSet?.status === ChangeSetStatus.REJECTED || changeSet?.status === ChangeSetStatus.APPROVED) {
                authorityTableData = `<ul style="list-style: none"><li>${changeSet.authorityGroupCode}: ${changeSet.authorizedBy.firstName} ${changeSet.authorizedBy.lastName} ${changeSet.authorizedBy.email}<li></ul>`;
            }
            else {
                const userList = changeSet.authorityGroupUsers.reduce((acc, user) => {
                    return acc.concat(`<li>${user.firstName} ${user.lastName} ${user.email}</li>`);
                }, '');
                authorityTableData = `<ul><li style="list-style: none">${changeSet.authorityGroupCode}</li>${userList}</ul>`;
            }
            tableRows = tableRows.concat(`
        <tr>
        <td style="border: 1px solid black">${changeSet.stepNumber}</td>
        <td style="border: 1px solid black">${changeSet.status}</td>
        <td style="border: 1px solid black">${authorityTableData}</td>
        </tr>
        `);
        });
        return `${openTableTag}${nextOrLastAction.length ? tableHeaders : ''}${tableRows}${endTableTag}`;
    }
    buildBaseDocumentInformation(obj) {
        if (!obj) {
            return '';
        }
        return `
      <div class="mt-2">
        ${this.buildHtmlOnlyAppBlockquote('Document Type', obj._subType)}
        ${this.buildHtmlOnlyAppBlockquote('Created', `${obj._createdDateTime} by <a href="mailto:${obj.createdByUser?.email[0]}">${obj._createdByUser}</a>`)}
        ${this.buildHtmlOnlyAppBlockquote('Opened', obj._computedOpenDate)}
        ${this.buildHtmlOnlyAppBlockquote('Closed', obj._computedCloseDate)}
        ${this.buildHtmlOnlyAppBlockquote('Status', obj._computedStatus)}
        ${this.buildHtmlOnlyAppBlockquote('Title', obj._title)}
        ${this.buildHtmlOnlyAppBlockquote('Description', obj.description || DEFAULT_DASH)}
      </div>
    `;
    }
    buildComments() {
        if (!this.documentId || !this.formData.sendComments) {
            return '';
        }
        const commentHtml = this.transformedComments
            .map((comment) => {
            return `
        <div class="comment">
          <p>${comment.comment}</p>
          <small>Created by: <i>${comment._createdByUser}</i></small>
          <br/>
          <small>Created on: <i>${comment._createdDateTime}</i></small>
          <br/>
          <small>Type: <i>${comment._subType}</i></small>
        </div>
        <hr/>
      `;
        })
            .join('');
        return `
        <style>
          .comment:nth-of-type(odd) {
            background: rgba(122, 122, 122, 0.2);
          }

          .comment {
            padding: 10px 0 10px 24px;
          }
        </style>

        <div class="mt-2">
          <h3>Comments</h3>
          <hr class="mb-2"/>

          <div class="no_comments">
            ${this.transformedComments.length === 0 ? 'No comments.' : ''}
          </div>

          <div class="comments">
            ${commentHtml}
          </div>
        </div>
      `;
    }
    buildCustomUserMessage() {
        if (!this.formData.extraMessage) {
            return '';
        }
        return `
      <style>
        .custom_message {
          background: rgba(122, 122, 122, 0.2);
          padding: 10px 0 10px 24px;
        }
      </style>

      <div class="mt-2 custom_message">
        <p>${this.formData.extraMessage}</p>
        <small>Sent by: <i>${this.$contactDisplay(this.currentUser)}</i></small>
      </div>
    `;
    }
    buildRcaInformation(obj) {
        if (!obj || obj.subType !== DocumentType.C_RCA) {
            return '';
        }
        return `
      <div class="mt-2">
        <h3>Misc</h3>
        <hr class="mb-2"/>

        <div class="">
          ${this.buildHtmlOnlyAppBlockquote('Process Escape?', obj.cosmicDocumentRca?.processEscape ? 'Yes' : 'No')}
          
          ${this.buildHtmlOnlyAppBlockquote('Does this require an award fee writeup?', obj.cosmicDocumentRca?.awardFeeWriteUpRequired ? 'Yes' : 'No')}
          
          ${this.buildHtmlOnlyAppBlockquote('Root cause category', obj.cosmicDocumentRca?.rootCauseCategory
            ? RcaRootCauseCategoryDisplay.get(obj.cosmicDocumentRca.rootCauseCategory) || DEFAULT_DASH
            : DEFAULT_DASH)}
          
          ${this.buildHtmlOnlyAppBlockquote('Root cause', obj.cosmicDocumentRca?.rootCause ? obj.cosmicDocumentRca.rootCause || DEFAULT_DASH : DEFAULT_DASH)}

          ${this.buildHtmlOnlyAppBlockquote('Containment', obj.cosmicDocumentRca?.containment ? obj.cosmicDocumentRca.containment || DEFAULT_DASH : DEFAULT_DASH)}
        </div>
      </div>

      <div class="mt-2">
        <h3 class="users--text">Associated people</h3>
        <hr class="mb-2"/>

        ${this.buildHtmlOnlyAppBlockquote('cRCA Owner', this.buildContactEmailLink(obj.cosmicDocumentRca?.rcaOwnerContact))}
        ${this.buildHtmlOnlyAppBlockquote('Initiator', this.buildContactEmailLink(obj.initiatorContact))}
        ${this.buildHtmlOnlyAppBlockquote('Technical PoC', this.buildContactEmailLink(obj.cosmicDocumentRca?.technicalPocContact))}
      </div

      <div class="mt-2">
        <h3>NASA information</h3>
        <hr class="mb-2"/>

        ${this.buildHtmlOnlyAppBlockquote('Was this request initiated by NASA?', obj.cosmicDocumentRca?.initiatedByNasa ? 'Yes' : 'No')}
        
        ${this.buildHtmlOnlyAppBlockquote('Does this investigation require NASA awareness?', obj.cosmicDocumentRca?.nasaPocContact ? 'Yes' : 'No')}
        
        ${this.buildHtmlOnlyAppBlockquote('NASA PoC', this.buildContactEmailLink(obj.cosmicDocumentRca?.technicalPocContact))}
      </div>
    `;
    }
    buildStepInformation(obj) {
        if (!this.documentId || !this.formData.sendStepInfo) {
            return '';
        }
        const html = (this.transformedDocument?.workOrderSteps?.nodes || [])
            .map((step) => {
            return `
        <div class="step">
          <p>${step.title || ''}</p>
          <a href="${this.buildStepLink(step)}"><small>Step: ${step.stepNumber}</small></a>
          <br/>
          <small>Status: <i>${step.computedStatus}</i></small>
        </div>
        <hr/>`;
        })
            .join('');
        return `
      <style>
        .step {
          padding: 10px 0 10px 24px;
        }

        .step:nth-of-type(odd) {
          background: rgba(122, 122, 122, 0.2);
        }
      </style>

      <div class="mt-2">
        <h3>Steps / Tasks</h3>
        <hr class="mb-2"/>

        <div class="no_steps">
          ${html === '' ? 'No steps.' : ''}
        </div>

        <div class="comments">
          ${html}
        </div>
      </div>
    `;
    }
    buildContactEmailLink(contact) {
        if (!contact?.email || !contact?.email.length) {
            return DEFAULT_DASH;
        }
        const contactDisplay = this.$contactDisplay(contact);
        const emailAddy = contact.email[0];
        if (contactDisplay === 'cBot') {
            return contactDisplay;
        }
        return `<a class="user-email" href="mailto:${emailAddy}">${contactDisplay}</a>`;
    }
    buildStepLink(step) {
        if (!step?.documentId || !step?.stepNumber) {
            return DEFAULT_DASH;
        }
        const baseUrl = getCdocsUrl();
        const documentSubtypeRoutePath = this.transformedDocument?._subType === 'cWorkflow' ? 'workflows' : this.transformedDocument?._subType;
        return `${baseUrl}/${documentSubtypeRoutePath}/manage/${this.transformedDocument?.id}?step=${step.stepNumber}#tasks`;
    }
    stripContactsAndUsers(obj) {
        if (!obj) {
            return [];
        }
        return Object.keys(obj)
            .filter((key) => key.endsWith('Contact') || key.endsWith('User'))
            .filter((key) => obj[key])
            .map((key) => {
            const item = obj[key];
            const contact = key.endsWith('Contact') ? item : item.contact;
            const _contact = this.$contactDisplay(contact);
            const _contactPresenceReason = this.$humanize(key.replace('Contact', '').replace('User', '')).replace('poc', 'PoC');
            return {
                _contactPresenceReason,
                _contact,
                isDelegateUserGroup: false,
                contacts: [contact]
            };
        });
    }
    reset() {
        this.showCosmicUsersControl = false;
        this.showModal = false;
        this.formData = {
            additionalUsers: [],
            extraMessage: null,
            recipients: [],
            sendComments: false,
            sendStepInfo: false
        };
    }
    onModalClose() {
        this.reset();
    }
    async fetchDocumentStakeholders() {
        try {
            const resp = await this.$apollo.query({
                query: gql `
          ${CosmicChangeSetAuthorityFragment}
          ${CosmicContactSlimFragmentOverride}
          ${CosmicDocumentRcaNoStepsFragment}
          ${CosmicDocumentSlimFragment}
          ${CosmicDocumentWorkflowNoStepsFragment}
          ${CosmicFracasFragment}
          ${CosmicUserNoSupportNoPermsFragment}
          ${CosmicUserSlimFragment}

          fragment DocumentEmail on Document {
            ...CosmicDocumentSlim

            attributes

            createdByUser {
              ...CosmicUserNoSupportNoPerms
            }

            cosmicDocumentFracas {
              ...CosmicFracas
            }

            cosmicDocumentWorkflow {
              ...CosmicDocumentWorkflowNoSteps
            }

            cosmicDocumentRca {
              ...CosmicDocumentRcaNoSteps
            }

            workOrderSteps {
              nodes {
                computedStatus
                documentId
                id
                nodeId
                stepNumber
                subType
                title

                assigneeContact {
                  ...CosmicContactSlim
                }

                createdByUser {
                  ...CosmicUserNoSupportNoPerms
                }

                changeSet {
                  id
                  nodeId

                  changeSetAuthorities(condition: { isHistorical: false }) {
                    nodes {
                      ...CosmicChangeSetAuthority
                    }
                  }

                  status {
                    status
                    lastAuthority {
                      id
                      nodeId
                      groupCode
                      authorizedByUser {
                        ...CosmicUserSlim
                      }
                      userGroup {
                        name
                        nodeId
                        usersToUserGroups {
                          nodes {
                            user {
                              ...CosmicUserSlim
                            }
                          }
                        }
                      }
                    }
                    nextAuthority {
                      id
                      nodeId
                      groupCode
                      userGroup {
                        name
                        nodeId
                        usersToUserGroups {
                          nodes {
                            user {
                              ...CosmicUserSlim
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }

          query GetDocumentEmailInfo($id: UUID!) {
            documentById(id: $id) {
              ...DocumentEmail
            }
          }
        `,
                fetchPolicy: 'network-only',
                variables: {
                    id: this.documentId
                }
            });
            this.entity = resp?.data?.documentById;
            this.fetchComments();
        }
        catch (err) {
            if (err instanceof Error) {
                this.$errorUtility({
                    err
                });
            }
            else {
                console.log(`Unexpected error: ${err}`);
            }
        }
    }
    async fetchComments() {
        try {
            const resp = await this.getDocumentCommentsById({ documentId: this.documentId });
            this.comments = (resp?.documentById?.documentComments.nodes || []);
        }
        catch (err) {
            if (err instanceof Error) {
                this.$errorUtility({
                    err
                });
            }
            else {
                console.log(`Unexpected error: ${err}`);
            }
        }
    }
    onAdditionalUsersChange(val) {
        console.log(val);
        this.formData.recipients = this.formData.recipients.filter((recipient) => recipient._contactPresenceReason !== 'User entered.');
        this.formData.additionalUsers.forEach((additionalUser) => {
            this.formData.recipients.push({
                _contact: this.$contactDisplay(additionalUser.user),
                _contactPresenceReason: 'User entered.',
                contacts: [
                    Object.assign({}, additionalUser.user, { id: additionalUser.user.contactId })
                ],
                isDelegateUserGroup: false
            });
        });
    }
    onClickOfEmailPopupActivator() {
        this.showModal = true;
        this.fetchDocumentStakeholders();
    }
};
__decorate([
    Prop({
        default: false,
        type: Boolean
    }),
    __metadata("design:type", Boolean)
], CosmicDocumentEmail.prototype, "disabled", void 0);
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", typeof (_a = typeof Maybe !== "undefined" && Maybe) === "function" ? _a : Object)
], CosmicDocumentEmail.prototype, "documentId", void 0);
__decorate([
    Prop({
        default: false,
        type: Boolean
    }),
    __metadata("design:type", Boolean)
], CosmicDocumentEmail.prototype, "useIcon", void 0);
__decorate([
    Watch('formData.additionalUsers'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", void 0)
], CosmicDocumentEmail.prototype, "onAdditionalUsersChange", null);
CosmicDocumentEmail = __decorate([
    Component
], CosmicDocumentEmail);
export default CosmicDocumentEmail;
