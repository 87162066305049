var _a;
import { __decorate, __metadata } from "tslib";
import { uniq } from 'lodash';
import { DebounceAll } from 'lodash-decorators';
import uniqBy from 'lodash/uniqBy';
import Vue from 'vue';
import { Component, Emit, Mixins, Prop, Watch } from 'vue-property-decorator';
import { OrganizationType } from '~/db_types';
import CurrentUserMixin from '~/nasa_ui/mixins/CurrentUserMixin';
import { EntityType, Maybe, OrganizationTypeDisplay } from '~/nasa_ui/types';
import { sortObjectBy } from '~/nasa_ui/utils';
let AppSelectOrganization = class AppSelectOrganization extends Mixins(CurrentUserMixin, Vue) {
    allOrganizations = [];
    duplicateCheckQueryResult = null;
    EntityType = EntityType;
    isLoading = false;
    OrganizationType = OrganizationType;
    OrganizationTypeDisplay = OrganizationTypeDisplay;
    selectedOrganizations = null;
    showNewOrganizationModal = false;
    userIgnoredDupe = false;
    formData = {
        code: null,
        name: null,
        subType: OrganizationType.VENDOR
    };
    allowCreate;
    disabled;
    label;
    multiple;
    required;
    value;
    get organizationSelectOptions() {
        if (!this.allOrganizations?.length) {
            return [];
        }
        return uniqBy(this.allOrganizations
            .map((org) => {
            return {
                displayText: org.name,
                value: org.code
            };
        })
            .sort(sortObjectBy('displayText')), 'value');
    }
    get shouldBounceCreateButton() {
        if (!this.allowCreate) {
            return false;
        }
        return !this.value && (this.formData.code?.length || 0) > 0;
    }
    get shouldDisableCreateFromModal() {
        if (!this.formData.code) {
            return true;
        }
        return Boolean(this.duplicateCheckQueryResult && this.duplicateCheckQueryResult.length && !this.userIgnoredDupe);
    }
    created() {
        this.fetchAllOrgs();
    }
    onSearchInputUpdate(userInputFromOrgCodeBox) {
        this.formData.code = userInputFromOrgCodeBox.trim().toUpperCase().replaceAll('/', '_').replaceAll(' ', '_');
    }
    onClickOfCreateFromModal() {
        if (!this.formData.code) {
            return;
        }
        const preliminaryNewOrganization = {
            attributes: {},
            code: this.formData.code.trim().toUpperCase().replaceAll('/', '_').replaceAll(' ', '_'),
            createdByUser: {},
            createdDateTime: '',
            isActive: true,
            isCageCode: false,
            name: this.formData.name || this.formData.code,
            subType: this.formData.subType || OrganizationType.VENDOR
        };
        this.allOrganizations?.push(preliminaryNewOrganization);
        this.emitInput(preliminaryNewOrganization.code);
        this.$emit('new-organization', preliminaryNewOrganization);
        this.showNewOrganizationModal = false;
    }
    onClickOfCreateNewOrganization() {
        this.showNewOrganizationModal = true;
    }
    onClickOfCancel() {
        this.reset();
    }
    reset() {
        this.showNewOrganizationModal = false;
        this.userIgnoredDupe = false;
        this.formData.code = null;
        this.formData.name = null;
        this.formData.subType = OrganizationType.VENDOR;
        this.duplicateCheckQueryResult = null;
    }
    emitInput(val) {
        if (val === '') {
            return null;
        }
        // If multiple and its not an array and its not null|undefined return the val as an array
        if (this.multiple && !Array.isArray(val) && val !== null && val !== undefined) {
            return [val];
        }
        return val;
    }
    syncSelectedOrganizations(val) {
        this.selectedOrganizations = val;
    }
    async onCodeUpdate() {
        if (!this.formData.code) {
            return;
        }
        try {
            const resp = await this.$http.get(`/organizations?take=1&query=${encodeURIComponent(this.formData.code)}`);
            this.duplicateCheckQueryResult = resp.data.results;
        }
        catch (err) {
            if (err instanceof Error) {
                this.$errorUtility({
                    err
                });
            }
            else {
                console.log(`Unexpected error: ${err}`);
            }
        }
    }
    async fetchAllOrgs() {
        try {
            this.isLoading = true;
            const resp = await this.$http.get(`/organizations?take=-1`);
            if (resp.data.totalCount) {
                const existingOptions = [...this.allOrganizations];
                this.allOrganizations = uniq([...existingOptions, ...resp.data.results]);
                this.isLoading = false;
            }
            else {
                // No project codes found
                this.isLoading = false;
            }
        }
        catch (err) {
            this.isLoading = false;
            this.$errorUtility({
                err,
                backupErrorText: 'Failed to fetch all orgs.'
            });
        }
    }
};
__decorate([
    Prop({
        default: true,
        type: Boolean
    }),
    __metadata("design:type", Boolean)
], AppSelectOrganization.prototype, "allowCreate", void 0);
__decorate([
    Prop({
        default: false,
        type: Boolean
    }),
    __metadata("design:type", Boolean)
], AppSelectOrganization.prototype, "disabled", void 0);
__decorate([
    Prop({
        default: 'Organizations',
        type: String
    }),
    __metadata("design:type", String)
], AppSelectOrganization.prototype, "label", void 0);
__decorate([
    Prop({
        default: false,
        type: Boolean
    }),
    __metadata("design:type", Boolean)
], AppSelectOrganization.prototype, "multiple", void 0);
__decorate([
    Prop({
        default: false,
        type: Boolean
    }),
    __metadata("design:type", Boolean)
], AppSelectOrganization.prototype, "required", void 0);
__decorate([
    Prop({
        default: ''
    }),
    __metadata("design:type", Object)
], AppSelectOrganization.prototype, "value", void 0);
__decorate([
    Watch('selectedOrganizations', { immediate: true }),
    Emit('input'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", void 0)
], AppSelectOrganization.prototype, "emitInput", null);
__decorate([
    Watch('value', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_a = typeof Maybe !== "undefined" && Maybe) === "function" ? _a : Object]),
    __metadata("design:returntype", void 0)
], AppSelectOrganization.prototype, "syncSelectedOrganizations", null);
__decorate([
    DebounceAll(200),
    Watch('formData.code'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", Promise)
], AppSelectOrganization.prototype, "onCodeUpdate", null);
AppSelectOrganization = __decorate([
    Component
], AppSelectOrganization);
export default AppSelectOrganization;
