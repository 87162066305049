import { __decorate, __metadata } from "tslib";
import { DebounceAll } from 'lodash-decorators';
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import { Side } from '~/db_types/swagger_types';
import BaseCosmic from '~/nasa_ui/base/BaseCosmic';
import BaseModal from '~/nasa_ui/base/BaseModal';
import { putPartReference } from '~/nasa_ui/utils';
import { sortObjectNumericallyBy } from '~/nasa_ui/utils/helpers/sortObjectNumericallyBy';
let ModalHardwareListAddToList = class ModalHardwareListAddToList extends Mixins(BaseModal, BaseCosmic) {
    hardwareList = null;
    selectedPartReference = null;
    selectedPartReferenceEdited = null;
    selectedPartReferenceRequest = null;
    formData = {
        addendumDate: null,
        addendumNumber: null,
        notes: null,
        quantity: 0,
        sequence: null
    };
    hardwareListId;
    get createHardwareListPartRequest() {
        if (!this.hardwareListId || !this.isFormValid) {
            return null;
        }
        if (!this.selectedPartReferenceRequest && !this.selectedPartReference) {
            return null;
        }
        const notesAsNumbers = this.formData.notes?.map((note) => {
            return parseInt(note);
        });
        const base = {
            addendumDate: this.formData.addendumDate,
            addendumNumber: this.formData.addendumNumber,
            quantity: this.formData.quantity ?? 0,
            sequence: this.nextLogicalSequenceNumber,
            notes: notesAsNumbers
        };
        if (this.selectedPartReference) {
            return Object.assign(base, {
                partReferenceId: this.selectedPartReference.id
            });
        }
        else if (this.selectedPartReferenceRequest) {
            return Object.assign(base, {
                createPartReference: { ...this.selectedPartReferenceRequest }
            });
        }
    }
    get isFormValid() {
        return this.isSequenceValid && Boolean(this.formData.quantity) && this.formData.quantity > 0;
    }
    get isSequenceNumberAlreadyInUse() {
        return this.hardwareList?.hardwareListParts?.some((node) => node?.sequence === this.formData.sequence) ?? false;
    }
    get isSequenceValid() {
        const isValidNumber = Boolean(this.formData.sequence);
        return isValidNumber && !this.isSequenceNumberAlreadyInUse;
    }
    get nextLogicalSequenceNumber() {
        const lastNode = this.hardwareList?.hardwareListParts?.sort(sortObjectNumericallyBy('sequence', true))[0];
        return (lastNode?.sequence ?? 0) + 10;
    }
    async onClickOfCreate() {
        if (this.selectedPartReferenceEdited) {
            this.isSaving = true;
            this.selectedPartReference = await this.updatePartReference(this.selectedPartReferenceEdited);
        }
        if (!this.createHardwareListPartRequest) {
            this.isSaving = false;
            return;
        }
        try {
            this.isSaving = true;
            const resp = await this.$http.post(`/hardware-lists/${this.hardwareListId}/parts`, this.createHardwareListPartRequest);
            if (resp) {
                this.emitEntityCreated('List updated.');
                this.$message(`reload_${this.hardwareListId}`);
                this.emitModalClose();
            }
            this.isSaving = false;
        }
        catch (err) {
            this.$errorUtility({ err });
            this.isSaving = false;
        }
    }
    onPartReferenceEdited(partReference) {
        this.selectedPartReferenceEdited = partReference;
    }
    onPartReferenceSelected(partReference) {
        this.selectedPartReference = partReference;
    }
    onNewPartReferenceSelected(partReferenceRequest) {
        this.selectedPartReferenceRequest = partReferenceRequest;
    }
    async updatePartReference(partReference) {
        const request = {
            attributes: partReference?.attributes || {},
            asBuiltNumber: partReference?.asBuiltNumber || null,
            birthDate: partReference?.birthDate || null,
            calibrationNumber: partReference?.calibrationNumber || null,
            contractEndingItemNumber: partReference?.contractEndingItemNumber || null,
            drawingDescription: partReference?.drawingDescription || null,
            drawingNumber: partReference?.drawingNumber || null,
            isTool: partReference?.isTool ?? false,
            itemClass: partReference?.itemClass || null,
            lastCalibrationDate: partReference?.lastCalibrationDate || null,
            location: partReference?.location || null,
            lotNumber: partReference?.lotNumber || null,
            serialNumber: partReference?.serialNumber || null,
            shelfLifeExpirationDate: partReference?.shelfLifeExpirationDate || null,
            side: partReference?.side || Side.NONE,
            size: partReference?.size || null,
            usageLifeExpirationDate: partReference?.usageLifeExpirationDate || null
        };
        try {
            const resp = await putPartReference(partReference.id, request);
            return resp.data;
        }
        catch (err) {
            if (err instanceof Error) {
                this.$errorUtility({ err });
            }
            else {
                console.error(`Unexpected error: ${err}`);
            }
        }
    }
    async fetchHardwareListById() {
        if (!this.hardwareListId) {
            return;
        }
        try {
            this.isFetching = true;
            const resp = await this.$http.get(`/hardware-lists/${this.hardwareListId}`);
            this.hardwareList = resp.data;
            this.isFetching = false;
        }
        catch (err) {
            this.isFetching = false;
            if (err instanceof Error) {
                this.$errorUtility({
                    err
                });
            }
            else {
                console.log(`Unexpected error: ${err}`);
            }
        }
    }
    onNextLogicalSequenceNumberChange() {
        if (this.nextLogicalSequenceNumber && !this.formData.sequence) {
            this.formData.sequence = this.nextLogicalSequenceNumber;
        }
    }
};
__decorate([
    Prop({
        type: String,
        required: true
    }),
    __metadata("design:type", String)
], ModalHardwareListAddToList.prototype, "hardwareListId", void 0);
__decorate([
    DebounceAll(10),
    Watch('hardwareListId', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", Promise)
], ModalHardwareListAddToList.prototype, "fetchHardwareListById", null);
__decorate([
    Watch('nextLogicalSequenceNumber'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], ModalHardwareListAddToList.prototype, "onNextLogicalSequenceNumberChange", null);
ModalHardwareListAddToList = __decorate([
    Component
], ModalHardwareListAddToList);
export default ModalHardwareListAddToList;
