var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "container pa-1 mt-2"
  }, [_c('SearchInputContainer', {
    attrs: {
      "autofocus": _vm.autofocus,
      "disable-search": false,
      "entity-type": _vm.EntityType.ASSEMBLY_TEMPLATE,
      "hint": _vm.hint,
      "is-loading": _vm.isLoading,
      "placeholder": _vm.placeholder
    },
    on: {
      "searchButtonClicked": _vm.onClickOfSearchButton,
      "enterPressed": _vm.onClickOfSearchButton
    },
    model: {
      value: _vm.searchInput,
      callback: function ($$v) {
        _vm.searchInput = $$v;
      },
      expression: "searchInput"
    }
  }), _c('SearchFilterDisplay', {
    attrs: {
      "active": _vm.shouldShowFilters,
      "color": _vm.EntityType.ASSEMBLY_TEMPLATE,
      "entity-type": _vm.EntityType.ASSEMBLY_TEMPLATE,
      "chipsFromFilters": _vm.chipsFromFilters,
      "show-filter-button": _vm.showFilterButton
    },
    on: {
      "appChipCloseClicked": _vm.onClickOfRemoveFilter,
      "clearAllFilters": _vm.onClearAllFilters,
      "filterButtonClicked": _vm.onClickOfFilterIcon
    }
  }), _c('SearchFilterContainer', {
    attrs: {
      "color": _vm.EntityType.ASSEMBLY_TEMPLATE,
      "show": _vm.shouldShowFilters
    }
  }, [_c('div', {
    staticClass: "c-grid mt-2"
  }, [_c('div', {
    staticClass: "col-half"
  }, [_c('AppSelectDrawingNumber', {
    staticClass: "dn",
    attrs: {
      "disabled": _vm.isFilterLocked('drawingNumber'),
      "multiple": "",
      "message": "Filters Assembly Templates by DNs that contain these selections"
    },
    model: {
      value: _vm.searchFilters.drawingNumber,
      callback: function ($$v) {
        _vm.$set(_vm.searchFilters, "drawingNumber", $$v);
      },
      expression: "searchFilters.drawingNumber"
    }
  })], 1), _c('div', {
    staticClass: "col-fourth"
  }, [_c('AppSelectEnum', {
    attrs: {
      "color": _vm.EntityType.ASSEMBLY_TEMPLATE,
      "display": _vm.AssemblyTemplateTypeDisplay,
      "enum": _vm.AssemblyTemplateType,
      "clearable": "",
      "label": "Type",
      "multiple": ""
    },
    model: {
      value: _vm.searchFilters.subType,
      callback: function ($$v) {
        _vm.$set(_vm.searchFilters, "subType", $$v);
      },
      expression: "searchFilters.subType"
    }
  })], 1)])]), _c('v-fade-transition', {
    attrs: {
      "hide-on-leave": ""
    }
  }, [_c('AppLoader', {
    staticClass: "ma-0",
    attrs: {
      "color": _vm.EntityType.ASSEMBLY_TEMPLATE,
      "show": _vm.isLoading,
      "type": "linear"
    }
  })], 1), _vm.expandable ? _c('v-slide-y-transition', {
    attrs: {
      "tag": "table",
      "hide-on-leave": ""
    }
  }, [_vm.shouldShowTable && _vm.searchResults ? _c('AppTableExpandable', {
    attrs: {
      "color": _vm.EntityType.ASSEMBLY_TEMPLATE,
      "headers": _vm.searchResultsTableHeaders,
      "items": _vm.computedSearchResults,
      "item-key": "id",
      "show-count": "",
      "dense-rows": "",
      "title": "Assembly Templates"
    },
    on: {
      "expandableRowClick": _vm.onClickOfAssemblyTemplate
    }
  }, [_vm.selectedAssemblyTemplate ? _c('template', {
    slot: "expanded-row"
  }, [_c('ButtonGeneric', {
    staticClass: "mb-2",
    attrs: {
      "small": "",
      "color": "primary",
      "icon": _vm.$icons['square_check']
    },
    on: {
      "click": function ($event) {
        return _vm.onClickOfSearchResult(_vm.selectedAssemblyTemplate);
      }
    }
  }, [_vm._v(" Select this " + _vm._s(_vm.computedSubTypeDisplay) + " ")]), _c('MiniAssemblyTemplate', {
    attrs: {
      "allow-edit": false,
      "id": _vm.selectedAssemblyTemplateId,
      "show-children-initially": false,
      "read-only": "",
      "show-children": ""
    }
  })], 1) : _vm._e()], 2) : _vm._e()], 1) : _c('v-slide-y-transition', [_vm.shouldShowTable && _vm.searchResults ? _c('AppTable', {
    key: "searchResults",
    staticClass: "mb-4",
    attrs: {
      "color": _vm.EntityType.ASSEMBLY_TEMPLATE,
      "headers": _vm.searchResultsTableHeaders,
      "items": _vm.searchResults,
      "selectable-rows": ""
    },
    on: {
      "rowClicked": _vm.onClickOfSearchResult
    }
  }, [_c('template', {
    slot: "title"
  }, [_vm._v(" Assembly Templates ( "), _c('small', {
    staticClass: "monospace_font"
  }, [_c('strong', [_vm._v(_vm._s(_vm.searchResults.length))])]), _vm._v(" ) ")])], 2) : _vm._e()], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };